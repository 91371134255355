import React, { ReactElement } from 'react';
import { useSelector } from 'react-redux';
import compose from 'recompose/compose';
import parseTrackingData from '../../../../../shared/helpers/parseTrackingData';
import { getScrollOffset } from '../../../../shared/helpers/getScrollOffset';
import { ensureTeaserInterface } from '../../components/Teaser/shared/helpers';
import windowStateSelector from '../../../../../shared/selectors/windowStateSelector';
import withAppNexus from '../../../../shared/decorators/withAppNexus';
import withHelmet from '../../../../shared/decorators/withHelmet';
import withScrollToAnchor from '../../../../shared/decorators/withScrollToAnchor';
import TestFragment from '../../../../../shared/tests/components/TestFragment';
import AppNexus from '../../components/AppNexus';
import Breadcrumbs from '../../components/Breadcrumbs';
import Recommendations from '../../components/Recommendations';
import VideoStage from '../../components/VideoStage';
import StatusPage from './../StatusPage';
import OverviewPageHeader, {
  OVERVIEW_PAGE_HEADER_DEFAULT,
} from '../../components/OverviewPageHeader';
import Pager, { PAGER_TYPE_PAGE_LOADER } from '../../components/Pager';
import { WithRaschRouter } from '../../../../../shared/@types/gql';
import { ROOT_SCHEMA_TYPE_WEB_PAGE } from '../../../../../shared/constants/structuredData';
import { TEASER_LAYOUT_S } from '../../../../../shared/constants/teaser';
import { SITE_TITLE } from '../../../App/constants';
import { MMR_1 } from '../../components/AppNexus/constants';
import { ITEMS_PER_PAGE, VIDEO_STAGE_ITEMS } from './constants';
import grid from '../../../../../common/assets/styles/grid.legacy.css';
import styles from './styles.legacy.css';
import { VideoStageType } from '../../../../../common/components/VideoStage/typings';

type VideosProps = RouterProps;

type VideosPropsInner = VideosProps &
  Pick<WithRaschRouter, 'data' | 'loading'> & {
    data: {
      environment: {
        videoStageSearch: VideoConnection | null;
      };
    };
  };

// ---------------------------------------------------------------------------------- //
// COMPONENT
// ---------------------------------------------------------------------------------- //

const PAGER_ANCHOR_SCROLL_ID = 'page';
const getFallbackTitle = (landingPage: LandingPage): string =>
  `${landingPage?.title || 'Videos'} - ${SITE_TITLE}`;

const Videos = ({ data, page, loading }: VideosPropsInner): ReactElement => {
  const viewportLabel = useSelector(
    (state) => windowStateSelector(state).viewport.label,
  );

  if (
    !data ||
    !data.environment ||
    !data.environment.globalSearch ||
    !data.environment.routeByPath ||
    !data.environment.routeByPath.object
  ) {
    return loading ? null : <StatusPage />;
  }

  const scrollOffset = getScrollOffset(false, viewportLabel);

  const globalSearch = data.environment.globalSearch;
  const videoStage = data.environment?.videoStageSearch || null;
  const landingPage = data.environment.routeByPath.object as LandingPage;
  const gridItems = globalSearch?.edges || null;
  const videoStageParagraph: VideoStageType = {
    id: landingPage.id,
    items: videoStage,
  };

  return (
    <TestFragment data-testid="videos-wrapper">
      {landingPage.preferredUri && landingPage.activeMenuTrail && (
        <TestFragment data-testid="videos-breadcrumbs-wrapper">
          <Breadcrumbs
            pageUrl={landingPage.preferredUri}
            items={landingPage.activeMenuTrail}
          />
        </TestFragment>
      )}
      <div className={'ad-wrapper ad-wrapper-mobile'}>
        <div className={grid.Container}>
          <AppNexus slot={MMR_1} deviceType="mobile" />
        </div>
      </div>
      <OverviewPageHeader
        title={landingPage.title || 'Videos'}
        lead={landingPage?.lead || ''}
        component={OVERVIEW_PAGE_HEADER_DEFAULT}
      />
      {videoStage?.edges && (
        <TestFragment data-testid="video-stage-paragraph-wrapper">
          <VideoStage
            scrollOffset={scrollOffset}
            videoStage={videoStageParagraph}
          />
        </TestFragment>
      )}

      {globalSearch?.count && gridItems && (
        <div
          id={PAGER_ANCHOR_SCROLL_ID}
          data-testid="search-results-containter"
          className={grid.Container}
        >
          <div className={styles.RecommendationsWrapper}>
            <Recommendations
              items={gridItems.map((item) => ensureTeaserInterface(item))}
              title={`D'AUTRES VIDÉOS À DÉCOUVRIR`}
              teaserLayout={TEASER_LAYOUT_S}
              trackingOrigin="bottom"
            />
          </div>
          <Pager
            component={PAGER_TYPE_PAGE_LOADER}
            currentPage={page}
            itemsCount={
              globalSearch.count &&
              Math.abs(globalSearch.count - VIDEO_STAGE_ITEMS)
            }
            itemsPerPage={ITEMS_PER_PAGE}
            anchorScrollId={PAGER_ANCHOR_SCROLL_ID}
          />
        </div>
      )}
    </TestFragment>
  );
};

export default compose(
  withScrollToAnchor(),
  withHelmet({
    getNode: (mapProps: VideosPropsInner) =>
      mapProps.data?.environment?.routeByPath?.object || null,
    getFallbackTitle: (mapProps: VideosPropsInner) =>
      getFallbackTitle(
        mapProps.data?.environment?.routeByPath?.object as LandingPage,
      ),
    getNodesCount: (mapProps: VideosPropsInner) =>
      (!!mapProps?.data?.environment?.globalSearch?.count &&
        Math.abs(
          parseInt(`${mapProps.data.environment.globalSearch.count}`) -
            VIDEO_STAGE_ITEMS,
        )) ||
      0,
    pageSize: ITEMS_PER_PAGE,
    rootSchemaType: ROOT_SCHEMA_TYPE_WEB_PAGE,
    getNodes: (mapProps: VideosPropsInner) =>
      mapProps.data?.environment?.videoStageSearch?.edges || [],
  }),
  withAppNexus({
    parseTrackingData: (props) =>
      parseTrackingData({
        ...props,
        articleType: 'LandingPage',
      }),
  }),
)(Videos);
