import { homeBottom } from './homeBottom';
import { homeTop } from './homeTop';
import { landscapeSpecial } from './landscapeSpecial';
import { landscapeWithAd } from './landscapeWithAd';
import { menuOverlay } from './menuOverlay';
import { teaser3x4 } from './teaser3x4';
import {
  GRID_LAYOUT_BLOGS,
  GRID_LAYOUT_HOME_BOTTOM,
  GRID_LAYOUT_HOME_TOP,
  GRID_LAYOUT_LANDSCAPE_SPECIAL,
  GRID_LAYOUT_LANDSCAPE_WITH_AD,
  GRID_LAYOUT_MENU_OVERLAY,
  GRID_LAYOUT_TEASER_3X4,
} from './constants';
import blogsStyles from './blogs/styles.legacy.css';
import homeBottomStyles from './homeBottom/styles.legacy.css';
import homeTopStyles from './homeTop/styles.legacy.css';
import landscapeSpecialStyles from './landscapeSpecial/styles.legacy.css';
import landscapeWithAdStyles from './landscapeWithAd/styles.legacy.css';
import menuOverlayStyles from './menuOverlay/styles.legacy.css';
import teaser3X4Styles from './teaser3x4/styles.legacy.css';
import {
  GlobalTeaserLayout,
  globalGridConfig,
} from '../../../../../../common/components/TeaserGrid/gridConfigs';
import { blogs } from '../../../../../../common/components/TeaserGrid/gridConfigs/blogs';
export type TeaserLayout =
  | typeof GRID_LAYOUT_HOME_TOP
  | typeof GRID_LAYOUT_HOME_BOTTOM
  | typeof GRID_LAYOUT_MENU_OVERLAY
  | typeof GRID_LAYOUT_LANDSCAPE_WITH_AD
  | typeof GRID_LAYOUT_LANDSCAPE_SPECIAL
  | typeof GRID_LAYOUT_BLOGS
  | typeof GRID_LAYOUT_TEASER_3X4
  | GlobalTeaserLayout;

type GridConfig = Record<
  TeaserLayout,
  { config: Record<string, any>; styles: Record<string, string> }
>;
export const gridConfig: GridConfig = {
  ...globalGridConfig,
  [GRID_LAYOUT_HOME_TOP]: {
    config: homeTop,
    styles: homeTopStyles,
  },
  [GRID_LAYOUT_HOME_BOTTOM]: {
    config: homeBottom,
    styles: homeBottomStyles,
  },
  [GRID_LAYOUT_MENU_OVERLAY]: {
    config: menuOverlay,
    styles: menuOverlayStyles,
  },
  [GRID_LAYOUT_BLOGS]: {
    config: blogs,
    styles: blogsStyles,
  },
  [GRID_LAYOUT_LANDSCAPE_WITH_AD]: {
    config: landscapeWithAd,
    styles: landscapeWithAdStyles,
  },
  [GRID_LAYOUT_LANDSCAPE_SPECIAL]: {
    config: landscapeSpecial,
    styles: landscapeSpecialStyles,
  },
  [GRID_LAYOUT_TEASER_3X4]: {
    config: teaser3x4,
    styles: teaser3X4Styles,
  },
};
