import React from 'react';
import teaserGridFactory from '../../../../../common/components/TeaserGrid/factory';
import TestFragment from '../../../../../shared/tests/components/TestFragment';
import AppNexus from '../AppNexus';
import Error from '../Error';
import HeroSlider from '../HeroSlider';
import Teaser from '../Teaser';
import { TYPE_AD } from '../../../../../common/components/TeaserGrid/gridConfigs/constants';
import { TYPE_DIVIDER, TYPE_HEROSLIDER_HOME } from './gridConfigs/constants';
import styles from './styles.legacy.css';
import { TeaserLayout, gridConfig } from './gridConfigs';
import { EnrichedGridConfig } from '../../../../../common/components/TeaserGrid/typings';

export const getGridItem = (item: EnrichedGridConfig) => {
  switch (item.type) {
    case TYPE_HEROSLIDER_HOME: {
      const sliderItems = {
        type: item.type,
        items: item.items.map((i) => {
          return {
            type: i.teaserType,
          };
        }),
        data: item.items.map((i) => i.data),
      };
      return (
        <TestFragment data-testid="teasergrid-hero-slider">
          <HeroSlider item={sliderItems as any} />
        </TestFragment>
      );
    }
    case TYPE_DIVIDER: {
      return (
        <div data-testid="teasergrid-divider" className={styles.Divider} />
      );
    }

    case TYPE_AD: {
      const adSlot =
        item.adConfig &&
        Array.isArray(item.adConfig) &&
        item.adConfig.map(
          ({ slot, isMultiPlacement = true, deviceType }, index: number) => (
            <div className="ad-wrapper" key={`teaser-grid-ad-${index}`}>
              <AppNexus
                slot={slot}
                isMultiPlacement={isMultiPlacement}
                deviceType={deviceType}
              />
            </div>
          ),
        );
      return (
        <TestFragment data-testid="teasergrid-ad-wrapper">
          {adSlot}
        </TestFragment>
      );
    }

    default:
      return null;
  }
};

const TeaserGrid = teaserGridFactory<Record<TeaserLayout, any>>({
  ErrorMessage: Error,
  Teaser: Teaser,
  cssGridConfig: gridConfig,
  getGridItem: getGridItem,
});

export default TeaserGrid;
