/* istanbul ignore file */

import advantagesItemFactory from '../../../../../common/components/AdvantagesItem/factory';
// eslint-disable-next-line postcss-modules/no-unused-class
import styles from './styles.legacy.css';

const AdvantagesParagraph = advantagesItemFactory({
  styles,
});

export default AdvantagesParagraph;
