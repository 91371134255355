import React, { ReactElement } from 'react';
import classNames from 'classnames';
import Link from '../../../../../../../common/components/Link';
import Picture from '../../../../../../../common/components/Picture';
import TestFragment from '../../../../../../../shared/tests/components/TestFragment';
import ArrowButton from '../../../ArrowButton';
import { TeaserPropsInner } from '../../../Teaser';
import { STYLE_BOOK_TEASER } from '../../../../../../../shared/constants/images';
import { ARROW_BUTTON_THEME_SKIN } from '../../../ArrowButton/constants';
import styles from './styles.legacy.css';

type TeaserComponentsPropsInner = TeaserPropsInner & TeaserInterface & Product;

const TeaserProduct = ({
  shortTitle,
  teaserImage,
  title,
  link,
  price,
  pricePrefix,
  trackingData,
  trackingSelector = '',
}: TeaserComponentsPropsInner): ReactElement | null => {
  if (!link || !link.path) {
    return null;
  }

  const teaserImgPath: string =
    teaserImage.image?.file?.relativeOriginPath || '';
  const teaserImgAlt: string = teaserImage.image?.file?.alt || '';

  return (
    <TestFragment data-testid="teaserproduct-container">
      <Link
        path={link.path}
        target={'_blank'}
        className={classNames(styles.Item, 'ArrowButtonHoverArea', {
          [trackingSelector]: !!trackingSelector,
        })}
        trackingData={trackingData}
      >
        <>
          {teaserImgPath && (
            <div className={styles.ImageWrapper}>
              <Picture
                relativeOrigin={teaserImgPath}
                focalPointX={teaserImage?.image?.file?.focalPointX}
                focalPointY={teaserImage?.image?.file?.focalPointY}
                alt={teaserImgAlt}
                className={styles.Image}
                style_320={STYLE_BOOK_TEASER}
              />
            </div>
          )}
          <div className={styles.ContentWrapper}>
            {shortTitle && (
              <div className={styles.ShortTitle}>{shortTitle}</div>
            )}
            {title && <div>{title}</div>}
            {price && (
              <div data-testid="teaserproduct-price">
                {pricePrefix && `${pricePrefix} `}
                <span className={styles.Currency}>CHF</span>{' '}
                <span>
                  {price === Math.round(price)
                    ? `${price}.—`
                    : Number(price).toFixed(2)}
                </span>
              </div>
            )}
            {link.label && (
              <div className={styles.Button}>
                <ArrowButton theme={ARROW_BUTTON_THEME_SKIN} large>
                  {link.label}
                </ArrowButton>
              </div>
            )}
          </div>
        </>
      </Link>
    </TestFragment>
  );
};

export default TeaserProduct;
