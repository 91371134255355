import classNames from 'classnames';
import textParagraphFactory from '../../../../../../../common/components/Paragraphs/components/TextParagraph/components/Default/factory';
import {
  TRACKING_CLASS_PARAGRAPH,
  TRACKING_CLASS_TEXT_PARAGRAPH,
} from '../../../../../../../shared/constants/tracking';
import {
  PAGESCREEN_MARKETING_TYPE_LONGFORM,
  PAGE_SCREEN_MARKETING_TYPE,
} from '../../../../screens/PageScreen/constants';
import { INFO_BOX_TYPE } from '../InfoBoxParagraph/constants';
import { ACCORDION_PAGE } from '../MinistageParagraph/components/MinistageAccordion/constants';
import styles from './styles.legacy.css';
import {
  TextParagraphFactoryOptionsStyles,
  TextParagraphProps,
} from '../../../../../../../common/components/Paragraphs/components/TextParagraph/typings';

export type TextParagraphPropsInner = TextParagraphProps;

const getStylesByProps = ({
  origin,
  isFirst,
  textParagraph,
}: TextParagraphPropsInner): TextParagraphFactoryOptionsStyles => {
  return {
    Wrapper: classNames(
      styles.RichtextWrapper,
      TRACKING_CLASS_PARAGRAPH,
      TRACKING_CLASS_TEXT_PARAGRAPH,
      {
        [styles.Accordion]: origin === ACCORDION_PAGE,
        [styles.MarketingPage]: origin === PAGE_SCREEN_MARKETING_TYPE,
        [styles.Longform]: origin === PAGESCREEN_MARKETING_TYPE_LONGFORM,
      },
    ),
    Header: classNames(styles.Header, {
      [styles.InfoBoxHeader]: !isFirst && origin === INFO_BOX_TYPE,
      [styles.InfoBoxHeaderTitle]: isFirst && origin === INFO_BOX_TYPE,
      [styles.InfoBoxHeaderTitleMargin]:
        isFirst && origin === INFO_BOX_TYPE && textParagraph.text,
      [styles.MarketingPage]: origin === PAGE_SCREEN_MARKETING_TYPE,
      [styles.Longform]: origin === PAGESCREEN_MARKETING_TYPE_LONGFORM,
    }),
  };
};

const TextParagraph = textParagraphFactory({
  styles: getStylesByProps,
});

export default TextParagraph;
