/* istanbul ignore file */
import { getTealiumData } from '../../../shared/helpers/tealium/helper';
import withRaschRouterFactory, {
  RaschRouterConfig,
} from '../../../shared/decorators/withRaschRouterFactory';
import { onLocationChange, setLoading, setScreenReady } from '../actions/route';
import {
  ROUTE_451,
  ROUTE_AUTHORS,
  ROUTE_PODCASTS,
  ROUTE_SEARCH,
  ROUTE_STYLEGUIDE,
  ROUTE_VIDEOS,
} from '../../screens/App/constants';
import { apolloConfig as routerApolloConfig } from '../../screens/App/components/Router/apolloConfig';
import { apolloConfig as authorsPageApolloConfig } from '../../screens/App/screens/Authors/apolloConfig';
import { apolloConfig as podcastsApolloConfig } from '../../screens/App/screens/Podcasts/apolloConfig';
import { apolloConfig as searchApolloConfig } from '../../screens/App/screens/Search/apolloConfig';
import { apolloConfig as videosApolloConfig } from '../../screens/App/screens/Videos/apolloConfig';

const routerConfig: RaschRouterConfig = {
  // created route styleguide/* to catch paragraphs and typography children of styleguide
  styleguide: { path: `${ROUTE_STYLEGUIDE}/*`, ignoreLoadingState: true },
  statusPage: {
    path: ROUTE_451,
    ignoreLoadingState: true,
  },
  search: {
    path: ROUTE_SEARCH,
    apolloConfig: searchApolloConfig,
    parseTealiumData: (props) =>
      getTealiumData({
        object: {
          ...props,
          preferredUri: props.location.pathname,
          __typename: 'Search',
          pageId: 'search',
        },
      }),
  },
  videos: {
    path: ROUTE_VIDEOS,
    apolloConfig: videosApolloConfig,
  },
  podcasts: {
    path: ROUTE_PODCASTS,
    apolloConfig: podcastsApolloConfig,
  },
  authorsPage: {
    path: ROUTE_AUTHORS,
    apolloConfig: authorsPageApolloConfig,
  },
  default: {
    path: '*',
    apolloConfig: routerApolloConfig,
    parseTealiumData: (props) => {
      // only used for 404 pages
      if (!props.newData?.environment?.routeByPath) {
        return getTealiumData({
          object: {
            ...props,
            preferredUri: props.location.pathname,
            __typename: 'ErrorPage',
          },
        });
      }

      return null;
    },
  },
};

const withRaschRouter = withRaschRouterFactory({
  routerConfig,
  onLocationChange,
  setScreenReady,
  setLoading,
});

export default withRaschRouter;
