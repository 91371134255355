import { gql } from '@apollo/client';
import { imageGalleryTeaserFragment } from 'Teaser/fragments';

export const GET_SPECIAL_ENTITIES = gql`
  query LandingPageSpecialsRouteByPath(
    $path: String!
    $overviewPageSize: Int!
    $overviewPageOffset: Int!
    $filter: [ContentTypeEnum]
  ) @api(name: cms) {
    environment(publication: ILE) {
      routeByPath(path: $path) {
        canonical
        preferred
        statusCode
        object {
          ... on LandingPage {
            title
            nid
            gcid
            shortTitle
            metaCanonicalUrl
            teaserImage {
              id
              image {
                id
                file(style: "large") {
                  id
                  alt
                  relativeOriginPath
                  focalPointX
                  focalPointY
                }
              }
            }
            channel {
              id
              channelType
              suppressAds
              sponsor {
                id
                title
                colorCode
                teaserImage {
                  id
                  link {
                    path
                  }
                  image {
                    id
                    file(style: "large") {
                      id
                      alt
                      relativeOriginPath
                      focalPointX
                      focalPointY
                    }
                  }
                }
              }
              partners {
                edges {
                  node {
                    ... on Sponsor {
                      id
                      title
                    }
                  }
                }
              }
              special {
                id
                title
                logo {
                  source
                }
                colorCode
                format
              }
              settings {
                channel {
                  id
                  title
                }
                title
                headerImage {
                  id
                  file(style: "large") {
                    id
                    alt
                    relativeOriginPath
                    focalPointX
                    focalPointY
                  }
                }
              }
              entities(
                filter: $filter
                limit: $overviewPageSize
                offset: $overviewPageOffset
              ) {
                count
                edges {
                  node {
                    ... on Article {
                      id
                      title
                      preferredUri
                      shortTitle
                      badgeLabel
                      badgeColor
                      hasVideo
                      channel {
                        id
                        title
                      }
                      teaserImage {
                        id
                        image {
                          id
                          file(style: "large") {
                            id
                            alt
                            relativeOriginPath
                            focalPointX
                            focalPointY
                          }
                        }
                      }
                      useAutoHyphens
                    }
                    ... on ImageGallery {
                      id
                      title
                      preferredUri
                      shortTitle
                      badgeLabel
                      badgeColor
                      teaserImage {
                        id
                        image {
                          id
                          file(style: "large") {
                            id
                            alt
                            relativeOriginPath
                            focalPointX
                            focalPointY
                          }
                        }
                      }
                      useAutoHyphens
                    }
                    ...ImageGalleryTeaserFragment
                    ... on NativeAdvertising {
                      gcid
                      id
                      title
                      shortTitle
                      lead
                      hasVideo
                      preferredUri
                      trackingTeaserClick
                      trackingTeaserImpression
                      subtypeValue: advertisingTypeValue
                      advertisingTypeLabel
                      link {
                        path
                        label
                      }
                      sponsor {
                        id
                        title
                      }
                      teaserImage {
                        id
                        caption
                        image {
                          id
                          file(style: "large") {
                            id
                            alt
                            relativeOriginPath
                            focalPointX
                            focalPointY
                          }
                        }
                      }
                      useAutoHyphens
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  ${imageGalleryTeaserFragment}
`;
