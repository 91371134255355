/* istanbul ignore file */

import React from 'react';
import noItemsFactory from '../../../../../../../common/components/NoItems/factory';
import Link from '../../../../../../../common/components/Link';
import ArrowButton from '../../../../components/ArrowButton';
import Icon from '../../../../components/Icon';
import styles from './styles.legacy.css';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const markStyleAsUsed = [styles.BookmarkAnimation];

export default noItemsFactory({
  styles: {
    NoItemsWrapper: '',
    InnerWrapper: styles.InnerWrapper,
    Text: styles.Text,
    Icon: styles.Icon,
    Wrapper: styles.Wrapper,
  },
  Icon,
  text: 'Avec ce symbole, vous enregistrez les articles qui vous intéressent pour les lire plus tard.',
  button: (
    <Link path="/" className={styles.Link}>
      <ArrowButton large>Page d&apos;acceuil</ArrowButton>
    </Link>
  ),
  iconType: 'IconBookmarkOutlineDefault',
});
