import React, { ReactElement } from 'react';
import classNames from 'classnames';
import Picture from '../../../../../../../common/components/Picture';
import {
  STYLE_16X9_1180,
  STYLE_16X9_340,
  STYLE_16X9_360,
  STYLE_16X9_560,
  STYLE_16X9_700,
  STYLE_16X9_800,
} from '../../../../../../../shared/constants/images';
import { TRACKING_CLASS_IMAGE_GALLERY_HERO_PARAGRAPH } from '../../../../../../../shared/constants/tracking';
import styles from './styles.legacy.css';
import { HeroProps } from './typings';

type HeroPropsInner = HeroProps;

const imageStyles = {
  style_320: STYLE_16X9_340,
  style_480: STYLE_16X9_360,
  style_540: STYLE_16X9_560,
  style_760: STYLE_16X9_700,
  style_960: STYLE_16X9_800,
  style_1680: STYLE_16X9_1180,
};

const Hero = ({
  shortTitle,
  title,
  teaserImage,
}: HeroPropsInner): ReactElement => {
  if (!teaserImage || Object.keys(teaserImage).length === 0 || !title) {
    return null;
  }

  const relativeOriginPath = teaserImage?.image?.file?.relativeOriginPath || '';
  const heroCredit = teaserImage?.image?.credit || '';
  const shouldDisplayImageCredit = !teaserImage?.suppressSource;

  if (!relativeOriginPath) {
    return null;
  }
  return (
    <div
      className={classNames(
        TRACKING_CLASS_IMAGE_GALLERY_HERO_PARAGRAPH,
        styles.Wrapper,
      )}
      data-testid="hero-image-container"
    >
      {(relativeOriginPath && (
        <>
          <Picture
            relativeOrigin={relativeOriginPath}
            focalPointX={teaserImage?.image?.file?.focalPointX}
            focalPointY={teaserImage?.image?.file?.focalPointY}
            alt={teaserImage?.image?.file?.alt || ''}
            className={styles.Image}
            {...imageStyles}
            disableWrapperClassName
            disableLineHeightResetClassName
            downloadPriority="high"
          />
          {shouldDisplayImageCredit && (
            <p className={styles.ImageCredit}>{heroCredit}</p>
          )}
        </>
      )) ||
        null}
      <div className={styles.ContentWrapper}>
        {shortTitle && <div className={styles.ShortTitle}>{shortTitle}</div>}
        <div className={styles.Title}>
          <h1 className={styles.TitleInner}>{title}</h1>
        </div>
      </div>
    </div>
  );
};

export default Hero;
