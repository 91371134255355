import React, { ReactElement } from 'react';
import compose from 'recompose/compose';
import withProps from 'recompose/withProps';
import classNames from 'classnames';
import Link from '../../../../../common/components/Link';
import Icon from '../Icon';
import { TRACKING_CLASS_SITE_FOOTER } from '../../../../../shared/constants/tracking';
import { query } from './queries.preload';
import grid from '../../../../../common/assets/styles/grid.legacy.css';
import styles from './styles.legacy.css';
import { FooterProps } from './typings';

type SocialOptions = {
  name: string;
  link: string;
  iconType: string;
};

type FooterPropsInner = FooterProps & {
  menuByName: Menu;
};

const socials: Array<SocialOptions> = [
  {
    name: 'Facebook',
    link: 'https://www.facebook.com/Magazine.Illustre/',
    iconType: 'IconFacebook',
  },
  {
    name: 'Twitter',
    link: 'https://twitter.com/Lillustre/',
    iconType: 'IconTwitter',
  },
  {
    name: 'Instagram',
    link: 'https://www.instagram.com/lillustre/',
    iconType: 'IconInstagram',
  },
  {
    name: 'Youtube',
    link: 'https://www.youtube.com/channel/UCqW56UFSLYUCGYDcrtYqqeQ',
    iconType: 'IconYoutube',
  },
];

const SocialLinks = (): ReactElement => {
  const socialItems: Array<SocialOptions> = socials;

  return (
    <>
      {socialItems.map(
        (item, index: number): ReactElement => (
          <li key={`footer-social-item-${index}`} className={styles.ListItem}>
            <Link
              className={styles.SocialLink}
              path={item.link}
              title={item.name}
              ariaLabel={item.name}
            >
              <Icon
                type={item.iconType}
                addClass={styles.Share}
                aria-hidden={true}
              />
            </Link>
          </li>
        ),
      )}
    </>
  );
};

class Footer extends React.Component<FooterPropsInner> {
  render() {
    const {
      addClass = '',
      menuByName,
      isMarketingPageReducedHeader,
    } = this.props;
    return (
      <footer
        className={classNames(
          TRACKING_CLASS_SITE_FOOTER,
          styles.Wrapper,
          grid.HideForPrint,
          {
            [addClass]: !!addClass,
            [grid.GridCentered]: isMarketingPageReducedHeader,
          },
        )}
      >
        <div className={grid.Container}>
          <div className={styles.Section}>
            <div className={styles.InnerWrapper}>
              <div className={styles.NavigationWrapper}>
                {menuByName?.links?.edges &&
                  Array.isArray(menuByName.links.edges) &&
                  menuByName.links.edges.length > 0 && (
                    <nav data-testid="footer-navigation-items-wrapper">
                      <ul className={styles.List}>
                        {menuByName.links.edges.map(
                          (
                            { node }: MenuTreeItemEdge,
                            index: number,
                          ): ReactElement => (
                            <li
                              key={`footer-menu-item-${index}`}
                              className={styles.ListItem}
                            >
                              <Link {...node?.link} className={styles.Link} />
                            </li>
                          ),
                        )}
                      </ul>
                    </nav>
                  )}
              </div>
              <div
                className={styles.CopyMobile}
                data-testid="footer-copy-right"
              >
                &copy; {new Date().getFullYear()} Ringier SA | Ringier Médias
                Suisse
              </div>
              <div className={styles.Share}>
                <ul
                  className={styles.List}
                  data-testid="footer-socials-wrapper"
                >
                  <SocialLinks />
                </ul>
              </div>
              <div className={styles.Logo}>
                <div className={styles.Copy}>
                  &copy; {new Date().getFullYear()} Ringier SA | Ringier Médias
                  Suisse
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    );
  }
}

const withData = withProps(query);

export default compose(withData)(Footer);
