import React from 'react';
import classNames from 'classnames';
import teaserFactory from '../../../../../../../common/components/Teaser/factory';
import { withTeaserTrackingHandler } from '../../../../../../../common/components/Teaser/helpers';
import { isIconVisible } from '../../shared/helpers';
import Icon from '../../../Icon';
import {
  IMAGE_GALLERY_CONTENT_TYPE,
  VIDEO_CONTENT_TYPE,
} from '../../../../../../../shared/constants/content';
import {
  FULLSCREEN_HASH,
  FULLSCREEN_HASH_TEASER_CLICK,
} from '../../../../../../../shared/constants/fullscreen';
import { STYLE_3X2_210 } from '../../../../../../../shared/constants/images';
import styles from './styles.legacy.css';
// @ts-ignore
import grid from '@grid.legacy.css';
import {
  GetElementByProps,
  TeaserFactoryProps,
} from '../../../../../../../common/components/Teaser/typings';

type TeaserLatestPropsInner = TeaserFactoryProps;

const getIconByProps: GetElementByProps<TeaserLatestPropsInner> = ({
  hasVideo,
  __typename,
}) => {
  const showIcon =
    hasVideo ||
    __typename === IMAGE_GALLERY_CONTENT_TYPE ||
    __typename === VIDEO_CONTENT_TYPE;

  if (!isIconVisible(hasVideo, __typename) || !showIcon) {
    return null;
  }

  return (
    <Icon
      type={classNames({
        IconCamera: hasVideo,
        IconFotoMarker: __typename === IMAGE_GALLERY_CONTENT_TYPE,
        IconMovieOutline: __typename === VIDEO_CONTENT_TYPE,
      })}
      addClass={classNames({
        [styles.IconStyle]: __typename !== VIDEO_CONTENT_TYPE,
        [styles.IconMovieOutlineStyle]: __typename === VIDEO_CONTENT_TYPE,
      })}
    />
  );
};

export const TeaserLatest = teaserFactory({
  icon: getIconByProps,
  isIconPositionOnImage: true,
  styles: {
    Wrapper: styles.Wrapper,
    Title: styles.Title,
    ImageWrapper: styles.ImageWrapper,
    Image: styles.Image,
    ContentWrapper: styles.ContentWrapper,
    TitleInner: styles.TitleInner,
    ShortTitle: styles.ShortTitle,
  },
  trackingTeaserHandler: withTeaserTrackingHandler,
  teaserImageStyles: { style_320: STYLE_3X2_210 },
  fullScreenHashTeaserClick: FULLSCREEN_HASH_TEASER_CLICK,
  fullScreenHash: FULLSCREEN_HASH,
});

const TeaserLatestInGrid = (props: TeaserLatestPropsInner) => (
  <div
    className={classNames(
      grid.ColOffsetXs1,
      grid.ColOffsetSm1,
      grid.ColOffsetLg1,
    )}
    data-testid="teaser-latest-wrapper"
  >
    <TeaserLatest {...props} />
  </div>
);

export default TeaserLatestInGrid;
