import React, { useEffect, useRef } from 'react';
import { compose } from 'recompose';
import { enrichOverviewBodyWithADs } from '../../../../../../../shared/helpers/ads';
import { latestNativeAdvertisingsGenerator } from '../../../../../../../shared/helpers/latestNativeAdvertisings';
import { latestNACounter } from '../../../../../../../shared/helpers/useLatestNativeAdvertisings';
import { prepareBody } from '../../helpers';
import withHelmet from '../../../../../../shared/decorators/withHelmet';
import useRecommendations from '../../../../../../../shared/hooks/useRecommendations';
import {
  RecommendationsNode,
  useRecommendationsResponse,
} from '../../../../../../../shared/hooks/useRecommendations/typings';
import TestFragment from '../../../../../../../shared/tests/components/TestFragment';
import EditButtons from '../../../../components/EditButtons';
import MonsterSky from '../../../../components/MonsterSky';
import Paragraphs from '../../../../components/Paragraphs';
import PartnerBanner from '../../../../components/PartnerBanner';
import { PUBLICATION_GROUP_ILE } from '../../../../../../../shared/constants/publications';
import { RECOMMENDATION_OPERATION } from '../../../../../../../shared/constants/recommendations';
import { ROOT_SCHEMA_TYPE_ORGANIZATION } from '../../../../../../../shared/constants/structuredData';
import { LANDING_PAGE_TYPE } from '../../constants';
import { LandingPageProps } from '../../typings';

type LandingPageHomePropsInner = LandingPageProps;

let latestNAGenerator: Generator<RecommendationsNode> = null;

const recommendationsOperation =
  RECOMMENDATION_OPERATION.LATEST_NATIVE_ADVERTISINGS;

const LandingPage = ({
  origin,
  landingPage,
  isAdSuppressed = false,
}: LandingPageHomePropsInner) => {
  const { recommendations, fetchRecommendations }: useRecommendationsResponse =
    useRecommendations();
  const fetchRecommendationsRef = useRef(fetchRecommendations);

  const recommendationsLimit = latestNACounter(landingPage?.body);

  useEffect(() => {
    if (recommendationsLimit <= 0) {
      return;
    }
    fetchRecommendationsRef.current({
      publication: PUBLICATION_GROUP_ILE,
      articleKeywords: {},
      contentId: '1', // any number, as it gets ignored by mostread anyway
      operation: recommendationsOperation,
      limit: recommendationsLimit,
    });
  }, [recommendationsLimit]);

  if (!landingPage || Object.keys(landingPage).length === 0) {
    return <TestFragment data-testid="not-found-wrapper">{null}</TestFragment>;
  }

  latestNAGenerator = latestNativeAdvertisingsGenerator(recommendations);

  const hasBody =
    landingPage?.body &&
    Array.isArray(landingPage?.body) &&
    landingPage?.body.length > 0;

  if (!hasBody) {
    return <TestFragment data-testid="not-found-wrapper">{null}</TestFragment>;
  }

  const preparedBodyWithAds = enrichOverviewBodyWithADs({
    pageBody: prepareBody(landingPage.body, landingPage.paragraphsSubsetSize),
    hasEQsWithMMR: true,
  });

  return (
    <TestFragment data-testid="landingpage-wrapper">
      <EditButtons
        editContentUri={landingPage.editContentUri}
        editRelationUri={landingPage.editRelationUri}
        cloneContentUri={landingPage.cloneContentUri}
      />

      {landingPage?.channel?.sponsors?.edges &&
        Array.isArray(landingPage?.channel?.sponsors?.edges) && (
          <TestFragment data-testid="landing-page-partnerbanner-wrapper">
            <PartnerBanner
              sponsors={landingPage?.channel?.sponsors?.edges || []}
            />
          </TestFragment>
        )}

      {!isAdSuppressed ||
        (!landingPage?.channel?.suppressAds && <MonsterSky />)}

      {preparedBodyWithAds && (
        <TestFragment data-testid="landing-page-paragraphs">
          <Paragraphs
            latestNAGenerator={latestNAGenerator}
            pageBody={preparedBodyWithAds}
            forceUpdate={recommendations?.[recommendationsOperation]}
            origin={origin || LANDING_PAGE_TYPE}
            hasContainer={false}
            isAdSuppressed={landingPage?.channel?.suppressAds || isAdSuppressed}
          />
        </TestFragment>
      )}
    </TestFragment>
  );
};

const LandingPageHome = compose(
  withHelmet({
    getNode: (mapProps: LandingPageHomePropsInner): LandingPage =>
      mapProps.landingPage,
    getImage: (mapProps: LandingPageHomePropsInner) =>
      mapProps.landingPage?.teaserImage?.image?.file ||
      mapProps.landingPage?.body?.[0]?.entityQueue?.items?.edges?.[0]?.node
        ?.teaserImage?.image?.file,
    getRootSchemaType: (mapProps: LandingPageHomePropsInner) =>
      mapProps.location?.pathname === '/' ? ROOT_SCHEMA_TYPE_ORGANIZATION : '',
  }),
)(LandingPage);

export default LandingPageHome;
