/* istanbul ignore file */

import React from 'react';
import noItemsFactory from '../../../../../../../common/components/NoItems/factory';
import Link from '../../../../../../../common/components/Link';
import ArrowButton from '../../../../components/ArrowButton';
import Icon from '../../../../components/Icon';
import styles from './styles.legacy.css';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const markStyleAsUsed = [styles.BellAnimation];

export default noItemsFactory({
  styles: {
    NoItemsWrapper: '',
    InnerWrapper: styles.InnerWrapper,
    Text: styles.Text,
    Icon: styles.Icon,
    Wrapper: styles.Wrapper,
  },
  Icon,
  button: (
    <Link path="/" className={styles.Link}>
      <ArrowButton large>Page d&apos;acceuil</ArrowButton>
    </Link>
  ),
  text: "Avec nos alertes e-mail, vous restez informé.e sur vos thèmes préférés. Dès qu'un article est publié, vous recevrez une notification par e-mail. Vous n'avez pas encore créé d'alerte? Il suffit d'activer «Suivre» en cliquant sur la cloche à la fin de chaque article.",
});
