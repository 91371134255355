import windowStateSelector from '../../../shared/selectors/windowStateSelector';
import withAppNexusFactory from '../../../shared/decorators/withAppNexusFactory';
import locationStateSelector from '../selectors/locationStateSelector';
import {
  MAIN_CHANNEL_ACTU,
  MAIN_CHANNEL_DEFAULT,
  MAIN_CHANNEL_HOME,
  MAIN_CHANNEL_MAGAZINE,
  MAIN_CHANNEL_PHOTOS,
  MAIN_CHANNEL_PLANETE,
  MAIN_CHANNEL_VIDEOS,
} from '../../screens/App/constants';

const URL_SEGMENT_TO_VERTICAL_MAP = {
  actu: MAIN_CHANNEL_ACTU,
  magazine: MAIN_CHANNEL_MAGAZINE,
  photos: MAIN_CHANNEL_PHOTOS,
  planete: MAIN_CHANNEL_PLANETE,
  videos: MAIN_CHANNEL_VIDEOS,
};

const mapPathSegmentToVertical = (pathSegment: string): string => {
  // create map url segment : constant
  const map = URL_SEGMENT_TO_VERTICAL_MAP;
  return map[pathSegment] || 'ROS';
};

const mapVerticalToAdCategory = () => ({
  [MAIN_CHANNEL_DEFAULT]: 'ROS',
  [MAIN_CHANNEL_HOME]: 'Home',
  [MAIN_CHANNEL_ACTU]: 'Magazine',
  [MAIN_CHANNEL_PHOTOS]: 'Photos',
  [MAIN_CHANNEL_PLANETE]: 'Planete',
  [MAIN_CHANNEL_VIDEOS]: 'Videos',
  [MAIN_CHANNEL_MAGAZINE]: 'Magazine',
});

export default withAppNexusFactory({
  locationStateSelector,
  windowStateSelector,
  mapPathSegmentToVertical,
  mapVerticalToAdCategory,
  defaultVertical: 'ROS',
});
