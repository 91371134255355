/**
 *
 */

import React from 'react';
import compose from 'recompose/compose';
import alertUnsubscribeFactory from '../../../../../common/screens/AlertsUnsubscribe/factory';
import parseTrackingData from '../../../../../shared/helpers/parseTrackingData';
import withParams from '../../../../../shared/decorators/withParams';
import withPianoTrackingData from '../../../../../shared/decorators/withPianoTrackingData';
import withApolloScreenHandler from '../../../../shared/decorators/withApolloScreenHandler';
import withAppNexus from '../../../../shared/decorators/withAppNexus';
import withWaitUntilApolloLoaded from '../../../../shared/decorators/withWaitUntilApolloLoaded';
import Link from '../../../../../common/components/Link/index';
import ArrowButton from '../../components/ArrowButton';
import Img from '../../components/Img';
import LoadingSpinner from '../../components/LoadingSpinner';
import LoginForm from '../AlertsProfile/components/LoginForm';
import styles from './styles.legacy.css';
import CheckmarkSVG from '../../assets/graphics/checkmark.svg';

const AlertsUnsubscribe = alertUnsubscribeFactory({
  styles: {
    AlertsUnsubscribeWrapper: styles.AlertsUnsubscribeWrapper,
    Icon: styles.Icon,
    Text: styles.Text,
    Wrapper: '',
    LoginWrapper: styles.LoginWrapper,
  },
  successText: 'Vous vous êtes désabonné.e de toutes vos alertes e-mail.',
  loginText: 'Veuillez vous connecter pour ajouter des thèmes à suivre',
  LoginForm,
  LoadingSpinner,
  checkmarkIcon: <Img url={CheckmarkSVG} addClass={styles.Img} />,
  button: (
    <Link path="/">
      <ArrowButton large>Page d&apos;acceuil</ArrowButton>
    </Link>
  ),
});

export default compose(
  withParams,
  withApolloScreenHandler,
  withWaitUntilApolloLoaded,
  withPianoTrackingData,
  withAppNexus({
    parseTrackingData: (props) =>
      parseTrackingData({
        ...props,
        articleType: 'LandingPage',
      }),
  }),
)(AlertsUnsubscribe);
