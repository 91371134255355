import React from 'react';
import classNames from 'classnames';
import { getImageStyles } from '../../../../../../../../../common/components/Picture/helpers';
import Picture from '../../../../../../../../../common/components/Picture';
import FullscreenButton from '../../../../../FullscreenButton';
import ImageCaption from '../../../../../Paragraphs/components/ImageCaption';
import {
  IMAGE_FORMAT_DEFAULT,
  IMAGE_FORMAT_LANDSCAPE,
  IMAGE_FORMAT_PORTRAIT,
  IMAGE_FORMAT_SQUARE,
  STYLE_1X1_410,
  STYLE_1X1_495,
  STYLE_1X1_640,
  STYLE_1X1_660,
  STYLE_2X3_305,
  STYLE_2X3_360,
  STYLE_2X3_960,
  STYLE_3X2_440,
  STYLE_3X2_770,
} from '../../../../../../../../../shared/constants/images';
import { MULTI_COLUMNS_PARAGRAPH } from '../../../../../../../../../shared/constants/paragraphs';
import {
  TRACKING_CLASS_DEFAULT_IMAGE_PARAGRAPH,
  TRACKING_CLASS_PARAGRAPH,
} from '../../../../../../../../../shared/constants/tracking';
import { INFO_BOX_TYPE } from '../../../InfoBoxParagraph/constants';
import grid from '../../../../../../../../../common/assets/styles/grid.legacy.css';
import sections from '../../../../../../../../../common/assets/styles/sections.legacy.css';
import helpers from '../../../../../../assets/styles/helpers.legacy.css';
import styles from './styles.legacy.css';

const getFormatClasses = (format) => {
  switch (format) {
    case IMAGE_FORMAT_PORTRAIT:
      return [grid.ColSm12, grid.ColOffsetSm6].join(' ');
    case IMAGE_FORMAT_SQUARE:
      return [grid.ColSm16, grid.ColOffsetSm4].join(' ');

    default:
      return '';
  }
};

const IMAGE_PARAGRAPH_IDENTIFIER = 'inline-image-paragraph';

const FORMAT_STYLE_MAPPING = {
  [IMAGE_FORMAT_LANDSCAPE]: [
    STYLE_3X2_440,
    STYLE_3X2_440,
    STYLE_3X2_770,
    STYLE_3X2_770,
    STYLE_3X2_770,
    STYLE_3X2_770,
  ],
  [IMAGE_FORMAT_PORTRAIT]: [
    STYLE_2X3_360,
    STYLE_2X3_360,
    STYLE_2X3_960,
    STYLE_2X3_305,
    STYLE_2X3_960,
    STYLE_2X3_960,
  ],
  [IMAGE_FORMAT_SQUARE]: [
    STYLE_1X1_410,
    STYLE_1X1_495,
    STYLE_1X1_495,
    STYLE_1X1_640,
    STYLE_1X1_660,
    STYLE_1X1_660,
  ],
};

const DefaultImageParagraph = ({
  imageParagraph,
  origin,
  heroMediaParagraph,
}) => {
  if (!imageParagraph || !imageParagraph.image) {
    return null;
  }

  const imageFormat = imageParagraph.format || IMAGE_FORMAT_DEFAULT;
  const imagePath = imageParagraph.image?.file?.relativeOriginPath || '';
  const imageAlt = imageParagraph.image?.file?.alt || '';
  const imageStyles = getImageStyles(imageFormat, FORMAT_STYLE_MAPPING);
  const originalUrl =
    (imageParagraph.image?.showOriginal &&
      imageParagraph.image?.file?.origin) ||
    null;
  return (
    <div
      className={classNames(
        TRACKING_CLASS_PARAGRAPH,
        TRACKING_CLASS_DEFAULT_IMAGE_PARAGRAPH,
        {
          [grid.Row]:
            (imageFormat === IMAGE_FORMAT_PORTRAIT ||
              imageFormat === IMAGE_FORMAT_SQUARE) &&
            origin !== MULTI_COLUMNS_PARAGRAPH &&
            !imageParagraph.fullWidth &&
            !heroMediaParagraph,
        },
      )}
      data-testid="default-imageparagraph-wrapper"
    >
      {imageParagraph.title && (
        <div
          data-testid="default-imageparagraph-title-wrapper"
          className={styles.TitleWrapper}
        >
          <h2
            data-testid="default-imageparagraph-title"
            className={styles.Title}
          >
            {imageParagraph.title}
          </h2>
        </div>
      )}

      <figure
        className={classNames({
          [getFormatClasses(imageParagraph.format)]:
            origin !== MULTI_COLUMNS_PARAGRAPH &&
            !imageParagraph.fullWidth &&
            !heroMediaParagraph,
          [styles.InfoBoxMargin]: origin === INFO_BOX_TYPE,
          [styles.Wrapper]: !heroMediaParagraph,
        })}
      >
        <div className={styles.ImageInnerWrapper}>
          {!heroMediaParagraph && (
            <FullscreenButton imageId={imageParagraph.id} origin={origin} />
          )}
          {((imagePath || originalUrl) && (
            <Picture
              url={originalUrl}
              showOriginal={imageParagraph.image?.showOriginal}
              relativeOrigin={imagePath}
              focalPointX={imageParagraph.image?.file?.focalPointX}
              focalPointY={imageParagraph.image?.file?.focalPointY}
              alt={imageAlt}
              className={classNames(
                IMAGE_PARAGRAPH_IDENTIFIER,
                sections.Section,
              )}
              {...imageStyles}
            />
          )) ||
            null}
        </div>
        {!heroMediaParagraph &&
          (imageParagraph.caption || imageParagraph.image?.credit) && (
            <ImageCaption
              addClass={helpers.Margin0}
              caption={imageParagraph.caption}
              credit={imageParagraph.image?.credit}
              suppressSource={imageParagraph.suppressSource}
            />
          )}
      </figure>
    </div>
  );
};

export default DefaultImageParagraph;
