import React from 'react';
import classNames from 'classnames';
import teaserFactory from '../../../../../../../common/components/Teaser/factory';
import Icon from '../../../Icon';
import { STYLE_16X9_220 } from '../../../../../../../shared/constants/images';
import styles from './styles.legacy.css';
import { GetElementByProps } from '../../../../../../../common/components/Teaser/typings';
import { TeaserVideoSProps } from './typings';

type TeaserVideoSPropsInner = TeaserVideoSProps;

const getIconByProps: GetElementByProps<TeaserVideoSPropsInner> = ({
  isActive,
}) => (
  <Icon
    type="IconMovieButton"
    addClass={classNames(styles.VideoIcon, { [styles.IsActive]: isActive })}
  />
);

const TeaserVideoS = teaserFactory({
  icon: getIconByProps,
  styles: {
    Wrapper: styles.Wrapper,
    ImageWrapper: styles.ImageWrapper,
    ContentWrapper: styles.ContentWrapper,
    Title: styles.Title,
    ShortTitle: styles.ShortTitle,
  },
  teaserImageStyles: { style_320: STYLE_16X9_220 },
});

export default TeaserVideoS;
