import classNames from 'classnames';
import badgeFactory from '../../../../../common/components/Badge/factory';
import { BADGE_COLOR_DEFAULT } from '../../../../../common/components/Badge/constants';
import styles from './styles.legacy.css';
import type { BadgeFactoryOptionsStyles } from '../../../../../common/components/Badge/typings';
import type { BadgeProps } from './typings';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const markStyleAsUsed = [
  styles.ContentPetrol,
  styles.ContentBlue,
  styles.ContentPurple,
  styles.ContentBlack,
  styles.ContentGrayD,
];
type BadgePropsInner = BadgeProps;

export const getStylesByProps = ({
  isSmall,
  color,
}: BadgePropsInner): BadgeFactoryOptionsStyles => {
  const themedStyles: BadgeFactoryOptionsStyles = {
    Wrapper: styles.Wrapper,
    Content: classNames(styles.Content, {
      [styles.ContentSmaller]: isSmall,
    }),
  };

  const firstUpper = (value: string): string => {
    return value.charAt(0).toUpperCase() + value.slice(1);
  };

  if (
    color &&
    styles[`Content${firstUpper(color)}`] &&
    color !== BADGE_COLOR_DEFAULT
  ) {
    themedStyles['Content'] = classNames(
      styles.Content,
      styles[`Content${firstUpper(color)}`],
      {
        [styles.ContentSmaller]: isSmall || false,
      },
    );
  }

  return themedStyles;
};

const Badge = badgeFactory({
  styles: getStylesByProps,
});

export default Badge;
