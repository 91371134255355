import React from 'react';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import settingsStateSelector from '../../../../shared/selectors/settingsStateSelector';
import LandingPageDefault from './components/LandingPageDefault';
import LandingPageHome from './components/LandingPageHome';
import LandingPageSpecial from './components/LandingPageSpecial';
import { MAIN_CHANNEL_HOME } from '../../constants';
import { CHANNEL_TYPE_SPECIAL } from '../../screens/Channel/constants';
import { ActiveMainChannel } from '../../../../shared/types';
import { LandingPageProps } from './typings';

type LandingPagePropsInner = LandingPageProps & {
  activeMainChannel: ActiveMainChannel;
};

const LandingPage = ({
  landingPage,
  location,
  activeMainChannel,
  page,
}: LandingPagePropsInner) => {
  if (activeMainChannel === MAIN_CHANNEL_HOME) {
    return <LandingPageHome landingPage={landingPage} location={location} />;
  }

  if (landingPage.channel?.channelType === CHANNEL_TYPE_SPECIAL) {
    return (
      <LandingPageSpecial
        landingPage={landingPage}
        page={page}
        location={location}
      />
    );
  }

  return (
    <LandingPageDefault
      landingPage={landingPage}
      page={page}
      location={location}
    />
  );
};

const mapStateToProps = (state) => ({
  activeMainChannel: settingsStateSelector(state).activeMainChannel,
});

export default compose(connect(mapStateToProps))(LandingPage);
