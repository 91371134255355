import React, { ReactElement } from 'react';
import classNames from 'classnames';
import { IMAGE_GALLERY_DETAIL_SCREEN } from '../../../../screens/ImageGalleryArticle/constants';
import { VIDEO_PAGE } from '../../../../screens/Video/constants';
import helpers from '../../../../assets/styles/helpers.legacy.css';
import styles from './styles.legacy.css';
import { ImageCaptionProps } from './typings';

export type ImageCaptionPropsInner = ImageCaptionProps;

const ImageCaption = ({
  addClass = '',
  caption,
  credit,
  origin,
  suppressSource,
}: ImageCaptionPropsInner): ReactElement => {
  if (!caption && !credit) {
    return null;
  }
  return (
    <figcaption
      className={classNames(styles.TextWrapper, {
        [addClass]: !!addClass,
        [styles.GalleryDetailSpacing]: origin === IMAGE_GALLERY_DETAIL_SCREEN,
        [styles.TextWrapperVideoDetail]: origin === VIDEO_PAGE,
      })}
    >
      {caption && origin !== VIDEO_PAGE && (
        <span
          className={classNames(styles.CaptionAuthorParagraph, {
            [styles.ParagraphWhite]: origin === IMAGE_GALLERY_DETAIL_SCREEN,
          })}
          dangerouslySetInnerHTML={{ __html: `${caption} ` }}
        />
      )}
      {credit && !suppressSource && (
        <span
          className={classNames({
            [styles.CaptionAuthorCopyRight]:
              origin === IMAGE_GALLERY_DETAIL_SCREEN,
            [styles.CaptionAuthorCopyRightGray]:
              origin !== IMAGE_GALLERY_DETAIL_SCREEN,
            [helpers.TextNowrap]: origin === IMAGE_GALLERY_DETAIL_SCREEN,
          })}
          dangerouslySetInnerHTML={{ __html: credit }}
        />
      )}
    </figcaption>
  );
};

export default ImageCaption;
