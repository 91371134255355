import React, { ReactElement } from 'react';
import classNames from 'classnames';
import magazineIssueFactory from '../../../../../../../common/components/Teaser/components/MagazineIssue/factory';
import Link from '../../../../../../../common/components/LinkLegacy';
import TestFragment from '../../../../../../../shared/tests/components/TestFragment';
import ArrowButton from '../../../ArrowButton';
import { TRACKING_CLASS_PRINT_TEASER } from '../../../../../../../shared/constants/tracking';
import styles from './styles.legacy.css';
import {
  TeaserMagazineIssueFactoryOptionsStyles,
  TeaserMagazineIssueProps,
} from '../../../../../../../common/components/Teaser/components/MagazineIssue/typings';

export const getLinkButtonByProps = ({ link, children }): ReactElement => {
  return (
    <TestFragment data-testid="TeaserMagazineIssue-LinkButton-Wrapper">
      <Link link={link} className={TRACKING_CLASS_PRINT_TEASER}>
        <ArrowButton large>{children}</ArrowButton>
      </Link>
    </TestFragment>
  );
};

export const getStyleByProps = (
  props: TeaserMagazineIssueProps,
): TeaserMagazineIssueFactoryOptionsStyles => {
  const isSpecialOffer = props.issue?.magazine?.isSpecialOffer || false;

  return {
    Wrapper: styles.Wrapper,
    ImageContentWrapper: styles.ImageContentWrapper,
    ImageWrapper: classNames(styles.ImageWrapper, {
      [styles.ImageWrapperSpecialOffer]: isSpecialOffer,
    }),
    Image: classNames(styles.Image, {
      [styles.ImageSpecialOffer]: isSpecialOffer,
    }),
    SpecialOfferWrapper: styles.SpecialOfferWrapper,
    SpecialOfferText: styles.SpecialOfferText,
    ContentWrapper: classNames(styles.ContentWrapper, {
      [styles.ContentWrapperSpecialOffer]: isSpecialOffer,
    }),
    IssuePublishedText: styles.IssuePublishedText,
    IssueLink: styles.IssueLink,
    MagazineText: styles.MagazineText,
    SkeletonButton: styles.SkeletonButton,
    SkeletonIssuePublished: styles.SkeletonIssuePublishedText,
    SkeletonTitle: styles.SkeletonTitle,
  };
};

const TeaserMagazineIssue = magazineIssueFactory({
  articleBoxFallbackText: "Désirez-vous lire plus d'article pareils?",
  CTAButton: getLinkButtonByProps,
  styles: getStyleByProps,
});

export default TeaserMagazineIssue;
