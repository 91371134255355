import {
  VIEWPORT_MD,
  VIEWPORT_SM,
  VIEWPORT_XS,
} from '../../../shared/actions/window';
import {
  LARGE_ARTICLE_SCROLL_OFFSET,
  LARGE_MARKETING_LANDING_PAGE_SCROLL_OFFSET,
  MEDIUM_ARTICLE_SCROLL_OFFSET,
  MEDIUM_MARKETING_LANDING_PAGE_SCROLL_OFFSET,
  MOBILE_ARTICLE_SCROLL_OFFSET,
  MOBILE_MARKETING_LANDING_PAGE_SCROLL_OFFSET,
} from '../../screens/App/constants';

export const getScrollOffset = (isMarketingLandingPage, viewport): number => {
  switch (viewport) {
    case VIEWPORT_XS:
      return isMarketingLandingPage
        ? MOBILE_MARKETING_LANDING_PAGE_SCROLL_OFFSET
        : MOBILE_ARTICLE_SCROLL_OFFSET;
    case VIEWPORT_SM:
    case VIEWPORT_MD:
      return isMarketingLandingPage
        ? MEDIUM_MARKETING_LANDING_PAGE_SCROLL_OFFSET
        : MEDIUM_ARTICLE_SCROLL_OFFSET;
    default:
      return isMarketingLandingPage
        ? LARGE_MARKETING_LANDING_PAGE_SCROLL_OFFSET
        : LARGE_ARTICLE_SCROLL_OFFSET;
  }
};
