import React, { ComponentType } from 'react';
import classNames from 'classnames';
import useInView from '../../../../../../../shared/hooks/useInView';
import Link from '../../../../../../../common/components/Link';
import Picture from '../../../../../../../common/components/Picture';
import ArrowButton from '../../../ArrowButton';
import TeaserSubscriptionM from '../TeaserSubscriptionM';
import { STYLE_BOOK_TEASER } from '../../../../../../../shared/constants/images';
import { ARROW_BUTTON_THEME_LIGHT } from '../../../ArrowButton/constants';
import grid from '../../../../../../../common/assets/styles/grid.legacy.css';
import styles from './styles.legacy.css';
import { TeaserFactoryProps } from '../../../../../../../common/components/Teaser/typings';

type TeaserSubscriptionLPropsInner = TeaserFactoryProps & TeaserInterface;

const TeaserSubscriptionL: ComponentType<TeaserFactoryProps> = (
  props: TeaserSubscriptionLPropsInner,
) => {
  const {
    shortTitle,
    teaserImage,
    title,
    link,
    lead,
    trackingData,
    trackingSelector = '',
  } = props;

  const { setRef, isInView } = useInView({
    rootMargin: '200px',
    triggerOnce: true,
  });

  if (!link || !link.path || !link.label || !title) {
    return null;
  }

  const teaserImagePath = teaserImage.image?.file?.relativeOriginPath || '';
  const teaserImageAlt = teaserImage.image?.file?.alt || '';

  return (
    <>
      <div
        data-testid="subscription-l-mobile-viewport-wrapper"
        className={classNames(styles.TeaserWrapper, grid.HiddenSmUp)}
      >
        <TeaserSubscriptionM {...props} />
      </div>

      <div
        ref={setRef}
        className={classNames(styles.TeaserWrapper, grid.HiddenSmDown)}
      >
        <div className={styles.ContentWrapper}>
          {shortTitle && (
            <div data-testid="teaser-shortTitle" className={styles.ShortTitle}>
              {shortTitle}
            </div>
          )}
          <div data-testid="teaser-title" className={styles.Title}>
            {title}
          </div>
          {/*
          ugly temporary hack with dangerouslySetInnerHTML, because lead field has no WYSIWYG editor in BE
          the regex matches the beginning of a string until the first colon ":"
          $& is the matched result of my regex
        */}
          {lead && (
            <div
              data-testid="teaser-lead"
              className={styles.Lead}
              dangerouslySetInnerHTML={{
                __html: lead
                  .split('\n')
                  .map((item) =>
                    item.replace(/.+?(?=:)/, '<strong>$&</strong>'),
                  )
                  .join('\n'),
              }}
            />
          )}
        </div>
        <div className={styles.BannerWrapper}>
          {teaserImagePath && (
            <Link
              path={link.path}
              className={classNames({
                [trackingSelector]: !!trackingSelector,
              })}
              trackingData={trackingData}
              target={'_blank'}
              aria-label={teaserImageAlt}
            >
              {isInView && (
                <Picture
                  relativeOrigin={teaserImagePath}
                  focalPointX={teaserImage?.image?.file?.focalPointX}
                  focalPointY={teaserImage?.image?.file?.focalPointY}
                  alt={teaserImageAlt}
                  className={styles.Image}
                  style_320={STYLE_BOOK_TEASER}
                />
              )}
            </Link>
          )}

          <Link
            path={link.path}
            className={classNames({
              [trackingSelector]: !!trackingSelector,
            })}
            trackingData={trackingData}
            target={'_blank'}
          >
            <ArrowButton
              theme={ARROW_BUTTON_THEME_LIGHT}
              large
              addClass={styles.ArrowButtonStyle}
            >
              {link.label}
            </ArrowButton>
          </Link>
        </div>
      </div>
    </>
  );
};

export default TeaserSubscriptionL;
