import { Store, applyMiddleware, compose, createStore } from 'redux';
import thunk from 'redux-thunk';
import urlMod from 'url';
import { parseSearchQuery } from '../../shared/helpers/parseSearchQuery';
import { initialState as pianoInitialState } from '../../shared/reducers/piano';
import { windowInitialState } from '../../shared/reducers/window';
import createReducer from '../shared/reducers';
import { routeInitialState } from './reducers/route';
import { settingsInitialState } from './reducers/settings';
import { PUBLICATION_ILE } from '../../shared/constants/publications';
import { MAIN_CHANNEL_HOME } from '../screens/App/constants';

type ConfigureServerStore = {
  url: any;
};

/**
 * configure client store
 *
 * @desc    prepare store for client
 * @param   {Object}  initialState
 * @returns {Store}
 */
export const configureClientStore = () => {
  const initialState =
    (global.__INITIAL_STATE__ &&
      JSON.parse(JSON.stringify(global.__INITIAL_STATE__))) ||
    {};
  return configureStore(initialState);
};

/**
 * configure server store
 *
 * @desc    prepare store for server
 * @param   {Object}  initialState
 * @returns {Store}
 */
export const configureServerStore = ({ url }: ConfigureServerStore) => {
  const initialState = JSON.parse(
    JSON.stringify({
      settings: settingsInitialState,
      window: windowInitialState,
      route: routeInitialState,
      piano: pianoInitialState,
    }),
  );
  const parsedUri = urlMod.parse(url);

  // set route/location state on ssr
  // INFO: if you make changes, make sure to implement it here as well: src/schweizer-illustrierte/shared/decorators/withReachRouterRedux.js
  initialState.route.locationBeforeTransitions.action = 'PUSH';
  initialState.route.locationBeforeTransitions.pathname = parsedUri.pathname;
  initialState.route.locationBeforeTransitions.search = parsedUri.search || '';
  initialState.route.locationBeforeTransitions.query = parseSearchQuery(
    parsedUri.search,
  );
  initialState.route.screenReady = true; // everything ready due to SSR pre-rendering

  // set piano/pageMetadata state on ssr
  initialState.piano.pageMetadata.pathname = parsedUri.pathname;
  initialState.piano.pageMetadata.publication = PUBLICATION_ILE;

  // set the activeMainChannel for ssr
  if (parsedUri.pathname && parsedUri.pathname === '/') {
    initialState.settings.activeMainChannel = MAIN_CHANNEL_HOME;
  } else if (parsedUri.pathname) {
    // slice the leading slash and split the uri
    const splitedPath: Record<string, any> = parsedUri.pathname
      .slice(1)
      .split('/');
    const channel: string = splitedPath[0];
    const firstCharUpper = channel.charAt(0).toUpperCase();
    initialState.settings.activeMainChannel = firstCharUpper + channel.slice(1);
  }

  return configureStore(initialState);
};

// ---------------------------------------------------------------------------------- //
// HELPERS
// ---------------------------------------------------------------------------------- //

const devToolsExtension =
  (__CLIENT__ && window.__REDUX_DEVTOOLS_EXTENSION__) || (() => (noop) => noop);

/**
 * configure store
 *
 * @desc    configures store with passed props
 * @param   {Object}  initialState
 * @returns {Store}
 */
export const configureStore = (initialState = {}) => {
  const enhancers: Array<any> = [applyMiddleware(thunk)];

  if (__DEVELOPMENT__) {
    enhancers.push(devToolsExtension());
  }

  // Create the store with the enhancers.
  const store: Store<any, any> = createStore(
    createReducer({}),
    initialState,
    compose(...enhancers),
  );

  // Extend the store with asynchronous reducers.
  const extendedStore = {
    ...store,
    asyncReducers: {},
  };

  return extendedStore;
};
