import classNames from 'classnames';
import { compose, lifecycle } from 'recompose';
import withImpressionTrack from '../../../../shared/decorators/withImpressionTrack';
import recommendationsFactory from '../../../../../common/components/Recommendations/components/Recommendations/factory';
import Teaser from '../Teaser';
import { STYLE_3X2_PLACEHOLDER_DATA } from '../../../../../shared/constants/images';

import grid from '../../../../../common/assets/styles/grid.legacy.css';
import styles from './styles.legacy.css';
import { RecommendationsProps } from '../../../../../common/components/Recommendations/components/Recommendations/typings';

const getStylesByProps = ({
  isInsideParagraph,
  isBlack,
  titleLinkPath,
}: RecommendationsProps) => {
  return {
    RecommendationItem: classNames({
      [styles.RecommendationsItemRelated]: isInsideParagraph,
      [grid.ColXs24]: isInsideParagraph,
      [grid.ColSm12]: isInsideParagraph,
      [styles.RecommendationsItem]: !isInsideParagraph,
      [grid.ColSm6]: !isInsideParagraph,
    }),
    Wrapper: isInsideParagraph
      ? classNames(
          styles.RecommendationsContainerRelated,
          grid.ColXs22,
          grid.ColOffsetXs1,
        )
      : classNames(styles.RecommendationsContainer),
    Title: classNames({
      [styles.SectionTitleRelated]: isInsideParagraph,
      [styles.SectionTitle]: !isInsideParagraph,
      [styles.IsBlack]: !isInsideParagraph && isBlack,
      [styles.TitleHover]: titleLinkPath,
    }),
    RecommendationsListContainer: classNames(
      grid.Row,
      styles.RecommendationsListContainer,
    ),
  };
};

const Recommendations = recommendationsFactory({
  Teaser: Teaser,
  skeletonPlaceholderImg: STYLE_3X2_PLACEHOLDER_DATA,
  styles: getStylesByProps,
});

const withLifecycle = lifecycle({
  shouldComponentUpdate(nextProps: RecommendationsProps) {
    return (
      JSON.stringify(this.props.items) !== JSON.stringify(nextProps.items) ||
      this.props.title !== nextProps.title ||
      this.props.titleLinkPath !== nextProps.titleLinkPath ||
      this.props.isInsideParagraph !== nextProps.isInsideParagraph ||
      this.props.isBlack !== nextProps.isBlack
    );
  },
});

export default compose(
  withLifecycle,
  withImpressionTrack({ propName: 'items' }),
)(Recommendations);
