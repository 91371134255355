/* istanbul ignore file */

import React from 'react';
import statusPageFactory from './../../../../../common/screens/StatusPage/factory';
import { setLoading, setScreenReady } from '../../../../shared/actions/route';
import Helmet from './../../components/Helmet';
import SVGIcon from './../../components/SVGIcon';
import SearchForm from './../../components/SearchForm/themes/SearchFormResultPage';
import {
  SVG_ICONS_TYPE_ILE_404,
  SVG_ICONS_TYPE_ILE_451,
} from './../../components/SVGIcon/constants';
import styles from './styles.legacy.css';
import { StatusCodeConfig } from './../../../../../common/screens/StatusPage/typings';

const statusCodeConfig: StatusCodeConfig = {
  404: {
    icon: <SVGIcon type={SVG_ICONS_TYPE_ILE_404} className={styles.Icon} />,
    title: { text: 'Oups, cette page est introuvable' },
    description: {
      text: 'La page souhaitée n’existe pas. Allez en bas de page pour retrouver nos contenus ou utilisez notre moteur de recherche.',
    },
    metaTitle: 'Page introuvable',
  },
  503: {
    icon: <SVGIcon type={SVG_ICONS_TYPE_ILE_404} className={styles.Icon} />,
    title: { text: 'Système hors service' },
    description: {
      text: 'Le système est en panne',
    },
    metaTitle: 'Hors Service',
  },
  451: {
    icon: <SVGIcon type={SVG_ICONS_TYPE_ILE_451} className={styles.Icon} />,
    title: { text: 'Cet article n`est pas disponible dans votre pays' },
    description: {
      text: 'Veuillez nous excuser, mais cet article n`est pas disponible dans votre lieu de séjour.',
    },
    metaTitle: 'Cet article n`est pas disponible dans votre pays',
    showSearchForm: false,
  },
};

const StatusPage = statusPageFactory({
  statusCodeConfig,
  searchForm: <SearchForm minQueryLength={2} />,
  Helmet,
  styles: {
    Wrapper: styles.Wrapper,
    Container: styles.Container,
    Row: styles.Row,
    Columns: styles.Columns,
    HeaderWrapper: styles.HeaderWrapper,
    IconWrapper: styles.IconWrapper,
    Icon: styles.Icon,
    Title: styles.Title,
    Description: styles.Description,
    SearchWrapper: styles.SearchWrapper,
  },
  setLoading,
  setScreenReady,
});

export default StatusPage;
