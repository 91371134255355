/* istanbul ignore file */

import { connect } from 'react-redux';
import classNames from 'classnames';
import utilityOverlayFactory from '../../../../../../../common/components/UtilityBar/components/UtilityOverlay/factory';
import headerStateSelector from '../../../../../../../shared/selectors/headerStateSelector';
import UtilityBar from '../../../UtilityBar';
import { UTILITY_BAR_OVERLAY_ORIGIN_HEADER } from '../../../../../../../common/components/UtilityBar/components/UtilityOverlay/constants';
import { IMAGE_GALLERY_CONTENT_TYPE } from '../../../../../../../shared/constants/content';
import styles from './styles.legacy.css';
import {
  UtilityOverlayFactoryOptionsStyles,
  UtilityOverlayProps,
} from '../../../../../../../common/components/UtilityBar/components/UtilityOverlay/typings';

type UtilityOverlayPropsInner = UtilityOverlayProps & {
  activeMainChannel: string;
  headerContentType: string;
};

const getStyleByProps = ({
  origin,
  headerContentType,
}: UtilityOverlayPropsInner): UtilityOverlayFactoryOptionsStyles => {
  return {
    Wrapper: classNames(styles.Wrapper, {
      [styles.WrapperHeaderOverlay]:
        origin === UTILITY_BAR_OVERLAY_ORIGIN_HEADER,
      [styles.WrapperInPageOverlay]:
        origin !== UTILITY_BAR_OVERLAY_ORIGIN_HEADER,
      [styles.WrapperPageOverlay]:
        origin !== UTILITY_BAR_OVERLAY_ORIGIN_HEADER &&
        headerContentType !== IMAGE_GALLERY_CONTENT_TYPE,
    }),
    WrapperSticky: styles.WrapperSticky,
    WrapperToggle: styles.WrapperToggle,
    Title: styles.Title,
    CloseButton: styles.CloseButton,
    UtilityBarWrapper: classNames(
      'utility-bar-wrapper',
      styles.UtilityBarWrapper,
    ),
  };
};

const mapStateToProps = (state) => ({
  headerContentType: headerStateSelector(state).contentType,
});

export default connect(mapStateToProps)(
  utilityOverlayFactory({
    UtilityBar,
    styles: getStyleByProps,
  }),
);
