/* istanbul ignore file */

import { ComponentType } from 'react';
import tooltipFactory from '../../../../../common/components/Tooltip/factory';
import Link from '../../../../../common/components/LinkLegacy';
import styles from './styles.legacy.css';
import { TooltipFactoryProps } from '../../../../../common/components/Tooltip/typings';

type TooltipPropsInner = TooltipFactoryProps;

const Tooltip: ComponentType<TooltipPropsInner> = tooltipFactory({
  Link,
  styles: {
    Wrapper: styles.Wrapper,
    Button: styles.Button,
    ButtonText: styles.ButtonText,
    ButtonOpen: styles.ButtonOpen,
    ButtonTextOpen: styles.ButtonTextOpen,
    ButtonTextClosed: styles.ButtonTextClosed,
    TooltipWrapper: styles.TooltipWrapper,
    Tooltip: styles.Tooltip,
    Content: styles.Content,
    Link: styles.Link,
  },
});

export default Tooltip;
