import React from 'react';
import compose from 'recompose/compose';
import parseTrackingData from '../../../../../shared/helpers/parseTrackingData';
import withAppNexus from '../../../../shared/decorators/withAppNexus';
import withHelmet from '../../../../shared/decorators/withHelmet';
import TestFragment from '../../../../../shared/tests/components/TestFragment';
import Breadcrumbs from '../../components/Breadcrumbs';
import OverviewPage from '../../components/OverviewPage';
import StatusPage from '../StatusPage';
import { ROOT_SCHEMA_TYPE_WEB_PAGE } from '../../../../../shared/constants/structuredData';
import { GRID_LAYOUT_BLOGS } from '../../components/TeaserGrid/gridConfigs/constants';
import { SITE_TITLE } from '../../constants';
import { PAGE_SIZE } from './constants';
import { PodcastsProps } from './typings';

type PodcastsPropsInner = PodcastsProps & {
  loading: boolean;
  data: QueryRoot & {
    environment: Route & {
      routeByPath: Route;
      termsByVocabulary: Channel;
    };
  };
};

const getFallbackTitle = (landingPage: LandingPage) =>
  `${landingPage?.title || 'Podcasts'} - ${SITE_TITLE}`;

const Podcasts = ({ data, location, page, loading }: PodcastsPropsInner) => {
  if (
    !data ||
    !data.environment ||
    !data.environment.termsByVocabulary ||
    !data.environment.routeByPath ||
    !data.environment.routeByPath.object
  ) {
    return loading ? null : <StatusPage statusCode={404} />;
  }

  const podcasts: ChannelConnection = data.environment.termsByVocabulary;
  const landingPage: LandingPage = data.environment.routeByPath.object;

  const termSettings: TermSettings = {
    title: landingPage.title || 'Podcasts',
    lead: landingPage.lead,
  };

  const updatedData: {
    routeObject: Channel;
  } = (podcasts.edges || []).reduce(
    (updatedData, podcast: ChannelEdge) => {
      const edges = updatedData.routeObject.entities.edges;

      const podcastNode = podcast.node || null;

      if (podcastNode && podcastNode.showOnPodcastOverview) {
        // add TeaserChannel at first place in row
        edges.push({ node: podcastNode });

        // we start with placeholders to keep grid: 1 channel teaser, then three teasers with articles
        const podcastArticles = [null, null, null];

        if (podcastNode.entities?.items) {
          podcastNode.entities.items.forEach(
            (podcastPost, index) => (podcastArticles[index] = podcastPost),
          );
        }

        // if podcast has podcast posts add them to gridConfig
        podcastArticles.forEach((podcastPost: Article) => {
          edges.push({ node: podcastPost });
        });
      }

      return updatedData;
    },
    {
      routeObject: {
        entities: {
          edges: [],
        },
      },
    },
  );
  updatedData.routeObject.entities.count =
    data.environment.termsByVocabulary.count;
  return (
    <TestFragment data-testid="podcasts-container">
      {landingPage.preferredUri && landingPage.activeMenuTrail && (
        <Breadcrumbs
          pageUrl={landingPage.preferredUri}
          items={landingPage.activeMenuTrail}
        />
      )}

      <OverviewPage
        location={location}
        routeObject={updatedData.routeObject}
        termSettings={termSettings}
        gridConfig={GRID_LAYOUT_BLOGS}
        paragraphType="BlogsScreen"
        page={page}
        pageSize={PAGE_SIZE}
      />
    </TestFragment>
  );
};

export default compose(
  withHelmet({
    getNode: (mapProps: PodcastsPropsInner): RouteObjectInterface =>
      mapProps.data?.environment?.routeByPath?.object || null,
    getFallbackTitle: (mapProps: PodcastsPropsInner): string =>
      getFallbackTitle(mapProps.data?.environment?.routeByPath?.object),
    getNodesCount: (mapProps): number =>
      mapProps.data?.environment?.routeByPath?.object.entities?.count || 0,
    pageSize: () => PAGE_SIZE,
    rootSchemaType: ROOT_SCHEMA_TYPE_WEB_PAGE,
    getNodes: (mapProps) =>
      mapProps?.environment?.routeObject?.entities?.edges || [],
  }),
  withAppNexus({
    parseTrackingData,
  }),
)(Podcasts);
