/* istanbul ignore file */

import classNames from 'classnames';
import utilityLinkFactory from '../../../../../../../common/components/UtilityBar/components/UtilityLink/factory';
import SVGIcon from '../../../SVGIcon';
import {
  UTILITY_BAR_ORIGIN_INLINE_OVERLAY,
  UTILITY_BAR_ORIGIN_OVERLAY,
  UTILITY_BAR_THEME_LIGHT,
} from '../../../../../../../shared/constants/utilitybar';
import styles from './styles.legacy.css';
import {
  UtilityLinkFactoryOptionsStyles,
  UtilityLinkProps,
} from '../../../../../../../common/components/UtilityBar/components/UtilityLink/typings';

type UtilityLinkPropsInner = UtilityLinkProps;

const getStyleByProps = ({
  origin,
  theme,
}: UtilityLinkPropsInner): UtilityLinkFactoryOptionsStyles => {
  return {
    Link: classNames(styles.Link, styles.LinkHover, {
      [styles.LinkOverlay]: origin === UTILITY_BAR_ORIGIN_OVERLAY,
      [styles.LinkOverlayInlined]: origin === UTILITY_BAR_ORIGIN_INLINE_OVERLAY,
      [styles.LinkLight]: theme === UTILITY_BAR_THEME_LIGHT,
    }),
    Active: styles.Active,
    Label: styles.Label,
    CommentCount: styles.CommentCount,
    Badge: styles.Badge,
    Icon: styles.Icon,
    Restricted: styles.Restricted,
  };
};

export default utilityLinkFactory({
  SVGIcon,
  styles: getStyleByProps,
  appAriaLabel: 'Lien vers',
});
