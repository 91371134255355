import React, { ReactElement } from 'react';
import classNames from 'classnames';
import teaserFactory from '../../../../../../../common/components/Teaser/factory';
import { withTeaserTrackingHandler } from '../../../../../../../common/components/Teaser/helpers';
import { enrichBadgeProps, isIconVisible } from '../../shared/helpers';
import Badge from '../../../Badge';
import Icon from '../../../Icon';
import {
  IMAGE_GALLERY_CONTENT_TYPE,
  VIDEO_CONTENT_TYPE,
} from '../../../../../../../shared/constants/content';
import {
  FULLSCREEN_HASH,
  FULLSCREEN_HASH_TEASER_CLICK,
} from '../../../../../../../shared/constants/fullscreen';
import {
  STYLE_16X9_800,
  STYLE_16X9_890,
  STYLE_3X2_280,
  STYLE_3X2_440,
  STYLE_3X2_770,
} from '../../../../../../../shared/constants/images';
import styles from './styles.legacy.css';
import {
  GetElementByProps,
  TeaserFactoryProps,
} from '../../../../../../../common/components/Teaser/typings';

type TeaserHeroXl2PropsInner = TeaserFactoryProps;

const getBadgeByProps: GetElementByProps<TeaserHeroXl2PropsInner> = ({
  badgeLabel,
  badgeColor,
  __typename,
  subtypeValue,
}: TeaserHeroXl2PropsInner): ReactElement | null => {
  if (!badgeLabel) {
    return null;
  }

  return (
    <div className={styles.Badge}>
      <Badge
        {...enrichBadgeProps(badgeColor, badgeLabel, __typename, subtypeValue)}
      />
    </div>
  );
};

const getIconPositionByProps = ({ __typename }: TeaserHeroXl2PropsInner) =>
  __typename === VIDEO_CONTENT_TYPE;

const getIconByProps: GetElementByProps<TeaserHeroXl2PropsInner> = ({
  hasVideo,
  __typename,
}) => {
  if (!isIconVisible(hasVideo, __typename)) {
    return null;
  }

  return (
    <Icon
      type={classNames({
        IconCamera: hasVideo,
        IconFotoMarker: __typename === IMAGE_GALLERY_CONTENT_TYPE,
        IconMovieOutline: __typename === VIDEO_CONTENT_TYPE,
      })}
      addClass={classNames({
        [styles.IconStyle]: __typename !== VIDEO_CONTENT_TYPE,
        [styles.IconMovieOutlineStyle]: __typename === VIDEO_CONTENT_TYPE,
      })}
    />
  );
};

const TeaserHeroXl2 = teaserFactory({
  badge: getBadgeByProps,
  isIconPositionOnImage: getIconPositionByProps,
  icon: getIconByProps,
  trackingTeaserHandler: withTeaserTrackingHandler,
  styles: {
    Wrapper: styles.Wrapper,
    Title: styles.Title,
    TitleInner: styles.TitleInner,
    Image: styles.Image,
    ContentWrapper: styles.ContentWrapper,
    ShortTitle: styles.ShortTitle,
    IconStyle: styles.IconStyle,
  },
  fullScreenHashTeaserClick: FULLSCREEN_HASH_TEASER_CLICK,
  teaserImageStyles: {
    style_320: STYLE_3X2_280,
    style_480: STYLE_3X2_440,
    style_540: STYLE_16X9_800,
    style_760: STYLE_3X2_770,
    style_960: STYLE_16X9_890,
  },
  fullScreenHash: FULLSCREEN_HASH,
});

export default TeaserHeroXl2;
