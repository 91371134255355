import { ROUTE_HOME_ILE } from '../../../shared/constants/publications';
import variables from './assets/styles/variables.legacy.css';
import { ActiveMainChannel } from './../../shared/types';

export const DEFAULT_PUBLICATION = 'ILE';
export const PUBLISHER = 'illustre.ch';
export const SITE_TITLE = 'Illustré';
export const ADVERTORIAL = 'Publireportage';
export const ROUTE_HOME = ROUTE_HOME_ILE;
export const ROUTE_ACCOUNT = 'account';
export const ROUTE_SEARCH = 'search/:query';
export const ROUTE_VIDEOS = 'videos';
export const ROUTE_PODCASTS = 'podcasts';
export const ROUTE_ALERTS = 'profile/alerts';
export const ROUTE_UNSUBSCRIBE_EMAIL_ALERTS = `${ROUTE_ALERTS}/unsubscribe/email`;
export const ROUTE_BOOKMARKS = 'profile/merkliste';
export const ROUTE_SUBSCRIPTIONS = 'abonnements';
export const ROUTE_LOGOUT = 'logout';
export const ROUTE_451 = '451';
export const ROUTE_STYLEGUIDE = 'styleguide';
export const ROUTE_AUTHORS = 'nos-redacteurs';

export const ROUTES_WITH_CUSTOM_PIANO_TRACKING = [ROUTE_451];
export const CHANNELS_VOCABULARY = 'channels_illustre';
export const CHANNEL_TYPE_VIDEOS = 'VideoBlog';
export const CHANNEL_TYPE_BLOGS = 'Blog';
export const CHANNEL_TYPE_VIDEO_BLOG = 'video_blog';
export const OVERVIEW_VISIBLE_TYPE_VIDEOS = 'Videos';
export const CHANNEL_TYPE_PODCASTS = 'Podcast';
export const OVERVIEW_VISIBLE_TYPE_PODCASTS = 'Podcast';

export const MODAL_ROOT_ID = 'modal-root';

export const MAIN_CHANNEL_DEFAULT: ActiveMainChannel = 'Default';
export const MAIN_CHANNEL_HOME: ActiveMainChannel = 'Home';
export const MAIN_CHANNEL_ACTU: ActiveMainChannel = 'Actu';
export const MAIN_CHANNEL_MAGAZINE: ActiveMainChannel = 'Magazine';
export const MAIN_CHANNEL_PHOTOS: ActiveMainChannel = 'Photos';
export const MAIN_CHANNEL_PLANETE: ActiveMainChannel = 'Planete';
export const MAIN_CHANNEL_VIDEOS: ActiveMainChannel =
  OVERVIEW_VISIBLE_TYPE_VIDEOS;

export const BRIGHTCOVE_ACCOUNT_ID = '2112711546001';
export const BRIGHTCOVE_PLAYER_ID = 'T2BdJtJ3X';

export const GOOGLE_RECAPTCHA_KEY = '6LfE_eAUAAAAACBwz58i-wKLXgRRT0r8cuesIhFS';

export const MOBILE_ARTICLE_SCROLL_OFFSET = parseInt(
  variables.headerHeightXs,
  10,
);
export const MEDIUM_ARTICLE_SCROLL_OFFSET = parseInt(
  variables.headerHeightSm,
  10,
);
export const LARGE_ARTICLE_SCROLL_OFFSET = parseInt(
  variables.headerHeightXl,
  10,
);
export const MOBILE_MARKETING_LANDING_PAGE_SCROLL_OFFSET = parseInt(
  variables.headerHeightMarketingPageXs,
  10,
);
export const MEDIUM_MARKETING_LANDING_PAGE_SCROLL_OFFSET = parseInt(
  variables.headerHeightMarketingPageSm,
  10,
);
export const LARGE_MARKETING_LANDING_PAGE_SCROLL_OFFSET = parseInt(
  variables.headerHeightMarketingPageXl,
  10,
);
