import { gql } from '@apollo/client';

export const parallaxImageParagraphFragment = gql`
  fragment ParallaxImageParagraphFragment on ParallaxImageParagraph {
    anchorId
    id
    suppressSource
    image {
      id
      credit
      file(style: "large") {
        id
        alt
        relativeOriginPath
        focalPointX
        focalPointY
      }
    }
  }
`;
