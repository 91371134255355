import React from 'react';
import compose from 'recompose/compose';
import classNames from 'classnames';
import videoStageFactory from '../../../../../common/components/VideoStage/factory';
import withWaitUntilApolloLoaded from '../../../../shared/decorators/withWaitUntilApolloLoaded';
import Link from '../../../../../common/components/Link';
import SmoothScroll from '../../../../../common/components/SmoothScroll';
import Teaser from '../Teaser';
import UtilityBar from '../UtilityBar';
import UtilityOverlay from '../UtilityBar/components/UtilityOverlay';
import VideoPlayer from '../VideoPlayer';
import ImageCaption from './components/ImageCaption';
import { TEASER_LAYOUT_VIDEO_S } from '../../../../../shared/constants/teaser';
import {
  UTILITY_TYPE_EMAIL,
  UTILITY_TYPE_FACEBOOK,
  UTILITY_TYPE_GETPOCKET,
  UTILITY_TYPE_LINKEDIN,
  UTILITY_TYPE_MESSENGER,
  UTILITY_TYPE_SHARE,
  UTILITY_TYPE_TWITTER,
  UTILITY_TYPE_WHATSAPP,
} from '../../components/UtilityBar/constants';
import grid from '../../../../../common/assets/styles/grid.legacy.css';
import { BREAKPOINTS } from '../../../../../common/assets/styles/variablesDefault.legacy.css';
import styles from './styles.legacy.css';
// @ts-ignore
import sections from '@sections.legacy.css';
import { UtilityBarProps } from '../../../../../common/components/UtilityBar/typings';
import { VideoStageComponent } from '../../../../../common/components/VideoStage/typings';

const VideoStageUtilityBar = ({
  title,
  shareUrl,
  imageUrl,
}: UtilityBarProps) => (
  <UtilityBar
    title={title}
    shareUrl={shareUrl}
    imageUrl={imageUrl}
    enabledUtilities={[UTILITY_TYPE_SHARE]}
  >
    {({ isOverlayVisible, toggleOverlayVisible }) => (
      <div className={styles.UtilityOverlayWrapper}>
        <UtilityOverlay
          title={title}
          shareUrl={shareUrl}
          imageUrl={imageUrl}
          hasStickyness={false}
          overlayTitle="Partager cette vidéo"
          isOverlayVisible={isOverlayVisible}
          toggleOverlayVisible={toggleOverlayVisible}
          enabledUtilities={[
            UTILITY_TYPE_EMAIL,
            UTILITY_TYPE_FACEBOOK,
            UTILITY_TYPE_MESSENGER,
            UTILITY_TYPE_WHATSAPP,
            UTILITY_TYPE_LINKEDIN,
            UTILITY_TYPE_TWITTER,
            UTILITY_TYPE_GETPOCKET,
          ]}
        />
      </div>
    )}
  </UtilityBar>
);

const VideoStage: VideoStageComponent = compose(withWaitUntilApolloLoaded)(
  videoStageFactory({
    Link,
    grid,
    VideoPlayer,
    Teaser,
    SmoothScroll,
    ImageCaption,
    UtilityBar: VideoStageUtilityBar,
    isObserveForAutoplayEnabled: false,
    hasToLazyLoadBrightcoveScript: true,
    isCaptionVisible: false,
    teaserLayout: TEASER_LAYOUT_VIDEO_S,
    viewportsToPerformAnchorScroll: BREAKPOINTS.xsBreakpointTo,
    styles: {
      Wrapper: styles.Wrapper,
      Items: styles.Items,
      IsActive: styles.IsActive,
      LeftBoxCols: classNames(grid.ColXs24, grid.ColSm14, grid.ColXl16),
      RightBoxCols: classNames(grid.ColXs24, grid.ColSm10, grid.ColXl8),
      InnerWrapper: classNames(sections.SectionPullOut, styles.InnerWrapper),
      HeadingWrapper: styles.HeadingWrapper,
      Heading: styles.Heading,
      StageWrapper: styles.StageWrapper,
      ContentWrapper: styles.ContentWrapper,
      Title: styles.Title,
      ShortTitle: styles.ShortTitle,
      UtilityBarWrapper: classNames(
        'utility-bar-wrapper',
        styles.SocialBarWrapper,
      ),
    },
  }),
);

export default VideoStage;
