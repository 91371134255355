import React, { ReactElement } from 'react';
import classNames from 'classnames';
import Picture from '../../../../../../../common/components/Picture';
import SubscribeButton from '../../../SubscribeButton/themes/SubscribeButtonOverviewPage';
import { getAlertItemTypeByTypename } from '../../../../../../../common/components/SubscribeButton/helper';
import { STYLE_1X1_140 } from '../../../../../../../shared/constants/images';
import { TEASER_PLACEHOLDER_PATH } from '../../../Teaser/constants';
import grid from '../../../../../../../common/assets/styles/grid.legacy.css';
import styles from './styles.legacy.css';
import { OverviewPageHeaderProps } from '../../typings';

export type CelebrityPropsInner = OverviewPageHeaderProps;

const OVERVIEW_PAGE_HEADER_IDENTIFIER = 'celebrity-overview-page-header';

const Celebrity = ({
  title,
  lead,
  headerImage,
  alertId,
  alertType,
}: CelebrityPropsInner): ReactElement => {
  if (!title || !headerImage) {
    return null;
  }

  const imgRelativeOriginPath =
    headerImage?.file?.relativeOriginPath || TEASER_PLACEHOLDER_PATH;
  const imgAlt = headerImage?.file?.alt || '';

  return (
    <div
      className={classNames(styles.Wrapper, grid.Container)}
      data-testid="overview-page-header-celebrity-wrapper"
    >
      <div className={grid.Row}>
        <div
          className={classNames(
            grid.ColXs24,
            grid.ColSm20,
            grid.ColOffsetSm2,
            grid.ColMd18,
            grid.ColOffsetSm3,
          )}
        >
          <div className={styles.HeaderImageWrapper}>
            <div className={styles.HeaderImageWrapperInner}>
              {(imgRelativeOriginPath && (
                <Picture
                  relativeOrigin={imgRelativeOriginPath}
                  focalPointX={headerImage?.file?.focalPointX}
                  focalPointY={headerImage?.file?.focalPointY}
                  alt={imgAlt}
                  className={classNames(
                    OVERVIEW_PAGE_HEADER_IDENTIFIER,
                    styles.HeaderImage,
                  )}
                  style_320={STYLE_1X1_140}
                />
              )) ||
                null}
            </div>
          </div>
        </div>
      </div>
      <div className={styles.HeadingOverviewWrapper}>
        <div className={grid.Row}>
          <div
            className={classNames(
              grid.ColXs24,
              grid.ColSm16,
              grid.ColOffsetSm4,
              grid.ColMd14,
              grid.ColOffsetSm5,
            )}
          >
            <h1
              className={classNames(styles.HeadingOverview, {
                [styles.HeadingOverviewWithAlerts]: alertId && alertType,
              })}
            >
              {title}
            </h1>
            {alertId && alertType && (
              <div className={styles.SubscribeButtonWrapper}>
                <SubscribeButton
                  id={Number(alertId)}
                  label={title}
                  type={getAlertItemTypeByTypename(alertType)}
                />
              </div>
            )}
            {lead && (
              <div
                data-testid="overview-page-header-celebrity-lead"
                className={styles.Lead}
              >
                {lead}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Celebrity;
