import React, { useEffect, useRef } from 'react';
import { compose } from 'recompose';
import { enrichOverviewBodyWithADs } from '../../../../../../../shared/helpers/ads';
import { latestNativeAdvertisingsGenerator } from '../../../../../../../shared/helpers/latestNativeAdvertisings';
import { latestNACounter } from '../../../../../../../shared/helpers/useLatestNativeAdvertisings';
import { ensureTeaserInterface } from '../../../../components/Teaser/shared/helpers';
import { prepareBody } from '../../helpers';
import withHelmet from '../../../../../../shared/decorators/withHelmet';
import withScrollToAnchor from '../../../../../../shared/decorators/withScrollToAnchor';
import useRecommendations from '../../../../../../../shared/hooks/useRecommendations';
import Breadcrumbs from '../../../../components/Breadcrumbs';
import EditButtons from '../../../../components/EditButtons';
import MonsterSky from '../../../../components/MonsterSky';
import Paragraphs from '../../../../components/Paragraphs';
import PartnerBanner from '../../../../components/PartnerBanner';
import TeaserGrid from '../../../../components/TeaserGrid';
import StatusPage from '../../../StatusPage';
import OverviewPageHeader, {
  OVERVIEW_PAGE_HEADER_DEFAULT,
} from '../../../../components/OverviewPageHeader';
import Pager, { PAGER_TYPE_PAGE_LOADER } from '../../../../components/Pager';
import { PUBLICATION_GROUP_ILE } from '../../../../../../../shared/constants/publications';
import { RECOMMENDATION_OPERATION } from '../../../../../../../shared/constants/recommendations';
import { ROOT_SCHEMA_TYPE_WEB_PAGE } from '../../../../../../../shared/constants/structuredData';
import {
  LANDING_PAGE_GRID_PAGE_SIZE,
  LANDING_PAGE_TYPE,
  LANDING_PAGE_TYPE_INFO_TOP,
} from '../../constants';
import grid from '../../../../../../../common/assets/styles/grid.legacy.css';
import styles from './styles.legacy.css';
import {
  RecommendationsNode,
  useRecommendationsResponse,
} from '../../../../../../../shared/hooks/useRecommendations/typings';
import { LandingPageProps } from '../../typings';

type LandingPageDefaultPropsInner = LandingPageProps;

const recommendationsOperation =
  RECOMMENDATION_OPERATION.LATEST_NATIVE_ADVERTISINGS;

const LandingPageDefault = ({
  landingPage,
  origin,
  page,
  isAdSuppressed = false,
}: LandingPageDefaultPropsInner) => {
  const { recommendations, fetchRecommendations }: useRecommendationsResponse =
    useRecommendations();
  const fetchRecommendationsRef = useRef(fetchRecommendations);

  const recommendationsLimit = latestNACounter(landingPage.body);

  useEffect(() => {
    if (recommendationsLimit <= 0) {
      return;
    }
    fetchRecommendationsRef.current({
      publication: PUBLICATION_GROUP_ILE,
      articleKeywords: {},
      contentId: '1', // any number, as it gets ignored by mostread anyway
      operation: recommendationsOperation,
      limit: recommendationsLimit,
    });
  }, [recommendationsLimit]);

  const isPaginationValid = page !== 0 && (landingPage.grid?.count || 0) !== 0;

  if (!landingPage || (!isPaginationValid && landingPage.grid)) {
    return <StatusPage />;
  }

  const preparedBody = prepareBody(
    landingPage.body,
    landingPage.paragraphsSubsetSize,
  );

  const preparedBodyWithAds = enrichOverviewBodyWithADs({
    pageBody: preparedBody,
    hasEQsWithMMR: true,
  });

  const hasGridItems =
    landingPage.grid?.edges &&
    Array.isArray(landingPage.grid?.edges) &&
    landingPage.grid?.edges.length > 0;

  let latestNAGenerator: Generator<RecommendationsNode> = null;
  latestNAGenerator = latestNativeAdvertisingsGenerator(recommendations);

  const paragraphsJsx =
    (preparedBodyWithAds && (
      <Paragraphs
        latestNAGenerator={latestNAGenerator}
        pageBody={preparedBodyWithAds}
        forceUpdate={recommendations?.[recommendationsOperation]}
        origin={origin || LANDING_PAGE_TYPE}
        hasContainer={false}
        isAdSuppressed={landingPage?.channel?.suppressAds || isAdSuppressed}
      />
    )) ||
    null;

  return (
    <div className={`landingpage-wrapper ${styles.Wrapper}`}>
      <EditButtons
        editContentUri={landingPage?.editContentUri}
        editRelationUri={landingPage?.editRelationUri}
        cloneContentUri={landingPage?.cloneContentUri}
      />
      <div className={styles.HeaderBackground} />

      <Breadcrumbs
        pageUrl={landingPage.preferredUri}
        items={landingPage.activeMenuTrail}
      />
      {landingPage?.channel?.sponsors?.edges &&
        Array.isArray(landingPage?.channel?.sponsors?.edges) && (
          <PartnerBanner
            sponsors={landingPage?.channel?.sponsors?.edges || []}
          />
        )}
      {!isAdSuppressed ||
        (!landingPage?.channel?.suppressAds && <MonsterSky />)}
      {hasGridItems && (
        <>
          <OverviewPageHeader
            title={landingPage?.title || ''}
            lead={landingPage?.lead || ''}
            component={OVERVIEW_PAGE_HEADER_DEFAULT}
          />
          {landingPage.subtypeValue === LANDING_PAGE_TYPE_INFO_TOP &&
            paragraphsJsx}
          <TeaserGrid
            layout="landscape"
            items={landingPage.grid.edges.map((item) =>
              ensureTeaserInterface(item.node),
            )}
          />
          <div className={grid.Container}>
            <Pager
              component={PAGER_TYPE_PAGE_LOADER}
              currentPage={page}
              itemsCount={landingPage?.grid?.count || 0}
              itemsPerPage={LANDING_PAGE_GRID_PAGE_SIZE}
            />
          </div>
        </>
      )}
      {landingPage.subtypeValue !== LANDING_PAGE_TYPE_INFO_TOP && paragraphsJsx}
    </div>
  );
};

export default compose(
  withHelmet({
    getNode: (mapProps: LandingPageDefaultPropsInner) =>
      mapProps?.landingPage || null,
    getNodesCount: (mapProps: LandingPageDefaultPropsInner) =>
      mapProps?.landingPage?.grid?.count || 0,
    getImage: (mapProps: LandingPageDefaultPropsInner) =>
      mapProps.landingPage?.teaserImage?.image?.file ||
      mapProps.landingPage?.body?.[0]?.entityQueue?.items?.edges?.[0]?.node
        ?.teaserImage?.image?.file ||
      null,
    pageSize: LANDING_PAGE_GRID_PAGE_SIZE,
    getRootSchemaType: (mapProps: LandingPageDefaultPropsInner) =>
      mapProps.location?.pathname !== '/' ? ROOT_SCHEMA_TYPE_WEB_PAGE : '',
    getNodes: (mapProps: LandingPageDefaultPropsInner) =>
      mapProps?.landingPage?.grid?.edges || [],
  }),
  withScrollToAnchor(),
)(LandingPageDefault);
