import React, { ReactElement } from 'react';
import classNames from 'classnames';
import SubscribeButton from '../../../SubscribeButton/themes/SubscribeButtonOverviewPage';
import { getAlertItemTypeByTypename } from '../../../../../../../common/components/SubscribeButton/helper';
import grid from '../../../../../../../common/assets/styles/grid.legacy.css';
import styles from './styles.legacy.css';
import { OverviewPageHeaderProps } from '../../typings';

export type DefaultPropsInner = OverviewPageHeaderProps;

const DefaultOverviewPageHeader = ({
  title,
  lead,
  alertId,
  alertType,
  showFollowButton = false,
}: DefaultPropsInner): ReactElement => {
  if (!title) {
    return null;
  }

  return (
    <div
      data-testid="overview-page-header-default-wrapper"
      className={classNames(grid.Container, styles.Wrapper, {
        [styles.NoLead]: !lead,
      })}
    >
      <div className={grid.Row}>
        <div
          className={classNames(grid.ColXs24, grid.ColSm20, grid.ColOffsetSm2)}
        >
          <div
            className={classNames(styles.HeadingOverviewWrapper, {
              [styles.HeadingOverviewWrapperWithAlerts]: alertId && alertType,
            })}
          >
            <h1 className={styles.HeadingOverview}>{title}</h1>
          </div>

          {alertId && alertType && showFollowButton && (
            <div className={styles.SubscribeButtonWrapper}>
              <SubscribeButton
                id={Number(alertId)}
                label={title}
                type={getAlertItemTypeByTypename(alertType)}
              />
            </div>
          )}
        </div>
      </div>

      {lead && (
        <div
          data-testid="overview-page-header-default-lead"
          className={grid.Row}
        >
          <div
            className={classNames(
              grid.ColXs24,
              grid.ColSm16,
              grid.ColOffsetSm4,
              grid.ColXl12,
              grid.ColOffsetXl6,
            )}
          >
            <div className={styles.Lead}>{lead}</div>
          </div>
        </div>
      )}
    </div>
  );
};

export default DefaultOverviewPageHeader;
