import { gql } from '@apollo/client';

export const videosOverviewTeaserFragment = gql`
  fragment VideosOverviewTeaserFragment on Video {
    id
    title
    preferredUri
    shortTitle
    changeDate
    metaCanonicalUrl
    metaDescription
    metaKeywords
    metaTitle
    caption
    publicationDate
    brightcoveId
    credit
    teaserImage {
      id
      image {
        id
        file(style: "16x9_560") {
          id
          alt
          relativeOriginPath
          focalPointX
          focalPointY
        }
      }
    }
    useAutoHyphens
  }
`;
