import React from 'react';
import classNames from 'classnames';
import teaserFactory from '../../../../../../../common/components/Teaser/factory';
import { withTeaserTrackingHandler } from '../../../../../../../common/components/Teaser/helpers';
import { enrichBadgeProps, isIconVisible } from '../../shared/helpers';
import Badge from '../../../Badge';
import Icon from '../../../Icon';
import {
  IMAGE_GALLERY_CONTENT_TYPE,
  VIDEO_CONTENT_TYPE,
} from '../../../../../../../shared/constants/content';
import {
  FULLSCREEN_HASH,
  FULLSCREEN_HASH_TEASER_CLICK,
} from '../../../../../../../shared/constants/fullscreen';
import {
  STYLE_3X2_280,
  STYLE_3X2_440,
  STYLE_3X2_770,
} from '../../../../../../../shared/constants/images';
import styles from './styles.legacy.css';
import {
  GetElementByProps,
  TeaserFactoryProps,
} from '../../../../../../../common/components/Teaser/typings';

type TeaserXLPropsInner = TeaserFactoryProps;

const getBadgeByProps: GetElementByProps<TeaserXLPropsInner> = (props) => {
  const { badgeLabel, badgeColor } = props;

  if (!badgeLabel) {
    return null;
  }
  return (
    <div className={styles.Badge}>
      <Badge {...enrichBadgeProps(badgeColor, badgeLabel)} />
    </div>
  );
};

const getIconByProps = ({ hasVideo, __typename }: TeaserXLPropsInner) => {
  if (!isIconVisible(hasVideo, __typename)) {
    return null;
  }

  return (
    <Icon
      type={classNames({
        IconCamera: hasVideo,
        IconFotoMarker: __typename === IMAGE_GALLERY_CONTENT_TYPE,
        IconMovieOutline: __typename === VIDEO_CONTENT_TYPE,
      })}
      addClass={classNames({
        [styles.IconStyle]: __typename !== VIDEO_CONTENT_TYPE,
        [styles.IconMovieOutlineStyle]: __typename === VIDEO_CONTENT_TYPE,
      })}
    />
  );
};

const TeaserXL = teaserFactory({
  badge: getBadgeByProps,
  icon: getIconByProps,
  trackingTeaserHandler: withTeaserTrackingHandler,
  styles: {
    Wrapper: styles.Wrapper,
    Title: styles.Title,
    TitleInner: styles.TitleInner,
    Image: styles.Image,
    ContentWrapper: styles.ContentWrapper,
    ShortTitle: styles.ShortTitle,
  },
  teaserImageStyles: {
    style_320: STYLE_3X2_280,
    style_760: STYLE_3X2_440,
    style_1680: STYLE_3X2_770,
  },
  fullScreenHashTeaserClick: FULLSCREEN_HASH_TEASER_CLICK,
  fullScreenHash: FULLSCREEN_HASH,
});

export default TeaserXL;
