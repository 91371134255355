import { HEROSLIDER_HOME } from '../../HeroSlider/constants';

/**
 * ILE specific types for grid config
 */
export const TYPE_HEROSLIDER_HOME = HEROSLIDER_HOME;

export const TYPE_DIVIDER = 'grid-divider';

/**
 * ILE specific grid config layouts
 */
export const GRID_LAYOUT_HOME_TOP = 'homeTop';
export const GRID_LAYOUT_HOME_BOTTOM = 'homeBottom';
export const GRID_LAYOUT_MENU_OVERLAY = 'menuOverlay';
export const GRID_LAYOUT_LANDSCAPE_WITH_AD = 'landscapeWithAd';
export const GRID_LAYOUT_BLOGS = 'blogs';
export const GRID_LAYOUT_LANDSCAPE_SPECIAL = 'landscapeSpecial';
export const GRID_LAYOUT_TEASER_3X4 = 'teaser3x4';
