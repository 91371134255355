/* istanbul ignore file */

import classNames from 'classnames';
import advantagesParagraphFactory from '../../../../../../../common/components/Paragraphs/components/AdvantagesParagraph/factory';
import AdvantagesItem from '../../../AdvantagesItem';
import grid from '../../../../../../../common/assets/styles/grid.legacy.css';
import styles from './styles.legacy.css';

export const getItemWrapper = (count: number, index: number) =>
  classNames({
    [grid.ColXl8]: count === 4,
    [grid.ColSm12]: count === 4,
    [grid.ColOffsetXl4]: count === 4 && (index === 0 || index === 2),
    [grid.ColSm8]: count !== 4,
    [grid.ColOffsetSm4]: count === 5 && index === 3,
  });

const AdvantagesParagraph = advantagesParagraphFactory({
  AdvantagesItem,
  styles: {
    Title: classNames(styles.Title, grid.ColOffsetXl4, grid.ColXl16),
    Wrapper: grid.Row,
    OuterWrapper: grid.Container,
    ItemWrapper: getItemWrapper,
  },
});

export default AdvantagesParagraph;
