import React, { ComponentType } from 'react';
import classNames from 'classnames';
import Link from '../../../../../../../common/components/Link';
import Picture from '../../../../../../../common/components/Picture';
import ArrowButton from '../../../ArrowButton';
import { STYLE_BOOK_TEASER } from '../../../../../../../shared/constants/images';
import { ARROW_BUTTON_THEME_LIGHT } from '../../../ArrowButton/constants';
import styles from './styles.legacy.css';
import { TeaserFactoryProps } from '../../../../../../../common/components/Teaser/typings';

type TeaserSubscriptionMPropsInner = TeaserFactoryProps & TeaserInterface;

const TeaserSubscriptionM: ComponentType<TeaserFactoryProps> = ({
  shortTitle,
  teaserImage,
  title,
  link,
  trackingData,
  trackingSelector = '',
}: TeaserSubscriptionMPropsInner) => {
  if (!link || !link.path || !link.label || !title) {
    return null;
  }

  const teaserImgPath = teaserImage.image?.file?.relativeOriginPath || '';
  const teaserImgAlt = teaserImage.image?.file?.alt || '';

  return (
    <Link
      className={classNames(
        styles.TeaserWrapper,
        styles.WrapperStyle,
        'ArrowButtonHoverArea',
        {
          [trackingSelector]: !!trackingSelector,
        },
      )}
      trackingData={trackingData}
      path={link.path}
      target={'_blank'}
    >
      <>
        {shortTitle && (
          <div data-testid="teaser-shortTitle" className={styles.ShortTitle}>
            {shortTitle}
          </div>
        )}
        <div data-testid="teaser-title" className={styles.Title}>
          {title}
        </div>
        <div className={styles.BannerWrapper}>
          {teaserImgPath && (
            <Picture
              relativeOrigin={teaserImgPath}
              focalPointX={teaserImage?.image?.file?.focalPointX}
              focalPointY={teaserImage?.image?.file?.focalPointY}
              alt={teaserImgAlt}
              className={styles.Image}
              disableWrapperClassName
              style_320={STYLE_BOOK_TEASER}
            />
          )}

          <ArrowButton
            theme={ARROW_BUTTON_THEME_LIGHT}
            large
            addClass={styles.ArrowButtonStyle}
          >
            {link.label}
          </ArrowButton>
        </div>
      </>
    </Link>
  );
};

export default TeaserSubscriptionM;
