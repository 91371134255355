import { gql } from '@apollo/client';
import { imageGalleryTeaserFragment } from 'Teaser/fragments';

export const GET_SEARCH_PAGE = gql`
  query Search(
    $query: String!
    $limit: Int
    $offset: Int
    $sort: SearchOrderByField
    $contentTypes: [ContentTypeEnum]
  ) @api(name: cms) {
    globalSearch(
      search: $query
      limit: $limit
      offset: $offset
      sort: $sort
      content_types: $contentTypes
    ) {
      count
      ...SearchResultFragment
    }
  }

  fragment SearchResultFragment on SearchableUnionConnection {
    edges {
      node {
        ... on Article {
          id
          preferredUri
          title
          shortTitle
          badgeLabel
          badgeColor
          restrictionStatus
          hasVideo
          channel {
            id
            title
            channelType
            authors(limit: 1) {
              edges {
                node {
                  id
                  name
                  imageParagraph {
                    id
                    image {
                      id
                      file(style: "large") {
                        id
                        alt
                        relativeOriginPath
                        focalPointX
                        focalPointY
                      }
                    }
                  }
                }
              }
            }
          }
          authors(limit: 1) {
            edges {
              node {
                id
                name
                imageParagraph {
                  id
                  image {
                    id
                    file(style: "large") {
                      id
                      alt
                      relativeOriginPath
                      focalPointX
                      focalPointY
                    }
                  }
                }
              }
            }
          }
          teaserImage {
            id
            image {
              id
              file(style: "large") {
                id
                alt
                relativeOriginPath
                focalPointX
                focalPointY
              }
            }
          }
          useAutoHyphens
        }
        ... on NativeAdvertising {
          gcid
          preferredUri
          id
          title
          shortTitle
          hasVideo
          trackingTeaserClick
          trackingTeaserImpression
          subtypeValue: advertisingTypeValue
          advertisingTypeLabel
          lead
          channel {
            settings {
              hierarchy {
                count
                edges {
                  node {
                    id
                    title
                    tid
                  }
                }
              }
            }
          }
          link {
            path
            label
          }
          sponsor {
            id
            title
          }
          teaserImage {
            id
            image {
              id
              file(style: "large") {
                id
                alt
                relativeOriginPath
                focalPointX
                focalPointY
              }
            }
          }
          useAutoHyphens
        }
        ... on LandingPage {
          id
          title
          lead
          shortTitle
          preferredUri
          restrictionStatus
          teaserImage {
            id
            caption
            image {
              id
              file(style: "large") {
                id
                alt
                relativeOriginPath
                focalPointX
                focalPointY
              }
            }
          }
          channel {
            id
            sponsor {
              id
              title
              colorCode
              teaserImage {
                id
                caption
                image {
                  id
                  file(style: "large") {
                    id
                    alt
                    relativeOriginPath
                    focalPointX
                    focalPointY
                  }
                }
              }
            }
            channelType
            special {
              id
              title
              colorCode
              logo {
                source
              }
            }
            partners(limit: 3) {
              edges {
                node {
                  ... on Sponsor {
                    id
                    title
                  }
                }
              }
            }
          }
          useAutoHyphens
        }
        ...ImageGalleryTeaserFragment
        ... on Keyword {
          id
          label
          preferredUri
          settings {
            title
            headerImage {
              id
              file(style: "large") {
                id
                alt
                relativeOriginPath
                focalPointX
                focalPointY
              }
            }
          }
        }
        ... on Video {
          id
          title
          shortTitle
          preferredUri
          restrictionStatus
          teaserImage {
            id
            image {
              id
              file(style: "large") {
                id
                alt
                relativeOriginPath
                focalPointX
                focalPointY
              }
            }
          }
          useAutoHyphens
        }
      }
    }
  }
  ${imageGalleryTeaserFragment}
`;
