import {
  SVG_ICONS_DEFAULT_CONFIG,
  SVG_ICONS_TYPE_BOOKMARK_ACTIVE,
  SVG_ICONS_TYPE_BOOKMARK_INACTIVE,
  SVG_ICONS_TYPE_COMMENT,
  SVG_ICONS_TYPE_SHARE,
} from '../../../../../shared/constants/svgIcons';
// @ts-ignore
import { ReactComponent as StatusPageILE404 } from '../../../../../common/components/SVGIcon/assets/statusPage/si_404.svg';
// @ts-ignore
import { ReactComponent as StatusPageILE451 } from '../../../../../common/components/SVGIcon/assets/statusPage/si_451.svg';
// @ts-ignore
import { ReactComponent as StatusPageILE500 } from '../../../../../common/components/SVGIcon/assets/statusPage/si_500.svg';
// @ts-ignore
import { ReactComponent as BookmarkOutlinedActiveIcon } from './assets/bookmark_outlined_active.svg';
// @ts-ignore
import { ReactComponent as BookmarkOutlinedInactiveIcon } from './assets/bookmark_outlined_inactive.svg';
// @ts-ignore
import { ReactComponent as CommentCountIcon } from './assets/comment.svg';
// @ts-ignore
import { ReactComponent as CommentIcon } from './assets/comment_lines.svg';
// @ts-ignore
import { ReactComponent as Hamburger } from './assets/hamburger.svg';
// @ts-ignore
import { ReactComponent as IllustreLogo } from './assets/illustre_logo2019.svg';
// @ts-ignore
import { ReactComponent as MediaNewspaper } from './assets/media-newspaper.svg';
// @ts-ignore
import { ReactComponent as ShareIcon } from './assets/share.svg';
// @ts-ignore
import { ReactComponent as SubscriptionBadgeLarge } from './assets/subscriptionButtons/l.svg';
// @ts-ignore
import { ReactComponent as SubscriptionBadgeMedium } from './assets/subscriptionButtons/m.svg';
// @ts-ignore
import { ReactComponent as SubscriptionBadgeSmall } from './assets/subscriptionButtons/s.svg';
// @ts-ignore
import { ReactComponent as SubscriptionBadgeXLarge } from './assets/subscriptionButtons/xl.svg';
// @ts-ignore
import { ReactComponent as SubscriptionBadgeXSmall } from './assets/subscriptionButtons/xs.svg';
// @ts-ignore
import { ReactComponent as UserIcon } from './assets/user.svg';
// @ts-ignore
import { ReactComponent as UserActiveIcon } from './assets/user_active.svg';

export const SVG_ICONS_TYPE_COMMENT_COUNT = 'svg-icons/type/comment-count';
export const SVG_ICONS_TYPE_USER = 'svg-icons/type/user';
export const SVG_ICONS_TYPE_USER_ACTIVE = 'svg-icons/type/user-active';

/* name the type not 'svg-icons' to geet the original svg */
export const SVG_TYPE_ILLUSTRE_LOGO = 'svg-logo/type/illustre-logo';
export const SVG_TYPE_ILLUSTRE_LOGO_WHITE = 'svg-logo/type/illustre-logo';
export const SVG_ICONS_TYPE_HAMBURGER = 'svg-icons/type/hamburger';
export const SVG_ICONS_TYPE_MEDIA_NEWSPAPER = 'svg-icons/type/media-newspaper';
export const SVG_BADGE_TYPE_SUBSCRIPTION = 'svg-icons/type/subscription';
export const SVG_ICONS_TYPE_ILE_404 = 'svg-icons/type/si-404';
export const SVG_ICONS_TYPE_ILE_451 = 'svg-icons/type/si-451';
export const SVG_ICONS_TYPE_ILE_500 = 'svg-icons/type/si-500';

export const SVG_ICONS_CONFIG = {
  ...SVG_ICONS_DEFAULT_CONFIG,
  [`${SVG_BADGE_TYPE_SUBSCRIPTION}_xs`]: SubscriptionBadgeXSmall,
  [`${SVG_BADGE_TYPE_SUBSCRIPTION}_sm`]: SubscriptionBadgeSmall,
  [`${SVG_BADGE_TYPE_SUBSCRIPTION}_md`]: SubscriptionBadgeMedium,
  [`${SVG_BADGE_TYPE_SUBSCRIPTION}_lg`]: SubscriptionBadgeLarge,
  [`${SVG_BADGE_TYPE_SUBSCRIPTION}_xl`]: SubscriptionBadgeXLarge,
  [SVG_ICONS_TYPE_USER]: UserIcon,
  [SVG_ICONS_TYPE_USER_ACTIVE]: UserActiveIcon,
  [SVG_ICONS_TYPE_SHARE]: ShareIcon,
  [SVG_ICONS_TYPE_COMMENT]: CommentIcon,
  [SVG_ICONS_TYPE_COMMENT_COUNT]: CommentCountIcon,
  [SVG_ICONS_TYPE_BOOKMARK_ACTIVE]: BookmarkOutlinedActiveIcon,
  [SVG_ICONS_TYPE_BOOKMARK_INACTIVE]: BookmarkOutlinedInactiveIcon,
  [SVG_TYPE_ILLUSTRE_LOGO]: IllustreLogo,
  [SVG_TYPE_ILLUSTRE_LOGO_WHITE]: IllustreLogo,
  [SVG_ICONS_TYPE_HAMBURGER]: Hamburger,
  [SVG_ICONS_TYPE_MEDIA_NEWSPAPER]: MediaNewspaper,
  [SVG_ICONS_TYPE_ILE_404]: StatusPageILE404,
  [SVG_ICONS_TYPE_ILE_451]: StatusPageILE451,
  [SVG_ICONS_TYPE_ILE_500]: StatusPageILE500,
};
