import React, { ReactElement } from 'react';
import createComponentSwitch from '../../../../../../../shared/decorators/componentSwitch';
import withImpressionTrackingObserver from '../../../../../../../shared/decorators/withImpressionTrackingObserver';
import MinistageAccordion from './components/MinistageAccordion';
import MinistageSingleAlertTopic from './components/MinistageSingleAlertTopic';
import MinistageTrendingTopics from './components/MinistageTrendingTopics';
import MinistageVideo from './components/MinistageVideo';
import {
  MINISTAGE_COMPONENT_ACCORDION,
  MINISTAGE_COMPONENT_VIDEO,
  MINISTAGE_SINGLE_ALERT_TOPIC,
  MINISTAGE_TRENDING_TOPICS,
} from '../../../../../../../shared/constants/paragraphs';
import { MinistageParagraphProps } from './typings';

const Switch = createComponentSwitch({
  [MINISTAGE_COMPONENT_VIDEO]: MinistageVideo,
  [MINISTAGE_TRENDING_TOPICS]: MinistageTrendingTopics,
  [MINISTAGE_SINGLE_ALERT_TOPIC]: MinistageSingleAlertTopic,
  [MINISTAGE_COMPONENT_ACCORDION]: MinistageAccordion,
});

const Ministage = ({ ...props }: MinistageParagraphProps): ReactElement => (
  <Switch
    component={props?.ministageParagraph?.ministage?.__typename}
    {...props}
  />
);

export default withImpressionTrackingObserver({
  getTrackingId: (props: MinistageParagraphProps) =>
    props?.ministageParagraph?.ministage?.headline ||
    props?.ministageParagraph?.ministage?.__typename,
  isTrackingActivated: (props: MinistageParagraphProps) =>
    props?.ministageParagraph?.ministage?.__typename,
})(Ministage);
