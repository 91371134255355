import React, { ReactElement } from 'react';
import { compose } from 'recompose';
import withHelmet from '../../../../shared/decorators/withHelmet';
import TestFragment from '../../../../../shared/tests/components/TestFragment';
import Breadcrumbs from '../../components/Breadcrumbs';
import Helmet from '../../components/Helmet';
import OverviewPage from '../../components/OverviewPage';
import StatusPage from './../StatusPage';
import { ROOT_SCHEMA_TYPE_WEB_PAGE } from '../../../../../shared/constants/structuredData';
import { OVERVIEW_PAGE_SIZE } from '../../components/TeaserGrid/constants';
import { SITE_TITLE } from '../../constants';
import { KeywordProps } from './typings';

type KeywordPropsInner = KeywordProps & Pick<RouterProps, 'location'>;

const KeywordComponent = ({
  keyword,
  location,
  page,
}: KeywordPropsInner): ReactElement => {
  if (
    !keyword?.entities?.edges ||
    !Array.isArray(keyword?.entities?.edges) ||
    !keyword?.entities?.edges.length
  ) {
    return <StatusPage />;
  }

  const socialMetaValues = {
    field_lead: keyword?.settings?.lead || '',
    field_short_description: keyword?.settings?.lead || '',
    field_short_title: keyword?.settings?.title || '',
    field_heroimage: '',
  };

  return (
    <TestFragment data-testid="keyword-container">
      <Helmet socialMetaValues={socialMetaValues} />

      {keyword.preferredUri && keyword.activeMenuTrail && (
        <Breadcrumbs
          pageUrl={keyword.preferredUri}
          items={keyword.activeMenuTrail}
        />
      )}

      <OverviewPage
        location={location}
        page={page}
        routeObject={keyword}
        paragraphType="KeywordScreen"
      />
    </TestFragment>
  );
};

export default compose(
  withHelmet({
    getNode: (mapProps: KeywordPropsInner) => ({
      ...mapProps.keyword,
      metaDescription: mapProps.keyword?.settings?.lead,
    }),
    getImage: (mapProps: KeywordPropsInner) =>
      mapProps.keyword?.settings?.headerImage?.file,
    getFallbackTitle: (mapProps: KeywordPropsInner): string =>
      `${
        mapProps?.keyword?.settings?.title || mapProps?.keyword?.label || ''
      } - ${SITE_TITLE}`,
    getNodesCount: (mapProps): number =>
      mapProps.routeObject?.entities?.count || 0,
    pageSize: (mapProps): number => mapProps.pageSize || OVERVIEW_PAGE_SIZE,
    rootSchemaType: ROOT_SCHEMA_TYPE_WEB_PAGE,
    getNodes: (mapProps) => mapProps.keyword?.entities?.edges || [],
  }),
)(KeywordComponent);
