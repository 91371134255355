import React from 'react';
import fileUploaderFactory from '../../../../../common/components/FileUploader/factory';
import ButtonWithLoading from '../../components/ButtonWithLoading';
import SVGIcon from '../SVGIcon';
import PreparedFiles from './components/PreparedFiles';
import UploadedFiles from './components/UploadedFiles';
import styles from './styles.legacy.css';
import { ButtonWithLoadingType } from '../../../../../common/components/ButtonWithLoading/typings';
import { ErrorCodesMap } from '../../../../../common/components/FileUploader/typings';

export const getErrorMessagesByCodes = ({
  name = '',
  maxFileSize = 0,
  maxFileCount = 1,
  allowedExtensions = '',
}): ErrorCodesMap => ({
  'file-too-large': `Le fichier "${name}" dépasse la taille maximale de ${maxFileSize} MB.`,
  'max-amount-of-files-achieved': 'Le nombre maximal de fichiers est atteint.',
  'too-many-files': `Il n'est pas possible de télécharger plus de ${maxFileCount} fichiers.`,
  'file-invalid-type': `Le format du fichier "${name}" n'est pas autorisé. Veuillez utiliser l'un des formats suivants ${allowedExtensions}.`,
  'duplicated-file': `Le fichier "${name}" a déjà été téléchargé.`,
  required: `Au moins un fichier doit être téléchargé.`,
});

const Button: ButtonWithLoadingType = ({ clickHandler, text }) => {
  return (
    <ButtonWithLoading
      size="big"
      aria-label="upload"
      variant="tertiary"
      onClick={clickHandler}
    >
      {text}
    </ButtonWithLoading>
  );
};

const FileUploader = fileUploaderFactory({
  SVGIcon,
  Button,
  PreparedFiles,
  UploadedFiles,
  getErrorMessagesByCodes,
  titleText: 'Pour télécharger des fichiers, faites-les glisser ici',
  subtitleText: 'ou',
  buttonText: 'Sélectionner les fichiers',
  styles: {
    Wrapper: styles.Wrapper,
    WrapperReject: styles.WrapperReject,
    WrapperActive: styles.WrapperActive,
    Title: styles.Title,
    Subtitle: styles.Subtitle,
    FullSpaceIconWrapper: styles.FullSpaceIconWrapper,
    UploadIconWrapper: styles.UploadIconWrapper,
    Info: styles.Info,
    FilesListWrapper: styles.FilesListWrapper,
    FilesListWrapperError: styles.FilesListWrapperError,
    ErrorMessage: styles.ErrorMessage,
    UploadButtonWrapper: styles.UploadButtonWrapper,
    FileExtensions: styles.FileExtensions,
    WrapperAccept: styles.WrapperAccept,
  },
});

export default FileUploader;
