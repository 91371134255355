import {
  ROUTE_ALERTS,
  ROUTE_BOOKMARKS,
  ROUTE_SUBSCRIPTIONS,
} from '../../../App/constants';

export const AUTHORIZATION_ERROR_MESSAGE =
  'Votre session a expiré. Veuillez vous reconnecter.  ';
export const AUTHORIZATION_ERROR_ID = 'toast/authorization-error';
export const AUTHORIZATION_LINK_TEXT = 'Se connecter';
export const AUTHORIZATION_INFO_MESSAGE =
  'Veuillez vous connecter pour ajouter des thèmes à suivre: ';
export const AUTHORIZATION_INFO_ID = 'toast/authorization-info';

export const RESTRICTED_ERROR_MESSAGE =
  "Cette fonction n'est disponible que pour les abonnés.. ";
export const RESTRICTED_ERROR_LINK_TEXT = 'Voir mon abonnement';
export const RESTRICTED_ERROR_LINK_PATH = ROUTE_SUBSCRIPTIONS;
export const RESTRICTED_ERROR_ID = 'toast/restricted-info';

export const LIMIT_EXCEEDED_ERROR_MESSAGE =
  'Vous avez atteint la limite de 100 thèmes. Veuillez en supprimer avant de vous abonner à de nouveaux thèmes. ';
export const LIMIT_EXCEEDED_ERROR_LINK_TEXT = 'Vers les alertes e-mail';
export const LIMIT_EXCEEDED_ERROR_LINK_PATH = ROUTE_ALERTS;
export const LIMIT_EXCEEDED_ERROR_ID = 'toast/limit-exceeded-error';

export const BOOKMARKS_ADD_SUCCESS_MESSAGE = 'Article ajouté à vos favoris. ';
export const BOOKMARKS_ADD_SUCCESS_ID = 'toast/bookmarks-add-success';
export const BOOKMARKS_REMOVE_SUCCESS_MESSAGE =
  'Article retiré de vos favoris. ';
export const BOOKMARKS_REMOVE_SUCCESS_ID = 'toast/bookmarks-remove-success';
export const BOOKMARKS_PATH = ROUTE_BOOKMARKS;
export const BOOKMARKS_AUTHORIZATION_INFO_MESSAGE =
  "Veuillez vous connecter pour ajouter l'article à vos favoris. ";
export const BOOKMARKS_LIMIT_EXCEEDED_ERROR_MESSAGE =
  "Vous avez atteint la limite de 100 éléments enregistrés sur votre liste des favoris. Veuillez en supprimer avant d'en ajouter de nouveaux. ";
export const BOOKMARKS_LIMIT_EXCEEDED_ERROR_LINK_TEXT = 'Vers mes favoris';
export const BOOKMARKS_LIMIT_EXCEEDED_ERROR_ID =
  'toast/bookmarks-limit-exceeded-error';
export const BOOKMARKS_LINK_TEXT = 'Vers mes favoris';

export const DEFAULT_ERROR_MESSAGE =
  'Une erreur s’est produite. Veuillez réessayer plus tard.';
