import React, { ReactElement } from 'react';
import MoreButton from '../../../MoreButton';
import { MORE_BUTTON_COLLAPSED } from '../../../MoreButton/constants';
import styles from './styles.legacy.css';
import { LazyLoadingPagerProps, LazyLoadingProps } from './typings';

type LazyLoadingPropsInner = LazyLoadingProps & {
  pager: LazyLoadingPagerProps;
};

const LazyLoading: Function = ({
  isLoading,
  loadMore,
  hasMoreResults,
  children,
}: LazyLoadingPropsInner): ReactElement | null => {
  if (!hasMoreResults) {
    return null;
  }

  return (
    <div className={styles.Wrapper} data-testid="LazyLoading-wrapper">
      <MoreButton
        onClick={!isLoading ? loadMore : null}
        isLoading={isLoading && !__TESTING__}
        type={MORE_BUTTON_COLLAPSED}
      >
        {(children && isLoading) || isLoading ? (
          <span data-testid="Lazy-Loading-loading-wrapper">
            Téléchargement en cours ...
          </span>
        ) : (
          children || (
            <span data-testid="Lazy-Loading-load-more-wrapper">
              Afficher plus
            </span>
          )
        )}
      </MoreButton>
    </div>
  );
};

export default LazyLoading;
