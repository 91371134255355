import React from 'react';
import classNames from 'classnames';
import teaserFactory from '../../../../../../../common/components/Teaser/factory';
import { withTeaserTrackingHandler } from '../../../../../../../common/components/Teaser/helpers';
import { enrichBadgeProps, isIconVisible } from '../../shared/helpers';
import Badge from '../../../Badge';
import Icon from '../../../Icon';
import {
  IMAGE_GALLERY_CONTENT_TYPE,
  VIDEO_CONTENT_TYPE,
} from '../../../../../../../shared/constants/content';
import {
  FULLSCREEN_HASH,
  FULLSCREEN_HASH_TEASER_CLICK,
} from '../../../../../../../shared/constants/fullscreen';
import {
  STYLE_16X9_1180,
  STYLE_16X9_340,
  STYLE_16X9_360,
  STYLE_16X9_560,
  STYLE_16X9_700,
  STYLE_16X9_800,
} from '../../../../../../../shared/constants/images';
import styles from './styles.legacy.css';
import {
  GetElementByProps,
  TeaserFactoryProps,
} from '../../../../../../../common/components/Teaser/typings';

type TeaserHeroXlPropsInner = TeaserFactoryProps;

const getBadgeByProps: GetElementByProps<TeaserHeroXlPropsInner> = ({
  badgeLabel,
  badgeColor,
  __typename,
  subtypeValue,
}) => {
  if (!badgeLabel) {
    return null;
  }
  return (
    <div className={styles.Badge}>
      <Badge
        {...enrichBadgeProps(badgeColor, badgeLabel, __typename, subtypeValue)}
      />
    </div>
  );
};

const getIconByProps: GetElementByProps<TeaserHeroXlPropsInner> = ({
  hasVideo,
  __typename,
}) => {
  if (!isIconVisible(hasVideo, __typename)) {
    return null;
  }

  return (
    <Icon
      type={classNames({
        IconCamera: hasVideo,
        IconFotoMarker: __typename === IMAGE_GALLERY_CONTENT_TYPE,
        IconMovieOutline: __typename === VIDEO_CONTENT_TYPE,
      })}
      addClass={classNames({
        [styles.IconStyle]: __typename !== VIDEO_CONTENT_TYPE,
        [styles.IconMovieOutlineStyle]: __typename === VIDEO_CONTENT_TYPE,
      })}
    />
  );
};

const getIconPositionByProps = ({ __typename }: TeaserHeroXlPropsInner) =>
  __typename === VIDEO_CONTENT_TYPE;

const TeaserHeroXl = teaserFactory({
  badge: getBadgeByProps,
  icon: getIconByProps,
  isIconPositionOnImage: getIconPositionByProps,
  trackingTeaserHandler: withTeaserTrackingHandler,
  styles: {
    Wrapper: styles.Wrapper,
    Title: styles.Title,
    TitleInner: styles.TitleInner,
    ContentWrapper: styles.ContentWrapper,
    ShortTitle: styles.ShortTitle,
    IconStyle: styles.IconStyle,
  },
  fullScreenHashTeaserClick: FULLSCREEN_HASH_TEASER_CLICK,
  teaserImageStyles: {
    style_320: STYLE_16X9_340,
    style_480: STYLE_16X9_360,
    style_540: STYLE_16X9_560,
    style_760: STYLE_16X9_700,
    style_960: STYLE_16X9_800,
    style_1680: STYLE_16X9_1180,
  },
  fullScreenHash: FULLSCREEN_HASH,
});

export default TeaserHeroXl;
