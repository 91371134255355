/* istanbul ignore file */

import React, { ReactElement } from 'react';
import commentSetUsernameFormFactory from '../../../../../../../common/components/Comments/components/CommentSetUsernameForm/factory';
import ArrowButton from '../../../ArrowButton';
import styles from './styles.legacy.css';
import { ButtonWithLoadingType } from '../../../../../../../common/components/ButtonWithLoading/typings';

const Button: ButtonWithLoadingType = ({
  clickHandler,
  text,
}): ReactElement => (
  <button
    className={styles.Button}
    onClick={clickHandler}
    data-testid="loginform-button"
    role="link"
  >
    <ArrowButton large>{text}</ArrowButton>
  </button>
);

export default commentSetUsernameFormFactory({
  Button,
  commentUsernameMessage:
    'Um kommentieren zu können, hinterlege bitte einen Benutzernamen.',
  styles: {
    Button: styles.Button,
    ButtonWrapper: styles.ButtonWrapper,
    Message: styles.Message,
  },
});
