import React, { ReactElement } from 'react';
import classNames from 'classnames';
import TestFragment from '../../../../../../../shared/tests/components/TestFragment';
import CollapsableBox from './components/CollapsableBox';
import TwoColumns from './components/TwoColumns';
import {
  INFO_BOX_STYLE_DEFAULT,
  INFO_BOX_STYLE_TWO_COLUMNS,
} from '../../../../../../../common/components/Paragraphs/components/InfoBoxParagraph/constants';
import grid from '../../../../../../../common/assets/styles/grid.legacy.css';
import { InfoBoxParagraphProps } from '../../../../../../../common/components/Paragraphs/components/InfoBoxParagraph/typings';

const InfoBoxParagraph = ({
  infoBoxParagraph,
  origin,
}: InfoBoxParagraphProps): ReactElement => {
  if (
    !Array.isArray(infoBoxParagraph?.infoBox?.body) ||
    infoBoxParagraph.infoBox.body.length < 1
  ) {
    return null;
  }

  switch (infoBoxParagraph.infoBox.style) {
    case INFO_BOX_STYLE_TWO_COLUMNS:
      return (
        <TestFragment data-testid="infobox-two-columns-container">
          <TwoColumns infoBoxParagraph={infoBoxParagraph} origin={origin} />
        </TestFragment>
      );
    case INFO_BOX_STYLE_DEFAULT:
    default:
      return (
        <TestFragment data-testid="infobox-default-container">
          <CollapsableBox
            infoBoxParagraph={infoBoxParagraph}
            origin={origin}
            articleColStyle={classNames(grid.Col22, grid.ColOffset1)}
          />
        </TestFragment>
      );
  }
};

export default InfoBoxParagraph;
