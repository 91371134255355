/* istanbul ignore file */
import React from 'react';
import { compose } from 'redux';
import { useQuery } from '@apollo/client';
import authorFactory from '../../../../../common/screens/Author/factory';
import { ensureTeaserInterfaces } from '../../components/Teaser/shared/helpers';
import withPagePager from '../../../../../shared/decorators/withPagePager';
import withHelmet from '../../../../shared/decorators/withHelmet';
import Breadcrumbs from '../../components/Breadcrumbs';
import StatusPage from '../StatusPage';
import AuthorDetails from './components/AuthorDetails';
import ExpansionPanel from './components/ExpansionPanel';
import Pager, {
  PAGER_TYPE_PAGE_LOADER as pagerType,
} from '../../components/Pager';
import { TeaserLatest } from '../../components/Teaser/components/TeaserLatest';
import {
  ARTICLE_CONTENT_TYPE,
  NATIVE_ADVERTISING_CONTENT_TYPE,
} from '../../../../../shared/constants/content';
import { ROOT_SCHEMA_TYPE_PERSON } from '../../../../../shared/constants/structuredData';
import { DEFAULT_PUBLICATION, ROUTE_AUTHORS } from '../../constants';
import {
  AUTHOR_PAGE_GRID_PAGE_SIZE,
  AUTHOR_PAGE_SORT_ORDER,
  AUTHOR_PAGE_SORT_TYPE,
} from './constants';
import { GET_CONTENT_BY_AUTHOR } from './queries';
import styles from './styles.legacy.css';

const withAuthorContent =
  (Component) =>
  ({ author, page, ...props }) => {
    const { data } = useQuery(GET_CONTENT_BY_AUTHOR, {
      variables: {
        limit: AUTHOR_PAGE_GRID_PAGE_SIZE,
        offset: (page - 1) * AUTHOR_PAGE_GRID_PAGE_SIZE,
        contentTypes: [ARTICLE_CONTENT_TYPE, NATIVE_ADVERTISING_CONTENT_TYPE],
        authorId: author?.aid ? parseInt(author.aid, 10) : null,
        publication: DEFAULT_PUBLICATION,
        sortOrder: AUTHOR_PAGE_SORT_ORDER,
        sort: AUTHOR_PAGE_SORT_TYPE,
      },
      skip: !author?.aid,
    });

    return (
      <Component
        author={author}
        page={page}
        contentByAuthor={data?.globalSearch}
        {...props}
      />
    );
  };

const AuthorPage = authorFactory({
  PAGE_SIZE: AUTHOR_PAGE_GRID_PAGE_SIZE,
  ensureTeaserInterface: ensureTeaserInterfaces,
  Teaser: TeaserLatest,
  StatusPage,
  Breadcrumbs,
  ROUTE_AUTHORS,
  Pager: (props) => <Pager {...props} />,
  pagerType,
  AuthorDetails,
  ExpansionPanel,
  latestNewsTitle: 'Derniers contenus',
  overviewPageTitle: 'Nos Redacteurs',
  additionalBreadcrumbText: " - Membre de l'équipe éditoriale Illustre",
  inJournalismSinceTitle: 'Journaliste depuis:',
  atPublisherSinceTitle: 'Travaille pour Illustre depuis:',
  descriptionTitle: 'Biographie',
  associationsTitle: 'Associations',
  awardsTitle: 'Récompenses',
  bookLinksTitle: 'Livres',
  podcastLinksTitle: 'Podcasts',
  newsletterLinksTitle: 'Newsletters',
  yearsLabel: {
    singular: 'an',
    plural: 'ans',
  },
  styles: {
    HeaderWrapper: styles.HeaderWrapper,
    BreadcrumbsWrapper: styles.BreadcrumbsWrapper,
    Description: styles.Description,
    ResultsWrapper: styles.ResultsWrapper,
    PagerWrapper: styles.PagerWrapper,
    PageHeading: styles.PageHeading,
    InnerWrapper: styles.InnerWrapper,
  },
});

export default compose(
  withAuthorContent,
  withPagePager,
  withHelmet({
    getNode: (mapProps) => mapProps.author || null,
    getFallbackTitle: ({ author }) => `${author.name} | Illustré`,
    pageSize: AUTHOR_PAGE_GRID_PAGE_SIZE,
    rootSchemaType: ROOT_SCHEMA_TYPE_PERSON,
    getNodesCount: (mapProps) => mapProps?.contentByAuthor?.count || 0,
    getNodes: (mapProps) => mapProps?.contentByAuthor?.edges || [],
  }),
)(AuthorPage);
