import React from 'react';
import classNames from 'classnames';
import teaserFactory from '../../../../../../../common/components/Teaser/factory';
import { isIconVisible } from '../../shared/helpers';
import Picture from '../../../../../../../common/components/Picture';
import Icon from '../../../Icon';
import {
  IMAGE_GALLERY_CONTENT_TYPE,
  VIDEO_CONTENT_TYPE,
} from '../../../../../../../shared/constants/content';
import {
  FULLSCREEN_HASH,
  FULLSCREEN_HASH_TEASER_CLICK,
} from '../../../../../../../shared/constants/fullscreen';
import {
  STYLE_1X1_140,
  STYLE_3X2_440,
  STYLE_3X2_770,
} from '../../../../../../../shared/constants/images';
import styles from './styles.legacy.css';
import {
  GetElementByProps,
  TeaserFactoryProps,
} from '../../../../../../../common/components/Teaser/typings';

type TeaserBlogPostMaxiPropsInner = TeaserFactoryProps;

export const getChildrenByProps: GetElementByProps<
  TeaserBlogPostMaxiPropsInner
> = (props) => {
  const { authors, channel } = props;

  const author =
    authors?.edges?.[0]?.node || channel?.authors?.edges?.[0]?.node || null;

  if (!author) {
    return null;
  }

  const imagePath =
    author.imageParagraph?.image?.file?.relativeOriginPath || '';
  const imageAlt = author.imageParagraph?.image?.file?.alt || '';

  return (
    <div className={styles.AuthorBox}>
      <div className={styles.AuthorName}>par {author.name}</div>
      {imagePath && (
        <Picture
          relativeOrigin={imagePath}
          focalPointX={author.imageParagraph?.image?.file?.focalPointX}
          focalPointY={author.imageParagraph?.image?.file?.focalPointY}
          alt={imageAlt}
          className={styles.AuthorImage}
          style_320={STYLE_1X1_140}
        />
      )}
    </div>
  );
};

export const getIconByProps: GetElementByProps<
  TeaserBlogPostMaxiPropsInner
> = ({ hasVideo, __typename }) => {
  if (!isIconVisible(hasVideo, __typename)) {
    return null;
  }

  return (
    <Icon
      type={classNames({
        IconCamera: hasVideo,
        IconFotoMarker: __typename === IMAGE_GALLERY_CONTENT_TYPE,
        IconMovieOutline: __typename === VIDEO_CONTENT_TYPE,
      })}
      addClass={classNames({
        [styles.IconStyle]: __typename !== VIDEO_CONTENT_TYPE,
        [styles.IconMovieOutlineStyle]: __typename === VIDEO_CONTENT_TYPE,
      })}
    />
  );
};

const TeaserBlogPostMaxi = teaserFactory({
  icon: getIconByProps,
  isIconPositionOnImage: true,
  children: getChildrenByProps,
  styles: {
    ContentWrapper: styles.ContentWrapper,
    Image: styles.Image,
    ImageWrapper: styles.ImageWrapper,
    ShortTitle: styles.ShortTitle,
    Title: styles.Title,
    TitleInner: styles.TitleInner,
    Wrapper: styles.Wrapper,
    IconStyle: styles.IconStyle,
  },
  teaserImageStyles: {
    style_320: STYLE_3X2_770,
    style_760: STYLE_3X2_440,
    style_1680: STYLE_3X2_770,
  },
  fullScreenHashTeaserClick: FULLSCREEN_HASH_TEASER_CLICK,
  fullScreenHash: FULLSCREEN_HASH,
});

export default TeaserBlogPostMaxi;
