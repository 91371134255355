import compose from 'recompose/compose';
import bookmarksProfileFactory from '../../../../../common/screens/Account/components/BookmarksProfile/factory';
import parseTrackingData from '../../../../../shared/helpers/parseTrackingData';
import withAppNexus from '../../../../shared/decorators/withAppNexus';
import { setLoading, setScreenReady } from '../../../../shared/actions/route';
import BookmarkList from '../../components/BookmarkList';
import Helmet from '../../components/Helmet';
import LoadingSpinner from '../../components/LoadingSpinner';
import LoginForm from './components/LoginForm';
import NoBookmarks from './components/NoBookmarks';
import styles from './styles.legacy.css';
// @ts-ignore
import grid from '@grid.legacy.css';

const BookmarksProfile = bookmarksProfileFactory({
  Helmet,
  styles: {
    BookmarksProfileWrapper: styles.BookmarksProfileWrapper,
    LoginWrapper: styles.LoginWrapper,
    Title: styles.Title,
  },
  titleText: 'Mes favoris',
  loginText: "Veuillez vous connecter pour ajouter l'article à vos favoris.",
  grid,
  LoginForm,
  NoBookmarks,
  LoadingSpinner,
  BookmarkList,
  setLoading,
  setScreenReady,
});

export default compose(
  withAppNexus({
    parseTrackingData: (props) =>
      parseTrackingData({
        ...props,
        articleType: 'LandingPage',
      }),
  }),
)(BookmarksProfile);
