import classNames from 'classnames';
import videoParagraphFactory from '../../../../../../../common/components/Paragraphs/components/VideoParagraph/factory';
import VideoPlayer from '../../../VideoPlayer';
import ImageCaption from '../ImageCaption';
import {
  ARTICLE_DEFAULT,
  ARTICLE_VIDEO,
  ARTICLE_VIDEO_HERO,
} from '../../../../screens/Article/constants';
import { PAGE_SCREEN_DEFAULT } from '../../../../screens/PageScreen/constants';
import { VIDEO_PAGE } from '../../../../screens/Video/constants';
import grid from '../../../../../../../common/assets/styles/grid.legacy.css';
import helpers from '../../../../assets/styles/helpers.legacy.css';
import styles from './styles.legacy.css';
import { BrightcoveProps } from '../../../../../../../common/components/Brightcove/typings';
import {
  VideoParagraphProps,
  VideoParagraphFactoryOptionsStyles,
} from '../../../../../../../common/components/Paragraphs/components/VideoParagraph/typings';
import { ActiveMainChannel } from '../../../../../../shared/types';

type VideoParagraphPropsInner = VideoParagraphProps &
  BrightcoveProps & {
    activeMainChannel: ActiveMainChannel;
  };

const getStylesByProps = (
  props: VideoParagraphPropsInner,
): VideoParagraphFactoryOptionsStyles => {
  const { origin, video } = props;
  return {
    OuterWrapper: classNames({
      [styles.OuterWrapper]: origin !== VIDEO_PAGE,
      [styles.CaptionPlaceholder]: !video?.credit && !video?.caption,
      [styles.HeroVideoMargin]: origin === ARTICLE_VIDEO_HERO,
    }),
    Wrapper: classNames(styles.Wrapper, {
      [helpers.PullOutXs]:
        origin === ARTICLE_VIDEO_HERO || origin === VIDEO_PAGE,
    }),
    TitleWrapper: classNames(
      grid.ColOffsetXs2,
      grid.ColXs20,
      grid.ColOffsetSm4,
      grid.ColSm16,
      grid.ColOffsetXl5,
      grid.ColXl14,
      helpers.TextCenter,
      styles.TitleWrapper,
    ),
    VideoTitle: styles.Quote,
    ShortTitle: styles.HeadingCatch3,
  };
};

const checkIfHasTitleByProps = ({
  origin,
  video,
}: VideoParagraphPropsInner) => {
  return (
    video?.title &&
    video?.shortTitle &&
    (origin === ARTICLE_DEFAULT ||
      origin === ARTICLE_VIDEO ||
      origin === PAGE_SCREEN_DEFAULT)
  );
};

const checkIfHasShortTitleByProps = ({ video }: VideoParagraphPropsInner) => {
  return !!video?.shortTitle;
};

const checkOriginByProps = ({ origin }: VideoParagraphPropsInner) => {
  return origin === VIDEO_PAGE;
};

const VideoParagraph = videoParagraphFactory({
  styles: getStylesByProps,
  Video: VideoPlayer,
  ImageCaption,
  hasTitle: checkIfHasTitleByProps,
  hasShortTitle: checkIfHasShortTitleByProps,
  shouldAutoPlay: checkOriginByProps,
  muted: checkOriginByProps,
  shouldHideCaption: false,
});

export default VideoParagraph;
