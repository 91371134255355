import React, { ReactElement } from 'react';
import { connect } from 'react-redux';
import locationStateSelector from '../../../../shared/selectors/locationStateSelector';
import LoadingBar from './components/LoadingBar';
import { LoadingBarProps } from './typings';

type LoadingBarPropsInner = LoadingBarProps & {
  screenReady?: boolean;
  isRefetchingData?: boolean;
};

const Loading = ({
  isRefetchingData,
  screenReady,
}: LoadingBarPropsInner): ReactElement | null => {
  return !screenReady || isRefetchingData ? <LoadingBar /> : null;
};

export const mapStateToProps = (
  state: Record<string, any>,
): Record<string, any> => ({
  isRefetchingData: locationStateSelector(state).isRefetchingData,
  screenReady: locationStateSelector(state).screenReady,
});

export default connect(mapStateToProps)(Loading);
