/* istanbul ignore file */

import classNames from 'classnames';
import alertListFactory from '../../../../../common/components/AlertList/factory';
import AlertItem from '../AlertItem';
import SubscribeButton from '../SubscribeButton';
import ExpansionPanel from './components/ExpansionPanel';
import grid from '../../../../../common/assets/styles/grid.legacy.css';

const AlertListFactory = alertListFactory({
  styles: {
    AlertListWrapper: '',
    AlertListInner: grid.Row,
    AlertListItem: classNames(grid.ColSm12, grid.ColMd8),
  },
  AlertItem,
  SubscribeButton,
  ExpansionPanel,
});

export default AlertListFactory;
