import { connect } from 'react-redux';
import classNames from 'classnames';
import headerFactory from '../../../../../common/components/Header/factory';
import windowStateSelector from '../../../../../shared/selectors/windowStateSelector';
import {
  VIEWPORT_LG,
  VIEWPORT_MD,
  VIEWPORT_SM,
  VIEWPORT_XL,
  VIEWPORT_XS,
  VIEWPORT_XXL,
} from '../../../../../shared/actions/window';
import HeaderInner from '../Header/components/HeaderInner';
import { HEADER_PLACEHOLDER_ID } from '../Header/constants';
import variables from '../../assets/styles/variables.legacy.css';
import styles from './styles.legacy.css';
import { HeaderProps } from '../../../../../common/components/Header/typings';

type HeaderPropsInner = HeaderProps & {
  viewportLabel: ViewportLabel;
};

let configIsVisible: InViewConfig;

let configIsCollapsed: InViewConfig;

const HEADER_DIMENSIONS_BY_VIEWPORT = {
  [VIEWPORT_XS]: {
    height: parseInt(variables.headerHeightXs, 10),
    margin: parseInt(variables.headerMarginXs, 10) - 5,
  },
  [VIEWPORT_SM]: {
    height: parseInt(variables.headerHeightSm, 10),
    margin: parseInt(variables.headerMarginLg, 10),
  },
  [VIEWPORT_MD]: {
    height: parseInt(variables.headerHeightSm, 10),
    margin: parseInt(variables.headerMarginLg, 10),
  },
  [VIEWPORT_LG]: {
    height: parseInt(variables.headerHeightSm, 10),
    margin: parseInt(variables.headerMarginLg, 10),
  },
  [VIEWPORT_XL]: {
    height: parseInt(variables.headerHeightXl, 10),
    margin: parseInt(variables.headerMarginXl, 10) - 1,
  },
  [VIEWPORT_XXL]: {
    height: parseInt(variables.headerHeightXl, 10),
    margin: parseInt(variables.headerMarginXl, 10) - 1,
  },
};

const getHeaderDimension = (viewport: ViewportLabel, prop = 'height'): number =>
  HEADER_DIMENSIONS_BY_VIEWPORT[viewport][prop];

const getObserverConfigsByProps = ({
  viewportLabel,
}: HeaderPropsInner): Array<InViewConfig> => {
  const headerDimensionMargin = getHeaderDimension(viewportLabel, 'margin');

  if (viewportLabel === VIEWPORT_XS) {
    configIsVisible = {
      rootMargin: `-${
        parseInt(variables.headerHeightXs, 10) - headerDimensionMargin * 2
      }px 0px 0px 0px`,
    };
    configIsCollapsed = {
      rootMargin: `-${
        parseInt(variables.headerHeightXs, 10) - headerDimensionMargin + 2
      }px 0px 0px 0px`,
    };
  } else if (
    viewportLabel === VIEWPORT_SM ||
    viewportLabel === VIEWPORT_MD ||
    viewportLabel === VIEWPORT_LG
  ) {
    configIsVisible = {
      rootMargin: `-${
        parseInt(variables.headerHeightSm, 10) - headerDimensionMargin
      }px 0px 0px 0px`,
    };
    configIsCollapsed = {
      rootMargin: `-${
        parseInt(variables.headerHeightSm, 10) - headerDimensionMargin
      }px 0px 0px 0px`,
    };
  } else {
    configIsVisible = {
      rootMargin: `-${
        parseInt(variables.headerHeightXl, 10) - headerDimensionMargin
      }px 0px 0px 0px`,
    };
    configIsCollapsed = {
      rootMargin: `-${
        parseInt(variables.headerHeightXl, 10) - headerDimensionMargin
      }px 0px 0px 0px`,
    };
  }

  // could not import from variables.legacy.css like variables.headerHeightXl for tests
  if (__TESTING__) {
    configIsVisible = {
      rootMargin: `-${60 - 12 * 2 - 1}px 0px 0px 0px`,
    };
    configIsCollapsed = {
      rootMargin: `-${60 - 12 + 2}px 0px 0px 0px`,
    };
  }

  return [configIsVisible, configIsCollapsed];
};

const getStyleByProps = ({ isMarketingPageReducedHeader }) => ({
  Wrapper: styles.Wrapper,
  Placeholder: classNames(styles.Placeholder, {
    [styles.MarketingPage]: isMarketingPageReducedHeader,
  }),
  IsSticky: styles.IsSticky,
  Header: '',
});

const Header = headerFactory({
  HeaderInner,
  placeholderId: HEADER_PLACEHOLDER_ID,
  observerConfigs: getObserverConfigsByProps,
  reInitObserverOnViewportLabelChange: [true, true],
  styles: getStyleByProps,
});

const mapStateToProps = (state) => ({
  viewportLabel: windowStateSelector(state).viewport.label,
});

export default connect(mapStateToProps)(Header);
