/**
 * @file   landing page graphql fragment
 * @author Alexandra Geier <alexandra.geier@ringieraxelspringer.ch>
 * @date   2019-03-01 15:16:46
 *
 */

import { gql } from '@apollo/client';
import { paragraphsFragment } from 'Paragraphs/fragments';
import { imageGalleryTeaserFragment } from 'Teaser/fragments';

export const landingPageFragment = gql`
  fragment LandingPageFragment on LandingPage {
    id
    nid
    gcid
    paragraphsSubsetSize
    title
    metaTitle
    metaDescription
    metaOgTitle
    metaOgDescription
    metaCanonicalUrl
    editContentUri
    editRelationUri
    cloneContentUri
    lead
    subtypeValue
    preferredUri
    activeMenuTrail {
      edges {
        node {
          id
          label
          link
        }
      }
    }
    channel {
      id
      channelType
      suppressAds
      settings {
        mainChannel {
          id
          title
        }
        hierarchy {
          count
          edges {
            node {
              id
              title
              tid
            }
          }
        }
      }
      sponsors {
        edges {
          node {
            ... on Sponsor {
              id
              title
              teaserImage {
                id
                caption
                link {
                  path
                }
                image {
                  id
                  file(style: "large") {
                    id
                    alt
                    relativeOriginPath
                    focalPointX
                    focalPointY
                  }
                }
              }
              backgroundImage {
                file(style: "large") {
                  id
                  alt
                  relativeOriginPath
                  focalPointX
                  focalPointY
                }
              }
            }
          }
        }
      }
    }
    teaserImage {
      id
      image {
        id
        file(style: "16x9_1130") {
          id
          relativeOriginPath
          focalPointX
          focalPointY
        }
      }
    }
    grid(limit: $landingPageGridSize, offset: $landingPageGridOffset) {
      count
      edges {
        node {
          ... on LandingPage {
            id
            title
            lead
            shortTitle
            preferredUri
            teaserImage {
              id
              caption
              image {
                id
                file(style: "large") {
                  id
                  alt
                  relativeOriginPath
                  focalPointX
                  focalPointY
                }
              }
            }
            channel {
              id
              sponsor {
                id
                title
                colorCode
                teaserImage {
                  id
                  caption
                  image {
                    id
                    file(style: "large") {
                      id
                      alt
                      relativeOriginPath
                      focalPointX
                      focalPointY
                    }
                  }
                }
              }
              channelType
              special {
                id
                title
                colorCode
                logo {
                  source
                }
              }
              partners(limit: 3) {
                edges {
                  node {
                    ... on Sponsor {
                      id
                      title
                    }
                  }
                }
              }
            }
            useAutoHyphens
          }
          ... on Article {
            id
            title
            lead
            changeDate
            publicationDate
            preferredUri
            publication
            shortTitle
            subtypeValue: articleType
            hasVideo
            restrictionStatus
            channel {
              id
              title
            }
            teaserImage {
              id
              image {
                id
                file(style: "large") {
                  id
                  alt
                  relativeOriginPath
                  focalPointX
                  focalPointY
                }
              }
            }
            useAutoHyphens
          }
          ... on NativeAdvertising {
            id
            gcid
            title
            lead
            subtypeValue: advertisingTypeValue
            advertisingTypeLabel
            shortTitle
            trackingTeaserImpression
            trackingTeaserClick
            publicationDate
            changeDate
            preferredUri
            publication
            channel {
              id
              title
              settings {
                hierarchy {
                  count
                  edges {
                    node {
                      id
                      title
                      tid
                    }
                  }
                }
              }
            }
            link {
              path
              label
            }
            sponsor {
              id
              title
              teaserImage {
                id
                title
                image {
                  id
                  file(style: "scaleh_120", calculateDimensions: true) {
                    id
                    alt
                    width
                    height
                    relativeOriginPath
                    focalPointX
                    focalPointY
                  }
                }
              }
            }
            teaserImage {
              id
              title
              image {
                id
                file(style: "large") {
                  id
                  alt
                  relativeOriginPath
                  focalPointX
                  focalPointY
                }
              }
            }
            useAutoHyphens
          }
          ... on Video {
            id
            preferredUri
            publication
            title
            brightcoveId
            publicationDate
            changeDate
            caption
            credit
            lead
            channel {
              id
              title
            }
            teaserImage {
              id
              image {
                id
                file(style: "large") {
                  id
                  alt
                  relativeOriginPath
                  focalPointX
                  focalPointY
                }
              }
            }
            useAutoHyphens
          }
          ...ImageGalleryTeaserFragment
          ... on Teaser {
            link {
              path
            }
            trackingEnabled
            trackingTeaserImpression
            trackingTeaserClick
            title
            shortTitle
            lead
            teaserImage {
              id
              caption
              image {
                id
                file(style: "large") {
                  id
                  relativeOriginPath
                  focalPointX
                  focalPointY
                }
              }
            }
            useAutoHyphens
          }
        }
      }
    }
    body {
      ...ParagraphsFragment
    }
  }
  ${imageGalleryTeaserFragment}
  ${paragraphsFragment}
`;
