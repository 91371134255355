import React, { ComponentType } from 'react';
import { useMutation } from '@apollo/client';
import webformFactory from '../../../../../../../common/components/Paragraphs/components/WebformParagraph/factory';
import Link from '../../../../../../../common/components/Link';
import ArrowButton from '../../../ArrowButton';
import Error from '../../../Error';
import Icon from '../../../Icon';
import LoadingSpinner from '../../../LoadingSpinner';
import LoginForm from '../../../LoginForm';
import BirthdayField from '../../../Paragraphs/components/WebformParagraph/components/BirthdayField';
import InputField from '../../../Paragraphs/components/WebformParagraph/components/InputField';
import MultiField from '../../../Paragraphs/components/WebformParagraph/components/MultiField';
import SelectField from '../../../Paragraphs/components/WebformParagraph/components/SelectField';
import SubmitButton from '../../../Paragraphs/components/WebformParagraph/components/SubmitButton';
import AddressFieldsWrapper from './components/AddressFieldsWrapper';
import FileField from './components/FileField';
import { Auth0 } from '../../../../../../../common/components/Auth0Provider';
import { SAVE_WEBFORM } from './mutations';
import { GOOGLE_RECAPTCHA_KEY } from '../../../../constants';
import styles from './styles.legacy.css';
import { WebformProps } from '../../../../../../../common/components/Paragraphs/components/WebformParagraph/typings';

const getIsUserLoggedIn: Function = (): boolean => {
  return Auth0.isAuthenticated();
};

const WebformComponent = webformFactory({
  SubmitButton,
  ErrorMessage: Error,
  Icon,
  InputField,
  BirthdayField,
  MultiField,
  SelectField,
  FileField,
  AddressFieldsWrapper,
  IconTypes: {
    errorIconType: 'IconWarning',
    successIconType: 'IconCircleCheckSolid',
  },
  errorCallToAction: (
    <ArrowButton large>
      <Link
        className={styles.ArrowButton}
        path="/contact"
        label="Vers le formulaire de contact"
      />
    </ArrowButton>
  ),
  defaultSuccessTitle: 'Avec nos sincères remerciements',
  defaultErrorMessage:
    'Pour des raisons techniques, le formulaire n’a pas pu être envoyé. Veuillez s’il vous plaît réessayer plus tard ou nous contacter.',
  defaultSuccessMessage: 'Le formulaire a été envoyé avec succès.',
  restrictionFormLoginMessage:
    'Veuillez s’il vous plaît vous inscrire pour remplir ce formulaire.',
  restrictionFormSetUsernameMessage:
    'Pour remplir ce formulaire, veuillez s’il vous plaît entrer votre nom d’utilisateur.',
  appCaptchaMessage: 'Veuillez s’il vous plaît d’abord confirmer le Captcha.',
  appErrorPanelHeaderMesssage: 'Veuillez s’il vous plaît réessayer',
  RestrictionForm: LoginForm,
  reCaptchaKey: GOOGLE_RECAPTCHA_KEY,
  LoadingSpinner,
  getIsUserLoggedIn,
  withErrorIcon: true,
  styles: {
    SubTitle: '',
    ToggleFormAppear: '',
    ToggleFormAppearActive: '',
    ToggleFormLeave: '',
    ToggleFormLeaveActive: '',
    Description: styles.Description,
    Required: '',
    ButtonWrapper: styles.ButtonWrapper,
    Loading: '',
    ToggleErrorAppear: '',
    ToggleErrorAppearActive: '',
    ToggleErrorLeave: '',
    ToggleErrorLeaveActive: '',
    ErrorIcon: styles.ErrorIcon,
    ErrorPanelWrapper: styles.ErrorPanelWrapper,
    ErrorPanelHeader: styles.ErrorPanelHeader,
    ErrorPanelContent: styles.ErrorPanelContent,
    SuccessIcon: styles.SuccessIcon,
    SuccessWrapper: styles.SuccessWrapper,
    SuccessContent: styles.SuccessContent,
    SuccessTitle: styles.SuccessTitle,
    SuccessMessage: styles.SuccessMessage,
    RestrictionFormWrapper: styles.RestrictionFormWrapper,
    ClosedContainer: styles.ClosedContainer,
    ClosedMessage: styles.ClosedMessage,
    ClosedIcon: styles.ClosedIcon,
    RichTextWrapper: styles.RichTextWrapper,
  },
});

const WebformParagraph: ComponentType<Omit<WebformProps, 'mutate'>> = (
  props,
) => {
  const [saveWebform] = useMutation(SAVE_WEBFORM);

  return <WebformComponent {...props} mutate={saveWebform} />;
};

export default WebformParagraph;
