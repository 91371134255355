import React, { ReactElement } from 'react';
import classNames from 'classnames';
import Picture from '../../../../../common/components/Picture';
import { STYLE_TEASER_1_1 } from '../../../../../shared/constants/images';
import grid from '../../../../../common/assets/styles/grid.legacy.css';
import helpers from '../../assets/styles/helpers.legacy.css';
import styles from './styles.legacy.css';
import { AuthorBoxProps } from './typings';

export type AuthorBoxPropsInner = AuthorBoxProps;

const AuthorBox = ({ author }: AuthorBoxPropsInner): ReactElement | null => {
  if (!author || !author.name) {
    return null;
  }

  const imagePath =
    author?.imageParagraph?.image?.file?.relativeOriginPath || '';
  const focalPointX = author?.imageParagraph?.image?.file?.focalPointX || null;
  const focalPointY = author?.imageParagraph?.image?.file?.focalPointY || null;
  const imageAlt = author?.imageParagraph?.image?.file?.alt || '';
  return (
    <div data-testid="article-author-box-wrapper" className={styles.Wrapper}>
      {imagePath && (
        <div
          data-testid="article-author-box-img-wrapper"
          className={classNames(
            helpers.TextLineHeightZero,
            grid.ColXsAuto,
            grid.ColSm4,
            grid.ColXl5,
          )}
        >
          <Picture
            relativeOrigin={imagePath}
            focalPointX={focalPointX}
            focalPointY={focalPointY}
            alt={imageAlt}
            className={styles.AuthorImg}
            style_320={STYLE_TEASER_1_1}
          />
        </div>
      )}
      <div
        className={classNames(grid.ColSm20, grid.ColXl19, styles.FlexOne, {
          [grid.ColOffsetSm4]: !author.imageParagraph,
          [grid.ColOffsetXl5]: !author.imageParagraph,
        })}
      >
        <div
          data-testid="article-author-box-name"
          className={classNames(styles.AuthorText, styles.AuthorName)}
        >
          <span>{author.name}</span>{' '}
        </div>
        {author.description && (
          <div
            data-testid="article-author-box-description"
            className={classNames(styles.AuthorText, styles.AuthorDescription)}
          >
            <span dangerouslySetInnerHTML={{ __html: author.description }} />
          </div>
        )}
      </div>
    </div>
  );
};

export default AuthorBox;
