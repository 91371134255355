/**
 * getEntityQueueLimitByPath function
 *
 * @author  Steven Wolf <steven.wolf@ringieraxelspringer.ch>
 * @date    2019-09-20
 */

import { ROUTE_HOME } from '../../../App/constants';

export default (pathname: string) => {
  switch (pathname) {
    case ROUTE_HOME:
      return 13;
    default:
      return -1;
  }
};
