/* istanbul ignore file */

import classNames from 'classnames';
import listicleItemParagraphFactory from '../../../../../../../common/components/Paragraphs/components/ListicleItemParagraph/factory';
import grid from '../../../../../../../common/assets/styles/grid.legacy.css';
import styles from './styles.legacy.css';
import {
  ListicleItemParagraphFactoryOptionsStyles,
  ListicleItemParagraphProps,
} from '../../../../../../../common/components/Paragraphs/components/ListicleItemParagraph/typings';

const getStylesByProps = ({
  listicleIndex,
}: ListicleItemParagraphProps): ListicleItemParagraphFactoryOptionsStyles => {
  const isEven = listicleIndex % 2 === 0;
  return {
    ContentBox: classNames(grid.ColXs24, grid.ColSm12, {
      [grid.ColOffsetSm1]: isEven,
    }),
    ImageBox: classNames(grid.ColXs24, grid.ColSm11, styles.ImageBox, {
      [grid.ColOffsetSm1]: !isEven,
    }),
    InnerWrapper: classNames(grid.ColOffsetXl4, grid.ColXs24, grid.ColXl16),
    Wrapper: classNames(styles.Wrapper, grid.Container),
    Title: styles.Title,
    Content: styles.Content,
    Footer: styles.Footer,
    Even: styles.Even,
    Odd: styles.Odd,
    ListicleItemWrapper: styles.ListicleItemWrapper,
    ListicleItemInnerWrapper: styles.ListicleItemInnerWrapper,
    Image: styles.Image,
  };
};

const ListicleItemParagraph = listicleItemParagraphFactory({
  styles: getStylesByProps,
});

export default ListicleItemParagraph;
