import classNames from 'classnames';
import teaserTextFactory from '../../../../../common/components/Teaser/components/TeaserText/factory';
import Link from '../../../../../common/components/LinkLegacy';
import { ARTICLE_HEADER_THEME_VIDEO_DETAIL } from './constants';
import styles from './styles.legacy.css';
import {
  TeaserTextComponent,
  TeaserTextFactoryOptionsStyles,
  TeaserTextFactoryOptionsStylesByProps,
} from '../../../../../common/components/Teaser/components/TeaserText/typings';
import { TeaserTextProps } from './typings';

type TeaserTextPropsInner = TeaserTextProps;

const getStylesByProps: TeaserTextFactoryOptionsStylesByProps<
  TeaserTextPropsInner
> = (props: TeaserTextPropsInner): TeaserTextFactoryOptionsStyles => {
  if (props.theme === ARTICLE_HEADER_THEME_VIDEO_DETAIL) {
    return {
      Wrapper: classNames(styles.Wrapper, styles.WrapperSmall),
      ShortTitle: classNames(styles.ShortTitle, styles.ShortTitleFontSmall),
      Title: classNames(styles.Title, styles.TitleFontSmall),
      Lead: classNames(styles.Lead, styles.LeadFontSmall),
    };
  }

  return {
    Wrapper: styles.Wrapper,
    ShortTitle: classNames(styles.ShortTitle, styles.ShortTitleFont),
    Title: classNames(styles.Title, styles.TitleFont),
    Lead: classNames(styles.Lead, styles.Lead),
  };
};

const TeaserText: TeaserTextComponent = teaserTextFactory({
  Link,
  styles: getStylesByProps,
});

export default TeaserText;
