import React from 'react';
import classNames from 'classnames';
import teaserFactory from '../../../../../../../common/components/Teaser/factory';
import { withTeaserTrackingHandler } from '../../../../../../../common/components/Teaser/helpers';
import {
  getBadgeByPropsFunction,
  getRenderArrow,
  getRenderBadge,
  isIconVisible,
} from '../../shared/helpers';
import Icon from '../../../Icon';
import {
  IMAGE_GALLERY_CONTENT_TYPE,
  VIDEO_CONTENT_TYPE,
} from '../../../../../../../shared/constants/content';
import {
  FULLSCREEN_HASH,
  FULLSCREEN_HASH_TEASER_CLICK,
} from '../../../../../../../shared/constants/fullscreen';
import {
  STYLE_2X3_210,
  STYLE_2X3_305,
  STYLE_2X3_960,
} from '../../../../../../../shared/constants/images';
import styles from './styles.legacy.css';
import {
  GetElementByProps,
  TeaserFactoryProps,
} from '../../../../../../../common/components/Teaser/typings';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const markStyleAsUsed = [
  styles.Badge,
  styles.BadgePositionBottom,
  styles.ArrowButtonAddClass,
];

type TeaserPortraitPropsInner = TeaserFactoryProps;

const renderBadge = getRenderBadge(styles);
const renderArrow = getRenderArrow(styles);
const getBadgeByProps = getBadgeByPropsFunction(renderBadge, renderArrow);

const getIconByProps: GetElementByProps<TeaserPortraitPropsInner> = ({
  hasVideo,
  __typename,
}) => {
  if (!isIconVisible(hasVideo, __typename)) {
    return null;
  }

  return (
    <Icon
      type={classNames({
        IconCamera: hasVideo,
        IconFotoMarker: __typename === IMAGE_GALLERY_CONTENT_TYPE,
        IconMovieOutline: __typename === VIDEO_CONTENT_TYPE,
      })}
      addClass={classNames({
        [styles.IconStyle]: __typename !== VIDEO_CONTENT_TYPE,
        [styles.IconMovieOutlineStyle]: __typename === VIDEO_CONTENT_TYPE,
      })}
    />
  );
};

const teaserImageStyles = {
  style_320: STYLE_2X3_210,
  style_480: STYLE_2X3_305,
  style_540: STYLE_2X3_960,
  style_760: STYLE_2X3_210,
  style_1680: STYLE_2X3_305,
};

const TeaserPortrait = teaserFactory({
  badge: getBadgeByProps,
  icon: getIconByProps,
  isIconPositionOnImage: true,
  styles: {
    Wrapper: styles.Wrapper,
    Title: styles.Title,
    ImageWrapper: styles.ImageWrapper,
    Image: styles.Image,
    ContentWrapper: styles.ContentWrapper,
    ShortTitle: styles.ShortTitle,
  },
  trackingTeaserHandler: withTeaserTrackingHandler,
  teaserImageStyles,
  fullScreenHashTeaserClick: FULLSCREEN_HASH_TEASER_CLICK,
  fullScreenHash: FULLSCREEN_HASH,
});

export default TeaserPortrait;
