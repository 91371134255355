/* istanbul ignore file */

import { RaschApolloConfig } from '../../../../../shared/decorators/withRaschRouterFactory';
import {
  CHANNELS_VOCABULARY,
  CHANNEL_TYPE_PODCASTS,
  DEFAULT_PUBLICATION,
  OVERVIEW_VISIBLE_TYPE_PODCASTS,
  ROUTE_PODCASTS,
} from '../../constants';
import { PAGE_SIZE } from './constants';
import { GET_PODCAST_QUERY } from './queries';

export const apolloConfig: RaschApolloConfig = {
  options: ({ location }) => {
    const page = location.query?.page || 1;

    return {
      query: GET_PODCAST_QUERY,
      variables: {
        publication: DEFAULT_PUBLICATION,
        path: ROUTE_PODCASTS,
        vid: CHANNELS_VOCABULARY,
        channelType: [CHANNEL_TYPE_PODCASTS],
        overviewPageVisibility: [OVERVIEW_VISIBLE_TYPE_PODCASTS],
        overviewPageSize: page === 1 ? PAGE_SIZE + 1 : PAGE_SIZE,
        overviewPageOffset: (page - 1) * PAGE_SIZE,
      },
    };
  },
};
