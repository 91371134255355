import React, { ReactElement } from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';
import settingsStateSelector from '../../../../shared/selectors/settingsStateSelector';
import Badge from '../Badge';
import { NATIVE_ADVERTISING_CONTENT_TYPE } from '../../../../../shared/constants/content';
import { ADVERTORIAL } from '../../constants';
import styles from './styles.legacy.css';
import { HeadComponent, HeadProps } from './typings';

type HeadPropsInner = HeadProps & {
  activeContentType: string;
};

const Head: HeadComponent = ({
  title,
  shortTitle,
  lead,
  activeContentType,
}: HeadPropsInner): ReactElement => {
  if (!title && !shortTitle && !lead) {
    return null;
  }

  const isNativeAdvertising =
    activeContentType === NATIVE_ADVERTISING_CONTENT_TYPE;

  return (
    <div data-testid="head-wrapper" className={styles.Wrapper}>
      {shortTitle && (
        <div
          className={classNames({
            [styles.ShortTitleWrapper]: isNativeAdvertising,
          })}
        >
          {isNativeAdvertising && <Badge label={ADVERTORIAL} color="grayD" />}
          {!isNativeAdvertising && (
            <span
              data-testid="head-shorttitle-wrapper"
              className={styles.HeadingCatch3}
            >
              {shortTitle}
            </span>
          )}
        </div>
      )}
      {title && (
        <h1 data-testid="head-title-wrapper" className={styles.Heading1A}>
          <span>{title}</span>
        </h1>
      )}
      {lead && (
        <p data-testid="head-lead-wrapper" className={styles.Lead1}>
          {lead}
        </p>
      )}
    </div>
  );
};

const mapStateToProps = (state: Record<string, any>): Record<string, any> => ({
  activeContentType: settingsStateSelector(state).activeContentType,
});

export default connect(mapStateToProps)(Head);
