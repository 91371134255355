import React, { ReactElement } from 'react';
import { useNavigate } from 'react-router-dom';
import classNames from 'classnames';
import {
  FULLSCREEN_HASH,
  FULLSCREEN_HASH_IMAGE_CLICK,
} from '../../../../../shared/constants/fullscreen';
import styles from './styles.legacy.css';
import { ImgProps } from './typings';

const openFullscreenGallery = (event, navigate) => {
  event.preventDefault();
  const target = event.currentTarget;
  const hash = (target && target.getAttribute('data-href')) || '';
  global.location.hash = hash;
  navigate(global.location.pathname + global.location.search + hash, {
    replace: true,
  });
};

const FullscreenButton = ({ imageId, origin }: ImgProps): ReactElement => {
  const navigate = useNavigate();
  if (!imageId) {
    return null;
  }
  let trackingData: Array<TrackingData>;
  if (origin) {
    trackingData = [
      {
        type: 'fullscreen-button',
        value: origin,
      },
    ];
  }

  return (
    <span
      data-track-info={
        trackingData &&
        Array.isArray(trackingData) &&
        trackingData.length > 0 &&
        JSON.stringify(trackingData)
      }
      onClick={(event) => openFullscreenGallery(event, navigate)}
      onKeyDown={(event) => openFullscreenGallery(event, navigate)}
      data-testid="fullscreen-button-wrapper"
      title="Galerie öffnen"
      className={classNames('fullscreen-button', styles.Icon)}
      data-href={`#${FULLSCREEN_HASH_IMAGE_CLICK + FULLSCREEN_HASH}${imageId}`}
      role="button"
      tabIndex={0}
    />
  );
};

export default FullscreenButton;
