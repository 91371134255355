import React from 'react';
import { connect } from 'react-redux';
import navigationUserMenuFactory from '../../../../../../../common/components/NavigationUserMenu/factory';
import { setNavigationVisible } from '../../../../../../../shared/actions/navigation';
import Link from '../../../../../../../common/components/Link';
import Icon from '../../../Icon';
import SVGIcon from '../../../SVGIcon';
import MenuItem from './components/MenuItem';
import { ROUTE_ALERTS, ROUTE_BOOKMARKS } from '../../../../constants';
import { SVG_TYPE_ILLUSTRE_LOGO_WHITE } from '../../../SVGIcon/constants';
import grid from '../../../../../../../common/assets/styles/grid.legacy.css';
import styles from './styles.legacy.css';
import {
  MenuHeaderLogoProps,
  NavigationUserMenuProps,
  UserCockpitMenuItem,
} from '../../../../../../../common/components/NavigationUserMenu/typings';

type NavigationUserMenuPropsInner = NavigationUserMenuProps & {
  setNavigationVisible: (visibleNavigation: string) => void;
};

export const MenuHeaderLogo = ({ closeNavigation }: MenuHeaderLogoProps) => (
  <div
    className={styles.HeaderLogoContent}
    data-testid="navigation-user-menu-header-wrapper"
  >
    <div className={styles.SVGLogoWrapper}>
      <Link path="/" onClick={closeNavigation}>
        <SVGIcon
          type={SVG_TYPE_ILLUSTRE_LOGO_WHITE}
          className={styles.SVGLogo}
        />
      </Link>
    </div>
    <span className={styles.LogoPrefix}>Mon espace</span>
  </div>
);

const closeNavigationByProps =
  ({ setNavigationVisible }: NavigationUserMenuPropsInner) =>
  (): void => {
    setNavigationVisible('');
  };

const links: Array<UserCockpitMenuItem> = [
  {
    name: 'Mes favoris',
    link: `/${ROUTE_BOOKMARKS}`,
    iconType: 'IconBookmark',
    trackingClass: 'link-usercockpit-bookmarks',
  },
  {
    name: 'Alertes par e-mail',
    link: `/${ROUTE_ALERTS}`,
    iconType: 'IconBell',
    trackingClass: 'link-usercockpit-email-alerts',
  },
  {
    name: 'e-Paper',
    link: 'https://epaper.illustre.ch/',
    iconType: 'IconNewspaper',
    trackingClass: 'link-usercockpit-epaper',
  },
  {
    name: 'Abos et Services',
    link: __AUTH0_SERVICES_URI__,
    iconType: 'IconServices',
    trackingClass: 'link-usercockpit-account',
  },
  {
    name: 'Modification du profil',
    link: __AUTH0_PROFILE_URI__,
    iconType: 'IconGear',
    trackingClass: 'link-usercockpit-profile',
  },
];

const mapDispatchToProps: Record<string, any> = {
  setNavigationVisible,
};

const NavigationUserMenu = navigationUserMenuFactory({
  Icon,
  MenuItem,
  MenuHeaderLogo,
  links,
  closeNavigation: closeNavigationByProps,
  appWelcomeMessage: 'Bonjour',
  appLogoutMessage: 'Déconnexion',
  appCloseMenuMessage: 'Fermer le menu',
  styles: {
    Wrapper: styles.Wrapper,
    MenuHeader: styles.MenuHeader,
    MenuHeaderContent: styles.MenuHeaderContent,
    MenuBodyWrapper: styles.MenuBodyWrapper,
    UserInformationWrapper: styles.UserInformationWrapper,
    UserName: styles.UserName,
    UserCredentials: styles.UserCredentials,
    MenuWrapper: styles.MenuWrapper,
    MenuListWrapper: styles.MenuListWrapper,
    MenuItem: styles.MenuItem,
    Container: grid.Container,
    CloseButton: styles.CloseButton,
    CloseIcon: styles.CloseIcon,
  },
});

export default connect(null, mapDispatchToProps)(NavigationUserMenu);
