import {
  UTILITY_TYPE_BOOKMARKS,
  UTILITY_TYPE_EMAIL,
  UTILITY_TYPE_FACEBOOK,
  UTILITY_TYPE_GETPOCKET,
  UTILITY_TYPE_LINKEDIN,
  UTILITY_TYPE_MESSENGER,
  UTILITY_TYPE_SHARE,
  UTILITY_TYPE_TWITTER,
  UTILITY_TYPE_WHATSAPP,
} from '../../components/UtilityBar/constants';

export const UTILITYBAR_CONFIG_ARTICLE = [
  UTILITY_TYPE_SHARE,
  UTILITY_TYPE_BOOKMARKS,
  // UTILITY_TYPE_COMMENTS,
];

export const UTILITYBAR_CONFIG_NATIVE_ADVERTISING = [
  UTILITY_TYPE_SHARE,
  // UTILITY_TYPE_BOOKMARKS,
];

export const UTILITYBAR_OVERLAY_CONFIG_ARTICLE = [
  UTILITY_TYPE_EMAIL,
  UTILITY_TYPE_FACEBOOK,
  UTILITY_TYPE_MESSENGER,
  UTILITY_TYPE_WHATSAPP,
  UTILITY_TYPE_LINKEDIN,
  UTILITY_TYPE_TWITTER,
  UTILITY_TYPE_GETPOCKET,
];
