import React from 'react';
import TestFragment from '../../../../../../../shared/tests/components/TestFragment';
import DefaultImageParagraph from './components/DefaultImageParagraph';
import GalleryDetailImageParagraph from './components/GalleryDetailImageParagraph';
import {
  IMAGE_FORMAT_LANDSCAPE,
  IMAGE_FORMAT_PORTRAIT,
  IMAGE_FORMAT_SQUARE,
  STYLE_1X1_410,
  STYLE_1X1_495,
  STYLE_1X1_640,
  STYLE_1X1_PLACEHOLDER,
  STYLE_2X3_210,
  STYLE_2X3_305,
  STYLE_2X3_360,
  STYLE_2X3_960,
  STYLE_2X3_PLACEHOLDER,
  STYLE_3X2_440,
  STYLE_3X2_770,
  STYLE_3X2_PLACEHOLDER,
} from '../../../../../../../shared/constants/images';
import { IMAGE_GALLERY_DETAIL_SCREEN } from '../../../../screens/ImageGalleryArticle/constants';

// Drupal image style mappings for all image formats
export const FORMAT_STYLE_MAPPING = {
  [IMAGE_FORMAT_LANDSCAPE]: [
    STYLE_3X2_440,
    STYLE_3X2_440,
    STYLE_3X2_770,
    STYLE_3X2_770,
    STYLE_3X2_770,
    STYLE_3X2_770,
  ],
  [IMAGE_FORMAT_PORTRAIT]: [
    STYLE_2X3_360,
    STYLE_2X3_360,
    STYLE_2X3_960,
    STYLE_2X3_210,
    STYLE_2X3_305,
    STYLE_2X3_360,
  ],
  [IMAGE_FORMAT_SQUARE]: [
    STYLE_1X1_410,
    STYLE_1X1_495,
    STYLE_1X1_640,
    STYLE_1X1_410,
    STYLE_1X1_495,
    STYLE_1X1_495,
  ],
};

export const FORMAT_PLACEHOLDER_STYLE_MAPPING = {
  [IMAGE_FORMAT_LANDSCAPE]: [
    STYLE_3X2_PLACEHOLDER,
    STYLE_3X2_PLACEHOLDER,
    STYLE_3X2_PLACEHOLDER,
    STYLE_3X2_PLACEHOLDER,
    STYLE_3X2_PLACEHOLDER,
    STYLE_3X2_PLACEHOLDER,
  ],
  [IMAGE_FORMAT_PORTRAIT]: [
    STYLE_2X3_PLACEHOLDER,
    STYLE_2X3_PLACEHOLDER,
    STYLE_2X3_PLACEHOLDER,
    STYLE_2X3_PLACEHOLDER,
    STYLE_2X3_PLACEHOLDER,
    STYLE_2X3_PLACEHOLDER,
  ],
  [IMAGE_FORMAT_SQUARE]: [
    STYLE_1X1_PLACEHOLDER,
    STYLE_1X1_PLACEHOLDER,
    STYLE_1X1_PLACEHOLDER,
    STYLE_1X1_PLACEHOLDER,
    STYLE_1X1_PLACEHOLDER,
    STYLE_1X1_PLACEHOLDER,
  ],
};

const ImageParagraph = (props) => {
  if (!props.imageParagraph || !props.imageParagraph.image) {
    return null;
  }

  switch (props.origin) {
    case IMAGE_GALLERY_DETAIL_SCREEN:
      return (
        <TestFragment data-testid="imageparagraph-gallery-detail-image-paragraph-wrapper">
          <GalleryDetailImageParagraph {...props} />
        </TestFragment>
      );

    default:
      return (
        <TestFragment data-testid="imageparagraph-default-image-paragraph-wrapper">
          <DefaultImageParagraph {...props} />
        </TestFragment>
      );
  }
};

export default ImageParagraph;
