/* istanbul ignore file */

import React, { ReactElement } from 'react';
import classNames from 'classnames';
import twoColumnsParagraphFactory from '../../../../../../../../../common/components/Paragraphs/components/InfoBoxParagraph/components/TwoColumns/factory';
import ParagraphsRenderer from '../../../ParagraphsRenderer';
import { INFO_BOX_TYPE } from '../../../../../../../../../common/components/Paragraphs/components/InfoBoxParagraph/constants';
import grid from '../../../../../../../../../common/assets/styles/grid.legacy.css';
import styles from './styles.legacy.css';
import { LANDING_PAGE_TYPE } from '../../../../../../screens/LandingPage/constants';
import { InfoBoxParagraphProps } from '../../../../../../../../../common/components/Paragraphs/components/InfoBoxParagraph/typings';

const getAppParagraphsRenderer = (
  infoBoxBody: Maybe<Array<Maybe<ParagraphInterface>>>,
): ReactElement => {
  if (!Array.isArray(infoBoxBody) || infoBoxBody.length < 1) {
    return null;
  }

  return (
    <ParagraphsRenderer
      pageBody={infoBoxBody}
      origin={INFO_BOX_TYPE}
      hasContainer={false}
    />
  );
};

const getStylesByProps = ({ origin }: InfoBoxParagraphProps) => {
  return {
    Wrapper: classNames(styles.Wrapper),
    LeftWrapper: classNames(
      {
        [grid.ColXs20]: origin !== LANDING_PAGE_TYPE,
        [grid.ColOffsetXs2]: origin !== LANDING_PAGE_TYPE,
        [grid.ColSm16]: origin !== LANDING_PAGE_TYPE,
        [grid.ColOffsetSm4]: origin !== LANDING_PAGE_TYPE,
      },
      grid.ColOffsetMd0,
      grid.ColMd12,
    ),
    RightWrapper: classNames(
      {
        [grid.ColXs20]: origin !== LANDING_PAGE_TYPE,
        [grid.ColOffsetXs2]: origin !== LANDING_PAGE_TYPE,
        [grid.ColSm16]: origin !== LANDING_PAGE_TYPE,
        [grid.ColOffsetSm4]: origin !== LANDING_PAGE_TYPE,
      },
      grid.ColOffsetMd0,
      grid.ColMd12,
    ),
  };
};

const TwoColumns = twoColumnsParagraphFactory({
  paragraphsRenderer: getAppParagraphsRenderer,
  styles: getStylesByProps,
});

export default TwoColumns;
