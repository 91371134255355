import compose from 'recompose/compose';
import alertsProfileFactory from '../../../../../common/screens/Account/components/AlertsProfile/factory';
import parseTrackingData from '../../../../../shared/helpers/parseTrackingData';
import withApolloScreenHandler from '../../../../shared/decorators/withApolloScreenHandler';
import withAppNexus from '../../../../shared/decorators/withAppNexus';
import withWaitUntilApolloLoaded from '../../../../shared/decorators/withWaitUntilApolloLoaded';
import withPianoTrackingData from './../../../../../shared/decorators/withPianoTrackingData';
import AlertList from '../../components/AlertList';
import Helmet from '../../components/Helmet';
import LoadingSpinner from '../../components/LoadingSpinner';
import LoginForm from './components/LoginForm';
import NoItems from './components/NoItems';
import grid from '../../../../../common/assets/styles/grid.legacy.css';
import styles from './styles.legacy.css';

const AlertsProfile = alertsProfileFactory({
  styles: {
    AlertsProfileWrapper: styles.AlertsProfileWrapper,
    LoginWrapper: styles.LoginWrapper,
    AlertListWrapper: grid.Container,
    Title: styles.Title,
    Description: styles.Description,
    ItemsWrapper: grid.ColSm18,
  },
  titleText: 'Alertes par e-mail',
  descriptionText:
    "Suivez vos thèmes préférés. Nous vous tiendrons informé.e par e-mail de la publication d'un nouvel article sur vos thèmes préférés.",
  loginText: 'Veuillez vous connecter pour ajouter des thèmes à suivre',
  Helmet,
  grid,
  LoginForm,
  NoItems,
  LoadingSpinner,
  AlertList,
});

export default compose(
  withApolloScreenHandler,
  withWaitUntilApolloLoaded,
  withPianoTrackingData,
  withAppNexus({
    parseTrackingData: (props) =>
      parseTrackingData({
        ...props,
        articleType: 'LandingPage',
      }),
  }),
)(AlertsProfile);
