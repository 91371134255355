import React, { ReactElement } from 'react';
import TestFragment from '../../../../../shared/tests/components/TestFragment';
import ParagraphsRenderer from './components/ParagraphsRenderer';
import type { ParagraphsProps } from './typings';

export type ParagraphsPropsInner = ParagraphsProps;

const Paragraphs = ({ ...props }: ParagraphsPropsInner): ReactElement => {
  if (!props.pageBody || !props.pageBody.length || props.pageBody.length < 1) {
    return null;
  }

  return (
    <TestFragment data-testid="paragraphs-wrapper">
      <ParagraphsRenderer {...props} />
    </TestFragment>
  );
};

export default Paragraphs;
