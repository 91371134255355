/* istanbul ignore file */

import React, { ReactElement } from 'react';
import commentBodyFactory from '../../../../../../../common/components/Comments/components/CommentBody/factory';
import {
  TIME_ELAPSED_FORMAT_LONG,
  getFormattedElapsedDate,
} from '../../../../../../../shared/helpers/dateTimeElapsed';
import SVGIcon from '../../../SVGIcon';
import { COMMENT_REDAKTION_ILE } from '../../../../../../../shared/constants/comments';
import { SVG_TYPE_ILLUSTRE_LOGO } from '../../../SVGIcon/constants';
import styles from './styles.legacy.css';

const getAccountLogoByProps = (): ReactElement => (
  <div className={styles.AccountNameWrapper}>
    <div className={styles.LogoWrapper}>
      <SVGIcon type={SVG_TYPE_ILLUSTRE_LOGO} />
    </div>
    <span className={styles.AccountName}>Redaktion</span>
  </div>
);

export default commentBodyFactory({
  publicationAccountName: `${COMMENT_REDAKTION_ILE}`,
  logo: <SVGIcon type={SVG_TYPE_ILLUSTRE_LOGO} />,
  logoAlt: 'Illustré logo',
  accountLogo: getAccountLogoByProps,
  getFormattedElapsedDate,
  elapsedDateFormat: TIME_ELAPSED_FORMAT_LONG,
  styles: {
    Body: styles.Body,
    Date: styles.Date,
    Logo: '',
    Name: styles.Name,
    Text: styles.Text,
  },
});
