/**
 * prepare body
 *
 * @description splits the body by entityqueue items and merges x paragraphs between those entity queues
 *
 * @param {ParagraphInterface} body
 * @param {string} paragraphsSubsetSize
 */

import { ENTITY_QUEUE_PARAGRAPH } from '../../../../../shared/constants/paragraphs';
import {
  ENTITY_QUEUE_STYLE_SPLIT_BOTTOM,
  ENTITY_QUEUE_STYLE_SPLIT_TOP,
} from '../../components/Paragraphs/components/EntityQueueParagraph/constants';

const SPLIT_ENTITY_QUEUE_BY_ITEMS = 7;

export const prepareBody = (
  body: ParagraphInterface,
  paragraphsSubsetSize = '',
) => {
  if (
    !body ||
    !Array.isArray(body) ||
    body.length === 0 ||
    body[0].__typename !== ENTITY_QUEUE_PARAGRAPH
  ) {
    return body;
  }

  // do deep copy of items to get ride of mutations
  const bodyCopy = JSON.parse(JSON.stringify(body));

  // we need this overwrite here for the gridOptions
  bodyCopy[0].style = ENTITY_QUEUE_STYLE_SPLIT_TOP;
  const splitParagraphId = bodyCopy[0].id;
  bodyCopy[0].id = splitParagraphId + ENTITY_QUEUE_STYLE_SPLIT_TOP;
  // do deep copy of items to get ride of mutations
  const entityQueueCopy = JSON.parse(JSON.stringify(bodyCopy[0]));
  entityQueueCopy.style = ENTITY_QUEUE_STYLE_SPLIT_BOTTOM;
  entityQueueCopy.id = splitParagraphId + ENTITY_QUEUE_STYLE_SPLIT_BOTTOM;

  // split and remove array items by given number of items
  bodyCopy[0]?.entityQueue?.items &&
    Array.isArray(bodyCopy[0].entityQueue?.items.edges) &&
    bodyCopy[0].entityQueue.items.edges.splice(SPLIT_ENTITY_QUEUE_BY_ITEMS);

  entityQueueCopy?.entityQueue?.items &&
    Array.isArray(entityQueueCopy.entityQueue.items.edges) &&
    entityQueueCopy.entityQueue.items.edges.splice(
      0,
      SPLIT_ENTITY_QUEUE_BY_ITEMS,
    );

  // if we don't have to split the body we do it anyway because of no duplications of grid config
  // as we dont have to merge paragraphs into the body we have to push the split on last position
  if (body.length <= 2) {
    bodyCopy.push(entityQueueCopy);
  } else {
    bodyCopy.splice(parseInt(paragraphsSubsetSize) + 1, 0, entityQueueCopy);
  }

  return bodyCopy;
};
