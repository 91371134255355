/**
 * @file    SortOrder
 * @author  Damian Bucki <damian.bucki@dreamlab.pl>
 * @date    2018-09-20
 *
 */

import React from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';
import locationStateSelector from '../../../../../../shared/selectors/locationStateSelector';
import useRaschRouterLocation from '../../../../../../../shared/hooks/useRaschRouterLocation';
import Link from '../../../../../../../common/components/Link/index';
import {
  GLOBAL_SEARCH_SORT_BY_DATE,
  GLOBAL_SEARCH_SORT_BY_RELEVANCE,
} from '../../../../../../../shared/constants/globalSearch';
import styles from './styles.legacy.css';
import { SortItem } from './typings';

const sortItems: Array<SortItem> = [
  {
    name: 'pertinence',
    sort: GLOBAL_SEARCH_SORT_BY_RELEVANCE,
  },
  {
    name: 'actualité',
    sort: GLOBAL_SEARCH_SORT_BY_DATE,
  },
];

const getHref = (
  pathname: string,
  query: Record<string, string>,
  sort: string,
) => {
  if (!sort || !pathname || !query) {
    return undefined;
  }

  const searchQuery = {
    ...query,
    sort,
  };

  const search = Object.keys(searchQuery)
    .map((value) => `${value}=${searchQuery[value]}`)
    .join('&');

  return `${pathname}${(search && '?' + search) || ''}`;
};

const SortOrder = () => {
  const { pathname, query } = useRaschRouterLocation();
  const currentSortOrder = query?.sort || GLOBAL_SEARCH_SORT_BY_RELEVANCE;
  return (
    <div
      className={`sort-order ${styles.Wrapper}`}
      data-testid="sort-order-wrapper"
    >
      <div
        className={styles.FilterWrapper}
        data-testid="sort-order-filter-wrapper"
      >
        <span className={styles.Label}>Trier selon:</span>

        {sortItems.map((item, index) => (
          <Link
            path={
              currentSortOrder !== item.sort
                ? getHref(pathname, query, item.sort)
                : null
            }
            key={`sort-link-${item.name || index}`}
            className={classNames(styles.FilterItem, {
              [styles.ActiveFilterItem]: currentSortOrder === item.sort,
            })}
            label={item.name}
          />
        ))}
      </div>
    </div>
  );
};

const mapStateToProps = (state: ReduxState) => ({
  routePathname:
    locationStateSelector(state).locationBeforeTransitions.pathname,
  routeQuery: locationStateSelector(state).locationBeforeTransitions.query,
});

export default connect(mapStateToProps)(SortOrder);
