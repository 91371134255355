/* istanbul ignore file */

import { RaschApolloConfig } from '../../../../../shared/decorators/withRaschRouterFactory';
import {
  GLOBAL_SEARCH_FILTER_ARTICLE,
  GLOBAL_SEARCH_FILTER_IMAGE_GALLERY,
  GLOBAL_SEARCH_FILTER_KEYWORD_SETTINGS,
  GLOBAL_SEARCH_FILTER_LANDING_PAGE,
  GLOBAL_SEARCH_FILTER_NATIVE_ADVERTISING,
  GLOBAL_SEARCH_FILTER_VIDEO,
  GLOBAL_SEARCH_SORT_BY_RELEVANCE,
} from '../../../../../shared/constants/globalSearch';
import { ITEMS_PER_PAGE } from './constants';
import { GET_SEARCH_PAGE } from './queries';

export const apolloConfig: RaschApolloConfig = {
  options: ({ location, params }) => {
    const page = location.query?.page || 1;
    const sort = location.query?.sort || GLOBAL_SEARCH_SORT_BY_RELEVANCE;
    const { query = '' } = params;

    return {
      query: GET_SEARCH_PAGE,
      variables: {
        query: query && `${query}*`,
        limit: ITEMS_PER_PAGE,
        offset: Math.abs(page - 1) * ITEMS_PER_PAGE,
        sort,
        contentTypes: [
          GLOBAL_SEARCH_FILTER_ARTICLE,
          GLOBAL_SEARCH_FILTER_IMAGE_GALLERY,
          GLOBAL_SEARCH_FILTER_LANDING_PAGE,
          GLOBAL_SEARCH_FILTER_KEYWORD_SETTINGS,
          GLOBAL_SEARCH_FILTER_VIDEO,
          GLOBAL_SEARCH_FILTER_NATIVE_ADVERTISING,
        ],
      },
    };
  },
};
