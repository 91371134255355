import sliderProgressBarFactory from '../../../../../../../common/components/Slider/components/SliderProgressBar/factory';
import styles from './styles.legacy.css';

const SliderProgressBar = sliderProgressBarFactory({
  styles: {
    Wrapper: styles.Wrapper,
    ProgressBar: styles.ProgressBar,
  },
});

export default SliderProgressBar;
