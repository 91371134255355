import teaserStageParagraphFactory from '../../../../../../../../../common/components/Paragraphs/components/TeaserStageParagraph/factory';
import { ensureTeaserInterface } from '../../../../../Teaser/shared/helpers';
import TeaserGrid from '../../../../../TeaserGrid';
import { TEASER_STAGE_PARAGRAPH_STYLE_RANDOMIZED_LIST } from '../../../../../../../../../shared/constants/paragraphs';
import grid from '../../../../../../../../../common/assets/styles/grid.legacy.css';
import styles from './styles.legacy.css';
import { TeaserLayout } from '../../../../../TeaserGrid/gridConfigs';
import { TeaserStageParagraphProps } from '../../../../../../../../../common/components/Paragraphs/components/TeaserStageParagraph/typings';

export type DefaultTeaserStageParagraphPropsInner = TeaserStageParagraphProps;

export const getGridLayoutByProps = ({
  teaserStage,
}: TeaserStageParagraphProps): TeaserLayout => {
  const itemsCount = teaserStage?.entities?.edges?.length || 0;
  let layout: TeaserLayout = null;

  switch (itemsCount) {
    case 1:
      layout = 'teaserStage1Item';
      break;
    case 2:
      layout = 'teaserStage2Items';
      break;
    case 3:
      layout = 'teaserStage3Items';
      break;
    case 4:
      layout = 'teaserStageDefault';
      break;
    default:
      layout = 'teaserStageUnlimited';
      break;
  }

  if (teaserStage.style === TEASER_STAGE_PARAGRAPH_STYLE_RANDOMIZED_LIST) {
    layout = 'teaserStageRandomizedList';
  }

  return layout;
};

const DefaultTeaserStageParagraph = teaserStageParagraphFactory({
  ensureTeaserInterface,
  gridLayout: getGridLayoutByProps,
  TeaserGridRenderer: () => TeaserGrid,
  styles: {
    Container: grid.Container,
    SectionTitle: styles.SectionTitle,
    TitleLink: styles.TitleLink,
    SVGImage: styles.SVGImage,
    TitleWrapper: styles.TitleWrapper,
    SponsorWrapper: styles.SponsorWrapper,
    SponsorImage: styles.SponsorImage,
    SponsorPrefix: styles.SponsorPrefix,
    SponsorLink: styles.SponsorLink,
  },
});

export default DefaultTeaserStageParagraph;
