import React from 'react';
import compose from 'recompose/compose';
import { useQuery } from '@apollo/client';
import classNames from 'classnames';
import { enrichOverviewBodyWithADs } from '../../../../../../../shared/helpers/ads';
import { getAllArticles } from '../../../../../../../shared/helpers/withHelmet';
import withHelmet from '../../../../../../shared/decorators/withHelmet';
import TestFragment from '../../../../../../../shared/tests/components/TestFragment';
import Breadcrumbs from '../../../../components/Breadcrumbs';
import EditButtons from '../../../../components/EditButtons';
import Error from '../../../../components/Error';
import OverviewPage from '../../../../components/OverviewPage';
import Paragraphs from '../../../../components/Paragraphs';
import PartnerBanner from '../../../../components/PartnerBanner';
import Teaser from '../../../../components/Teaser';
import {
  ARTICLE_CONTENT_TYPE,
  IMAGE_GALLERY_CONTENT_TYPE,
  NATIVE_ADVERTISING_CONTENT_TYPE,
} from '../../../../../../../shared/constants/content';
import { ROOT_SCHEMA_TYPE_WEB_PAGE } from '../../../../../../../shared/constants/structuredData';
import { TEASER_LAYOUT_SPECIAL_HERO } from '../../../../components/../../../../shared/constants/teaser';
import { SPECIALS_PAGE_SIZE } from '../../../../components/TeaserGrid/constants';
import { CHANNEL_TYPE_SPECIAL } from '../../../Channel/constants';
import {
  LANDING_PAGE_DEFAULT_GRID_PAGE_SIZE,
  LANDING_PAGE_TYPE,
} from '../../constants';
import { GET_SPECIAL_ENTITIES } from '../../queries';
import grid from '../../../../../../../common/assets/styles/grid.legacy.css';
import styles from './styles.legacy.css';
import { RecommendationsNode } from '../../../../../../../shared/hooks/useRecommendations/typings';
import { LandingPageProps } from '../../typings';

type LandingPagePropsInner = LandingPageProps;

type LandingPageQueryComponentProps = {
  environment: Route & {
    routeByPath: Route;
  };
};

const latestNAGenerator: Generator<RecommendationsNode> | null = null;

const LandingPage = ({
  page,
  origin,
  location,
  landingPage,
  isAdSuppressed = false,
}: LandingPagePropsInner) => {
  /**
   * Sub-pages like /specials/wallis request the same landingPage again later
   * which is then wrongly served from hydration with the originally requested landingPage
   * because it has the same path. Which results in a 404 flashing on SSR.
   * This landingPageCachebuster prevents that.
   */
  const channel: Channel = landingPage?.channel || null;
  const isSpecialChannel =
    channel?.channelType === CHANNEL_TYPE_SPECIAL || false;
  const landingPageCachebuster = isSpecialChannel ? '?isSpecialChannel' : '';

  const gqlVariables = {
    path: location && location.pathname.substr(1) + landingPageCachebuster,
    overviewPageSize: SPECIALS_PAGE_SIZE,
    overviewPageOffset: (page - 1) * SPECIALS_PAGE_SIZE,
    filter: [
      ARTICLE_CONTENT_TYPE,
      IMAGE_GALLERY_CONTENT_TYPE,
      NATIVE_ADVERTISING_CONTENT_TYPE,
    ],
  };

  const landingPageNotExist =
    !landingPage || Object.keys(landingPage).length === 0;

  const { data, loading, error } = useQuery<LandingPageQueryComponentProps>(
    GET_SPECIAL_ENTITIES,
    {
      variables: gqlVariables,
      skip: landingPageNotExist,
    },
  );

  if (landingPageNotExist) {
    return null;
  }

  const activeMenuTrail: ActiveMenuTrailItemConnection | null =
    (landingPage?.activeMenuTrail && {
      ...JSON.parse(JSON.stringify(landingPage.activeMenuTrail)),
    }) ||
    null;

  const gridConfig = 'landscapeSpecial';

  const preparedBodyWithAds = enrichOverviewBodyWithADs({
    pageBody: landingPage.body,
    hasEQsWithMMR: true,
  });

  const object = data?.environment?.routeByPath?.object as LandingPage;

  return (
    <TestFragment data-testid="landing-page-wrapper">
      <EditButtons
        editContentUri={landingPage.editContentUri}
        editRelationUri={landingPage.editRelationUri}
        cloneContentUri={landingPage.cloneContentUri}
      />

      {activeMenuTrail && (
        <TestFragment data-testid="channel-breadcrumbs-wrapper">
          <Breadcrumbs
            pageUrl={landingPage.preferredUri}
            items={activeMenuTrail}
          />
        </TestFragment>
      )}

      {landingPage?.channel?.sponsors?.edges &&
        Array.isArray(landingPage?.channel?.sponsors?.edges) && (
          <TestFragment data-testid="landing-page-partnerbanner-wrapper">
            <PartnerBanner
              sponsors={landingPage?.channel?.sponsors?.edges || []}
            />
          </TestFragment>
        )}

      {!loading && error && __DEVELOPMENT__ && (
        <Error msg={`Apollo <Query> component error: ${error}`} />
      )}

      {!loading && object && (
        <>
          {
            <div
              className={classNames(
                grid.Container,
                styles.SpecialHeaderWrapper,
              )}
            >
              <Teaser
                component={TEASER_LAYOUT_SPECIAL_HERO}
                {...{
                  title: object?.title || '',
                  shortTitle:
                    object?.shortTitle || object?.channel?.title || '',
                  preferredUri: '',
                  channel: object?.channel || {},
                  teaserImage: object?.teaserImage || {},
                }}
              />
            </div>
          }

          {object?.channel && (
            <OverviewPage
              location={location}
              page={page}
              pageSize={SPECIALS_PAGE_SIZE}
              routeObject={object?.channel}
              termSettings={object?.channel?.settings}
              gridConfig={gridConfig}
              paragraphType="LandingPageScreen"
              paragraphIndex={0}
            />
          )}
        </>
      )}

      {preparedBodyWithAds &&
        Array.isArray(preparedBodyWithAds) &&
        preparedBodyWithAds.length > 0 && (
          <TestFragment data-testid="landing-page-paragraphs">
            <Paragraphs
              latestNAGenerator={latestNAGenerator}
              pageBody={preparedBodyWithAds}
              origin={origin || LANDING_PAGE_TYPE}
              hasContainer={false}
              isAdSuppressed={
                landingPage?.channel?.suppressAds || isAdSuppressed
              }
            />
          </TestFragment>
        )}
    </TestFragment>
  );
};

export default compose(
  withHelmet({
    getNode: (mapProps: LandingPagePropsInner): LandingPage | null =>
      mapProps.landingPage || null,
    getImage: (mapProps: LandingPagePropsInner) => {
      // Because of wrong ParagraphInterface typing we have to type it like this right now
      const landingPageBody = mapProps.landingPage?.body as any;
      return (
        mapProps.landingPage?.teaserImage?.image?.file ||
        landingPageBody?.[0]?.entityQueue?.items?.edges?.[0]?.node?.teaserImage
          ?.image?.file
      );
    },
    getNodesCount: (mapProps: LandingPagePropsInner): number =>
      mapProps.landingPage?.grid?.count || 0,
    pageSize: LANDING_PAGE_DEFAULT_GRID_PAGE_SIZE,
    rootSchemaType: ROOT_SCHEMA_TYPE_WEB_PAGE,
    getNodes: (mapProps: LandingPagePropsInner) =>
      getAllArticles(mapProps.landingPage),
  }),
)(LandingPage);
